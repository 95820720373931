import React from 'react';
import NavbarHeader from "../components/Main/NavbarHeader";
import Footer from "../components/Footer";
import EquipmentCard from "../components/Main/EquipmentCard";
import './styles/EquipmentCardPage.css';

const EquipmentCardPage = () => {
    return (
        <div>
            <NavbarHeader />
            <div className="equipment-card-page-container">
                <EquipmentCard />
            </div>
            <Footer />
        </div>
    );
};

export default EquipmentCardPage;
