import React from 'react';
import { Box, Image, Text, VStack, HStack, Button, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import './EquipmentList.css';

const EquipmentList = ({ equipment }) => {
    const navigate = useNavigate();
    const toast = useToast();

    const handleItemClick = (id) => {
        navigate(`/equipment/${id}`);
    };

    const handleCopy = (e, code) => {
        e.stopPropagation();
        navigator.clipboard.writeText(code)
            .then(() => {
                toast({
                    title: "Код скопирован.",
                    description: `Код ${code} был успешно скопирован в буфер обмена.`,
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                });
            })
            .catch(err => {
                console.error('Ошибка при копировании:', err);
                toast({
                    title: "Ошибка.",
                    description: "Не удалось скопировать код.",
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                });
            });
    };

    return (
        <VStack spacing={4} align="stretch" className="equipment-list">
            {equipment.map((item) => (
                <HStack
                    key={item.id}
                    borderWidth="1px"
                    borderRadius="lg"
                    overflow="hidden"
                    padding="1rem"
                    className="equipment-item"
                    onClick={() => handleItemClick(item.id)}
                    cursor="pointer"
                    _hover={{ bg: "gray.100" }}
                >
                    <Image
                        src={item.photo_url || '/images/placeholder.png'}
                        alt={`${item.type} ${item.brand_name} ${item.model}`}
                        objectFit="cover"
                        borderRadius="md"
                        onError={(e) => { e.target.src = '/images/placeholder.png'; }}
                        className="equipment-image"

                    />
                    <VStack align="start" spacing={1} flex="1" className="equipment-details">
                        <Text fontWeight="bold" fontSize="lg">
                            {item.type || ''} {item.brand_name || ''} {item.model || ''}
                        </Text>
                        {item.number && (
                            <Text
                                onClick={(e) => handleCopy(e, item.number)}
                                cursor="pointer"
                                color="blue.500"
                                textDecoration="underline"
                            >
                                Код: {item.number}
                            </Text>
                        )}
                    </VStack>
                    <Button
                        onClick={(e) => { e.stopPropagation(); handleItemClick(item.id); }}
                        className="equipment-button"
                        colorScheme="teal"
                        size="sm"
                    >
                        Подробнее
                    </Button>
                </HStack>
            ))}
        </VStack>
    );
};

export default EquipmentList;
