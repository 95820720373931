// src/components/Admin/Settings.jsx
import React, { useState, useEffect } from 'react';
import { Box, Heading, FormControl, FormLabel, Input, Button, Spinner, useToast } from '@chakra-ui/react';
import api from '../../utils/api';

const Settings = () => {
    const [systemName, setSystemName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();

    const fetchSettings = async () => {
        setIsLoading(true);
        try {
            const response = await api.get('/api/admin/settings', { withCredentials: true });
            setSystemName(response.data.systemName);
        } catch (error) {
            console.error('Ошибка при получении настроек:', error);
            toast({
                title: "Ошибка",
                description: "Не удалось загрузить настройки.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchSettings();
    }, []);

    const handleSave = async () => {
        setIsLoading(true);
        try {
            await api.post('/api/admin/settings', { systemName }, { withCredentials: true });
            toast({
                title: "Настройки сохранены.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Ошибка при сохранении настроек:', error);
            toast({
                title: "Ошибка",
                description: "Не удалось сохранить настройки.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <Spinner size="xl" />;
    }

    return (
        <Box>
            <Heading mb={4}>Настройки системы</Heading>
            <FormControl id="systemName" mb={4}>
                <FormLabel>Название системы</FormLabel>
                <Input
                    type="text"
                    value={systemName}
                    onChange={(e) => setSystemName(e.target.value)}
                />
            </FormControl>
            <Button colorScheme="blue" onClick={handleSave}>
                Сохранить
            </Button>
        </Box>
    );
};

export default Settings;
