// packages/client/src/components/Main/FiltersPanel.jsx
import React, { useEffect, useState } from 'react';
import {
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Checkbox,
    VStack,
    Text,
    Input,
    HStack,
    Button,
} from '@chakra-ui/react';
import './FiltersPanel.css';
import api from "../../utils/api";

const FiltersPanel = ({ onFilterChange }) => {
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [conditions, setConditions] = useState([]);
    const [locations, setLocations] = useState([]);
    const [stores, setStores] = useState([]);
    const [floors, setFloors] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({
        category_id: null,
        brand_id: null,
        condition_id: null,
        location_id: null,
        store_id: null,
        floor_id: null,
        status_id: null,
        warranty: null,
        price_min: '',
        price_max: '',
        operational_expenses_min: '',
        operational_expenses_max: '',
        purchase_date_from: '',
        purchase_date_to: '',
        production_date_from: '',
        production_date_to: '',
        last_repair_date_from: '',
        last_repair_date_to: '',
        created_at_from: '',
        created_at_to: '',
        updated_at_from: '',
        updated_at_to: '',
        search: '',
    });

    useEffect(() => {
        fetchFiltersData();
    }, []);

    const fetchFiltersData = async () => {
        try {
            const [categoriesRes, brandsRes, conditionsRes, locationsRes, storesRes, floorsRes, statusesRes] = await Promise.all([
                api.get('/api/equipment/categories'),
                api.get('/api/brands'),
                api.get('/api/conditions'),
                api.get('/api/locations'),
                api.get('/api/stores'),
                api.get('/api/floors'),
                api.get('/api/statuses'),
            ]);

            if (categoriesRes.data.success) setCategories(categoriesRes.data.categories || []);
            if (brandsRes.data.success) setBrands(brandsRes.data.brands || []);
            if (conditionsRes.data.success) setConditions(conditionsRes.data.conditions || []);
            if (locationsRes.data.success) setLocations(locationsRes.data.locations || []);
            if (storesRes.data.success) setStores(storesRes.data.stores || []);
            if (floorsRes.data.success) setFloors(floorsRes.data.floors || []);
            if (statusesRes.data.success) setStatuses(statusesRes.data.statuses || []);
        } catch (error) {
            console.error('Ошибка при загрузке данных фильтров:', error);
        }
    };

    const handleFilterChange = (key, value) => {
        setSelectedFilters(prevFilters => {
            const updatedFilters = {
                ...prevFilters,
                [key]: value,
            };
            onFilterChange(updatedFilters);
            return updatedFilters;
        });
    };

    const handleResetFilters = () => {
        const resetFilters = {
            category_id: null,
            brand_id: null,
            condition_id: null,
            location_id: null,
            store_id: null,
            floor_id: null,
            status_id: null,
            warranty: null,
            price_min: '',
            price_max: '',
            operational_expenses_min: '',
            operational_expenses_max: '',
            purchase_date_from: '',
            purchase_date_to: '',
            production_date_from: '',
            production_date_to: '',
            last_repair_date_from: '',
            last_repair_date_to: '',
            created_at_from: '',
            created_at_to: '',
            updated_at_from: '',
            updated_at_to: '',
            search: '',
        };
        setSelectedFilters(resetFilters);
        onFilterChange(resetFilters);
    };

    return (
        <Box>
            <Accordion allowMultiple>
                {/* Категории */}
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Категории
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <VStack align="start" spacing={2}>
                            {categories.map(category => (
                                <Checkbox
                                    key={category.id}
                                    isChecked={selectedFilters.category_id === category.id}
                                    onChange={() => handleFilterChange('category_id', selectedFilters.category_id === category.id ? null : category.id)}
                                    className="filter-checkbox"
                                >
                                    {category.name}
                                </Checkbox>
                            ))}
                        </VStack>
                    </AccordionPanel>
                </AccordionItem>

                {/* Бренды */}
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Бренды
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <VStack align="start" spacing={2}>
                            {brands.map(brand => (
                                <Checkbox
                                    key={brand.id}
                                    isChecked={selectedFilters.brand_id === brand.id}
                                    onChange={() => handleFilterChange('brand_id', selectedFilters.brand_id === brand.id ? null : brand.id)}
                                    className="filter-checkbox"
                                >
                                    {brand.name}
                                </Checkbox>
                            ))}
                        </VStack>
                    </AccordionPanel>
                </AccordionItem>

                {/* Состояния */}
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Состояние
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <VStack align="start" spacing={2}>
                            {conditions.map(condition => (
                                <Checkbox
                                    key={condition.id}
                                    isChecked={selectedFilters.condition_id === condition.id}
                                    onChange={() => handleFilterChange('condition_id', selectedFilters.condition_id === condition.id ? null : condition.id)}
                                    className="filter-checkbox"
                                >
                                    {condition.name}
                                </Checkbox>
                            ))}
                        </VStack>
                    </AccordionPanel>
                </AccordionItem>

                {/* Местоположения */}
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Местоположение
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <VStack align="start" spacing={2}>
                            {locations.map(location => (
                                <Checkbox
                                    key={location.id}
                                    isChecked={selectedFilters.location_id === location.id}
                                    onChange={() => handleFilterChange('location_id', selectedFilters.location_id === location.id ? null : location.id)}
                                    className="filter-checkbox"
                                >
                                    {location.name}
                                </Checkbox>
                            ))}
                        </VStack>
                    </AccordionPanel>
                </AccordionItem>

                {/* Магазины */}
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Магазины
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <VStack align="start" spacing={2}>
                            {stores.map(store => (
                                <Checkbox
                                    key={store.id}
                                    isChecked={selectedFilters.store_id === store.id}
                                    onChange={() => handleFilterChange('store_id', selectedFilters.store_id === store.id ? null : store.id)}
                                    className="filter-checkbox"
                                >
                                    {store.name}
                                </Checkbox>
                            ))}
                        </VStack>
                    </AccordionPanel>
                </AccordionItem>

                {/* Этажи */}
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Этажи
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <VStack align="start" spacing={2}>
                            {floors.map(floor => (
                                <Checkbox
                                    key={floor.id}
                                    isChecked={selectedFilters.floor_id === floor.id}
                                    onChange={() => handleFilterChange('floor_id', selectedFilters.floor_id === floor.id ? null : floor.id)}
                                    className="filter-checkbox"
                                >
                                    {floor.name}
                                </Checkbox>
                            ))}
                        </VStack>
                    </AccordionPanel>
                </AccordionItem>

                {/* Статусы */}
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Статусы
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <VStack align="start" spacing={2}>
                            {statuses.map(status => (
                                <Checkbox
                                    key={status.id}
                                    isChecked={selectedFilters.status_id === status.id}
                                    onChange={() => handleFilterChange('status_id', selectedFilters.status_id === status.id ? null : status.id)}
                                    className="filter-checkbox"
                                >
                                    {status.name}
                                </Checkbox>
                            ))}
                        </VStack>
                    </AccordionPanel>
                </AccordionItem>

                {/* Гарантия */}
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Гарантия
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <VStack align="start" spacing={2}>
                            <Checkbox
                                isChecked={selectedFilters.warranty === true}
                                onChange={() => handleFilterChange('warranty', selectedFilters.warranty === true ? null : true)}
                                className="filter-checkbox"
                            >
                                В наличии
                            </Checkbox>
                            <Checkbox
                                isChecked={selectedFilters.warranty === false}
                                onChange={() => handleFilterChange('warranty', selectedFilters.warranty === false ? null : false)}
                                className="filter-checkbox"
                            >
                                Нет гарантии
                            </Checkbox>
                        </VStack>
                    </AccordionPanel>
                </AccordionItem>

                {/* Диапазон цен */}
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Диапазон цен (₽)
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <VStack spacing={4}>
                            <HStack>
                                <Input
                                    placeholder="С"
                                    type="number"
                                    value={selectedFilters.price_min}
                                    onChange={(e) => handleFilterChange('price_min', e.target.value)}
                                    className="filter-input"
                                />
                                <Text>до</Text>
                                <Input
                                    placeholder="По"
                                    type="number"
                                    value={selectedFilters.price_max}
                                    onChange={(e) => handleFilterChange('price_max', e.target.value)}
                                    className="filter-input"
                                />
                            </HStack>
                        </VStack>
                    </AccordionPanel>
                </AccordionItem>

                {/* Диапазон операционных расходов */}
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Диапазон операционных расходов (₽)
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <VStack spacing={4}>
                            <HStack>
                                <Input
                                    placeholder="С"
                                    type="number"
                                    value={selectedFilters.operational_expenses_min}
                                    onChange={(e) => handleFilterChange('operational_expenses_min', e.target.value)}
                                    className="filter-input"
                                />
                                <Text>до</Text>
                                <Input
                                    placeholder="По"
                                    type="number"
                                    value={selectedFilters.operational_expenses_max}
                                    onChange={(e) => handleFilterChange('operational_expenses_max', e.target.value)}
                                    className="filter-input"
                                />
                            </HStack>
                        </VStack>
                    </AccordionPanel>
                </AccordionItem>

                {/* Даты покупки */}
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Даты покупки
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <VStack spacing={4}>
                            <HStack>
                                <Input
                                    placeholder="С"
                                    type="date"
                                    value={selectedFilters.purchase_date_from}
                                    onChange={(e) => handleFilterChange('purchase_date_from', e.target.value)}
                                    className="filter-input"
                                />
                                <Text>до</Text>
                                <Input
                                    placeholder="По"
                                    type="date"
                                    value={selectedFilters.purchase_date_to}
                                    onChange={(e) => handleFilterChange('purchase_date_to', e.target.value)}
                                    className="filter-input"
                                />
                            </HStack>
                        </VStack>
                    </AccordionPanel>
                </AccordionItem>

                {/* Даты производства */}
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Даты производства
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <VStack spacing={4}>
                            <HStack>
                                <Input
                                    placeholder="С"
                                    type="date"
                                    value={selectedFilters.production_date_from}
                                    onChange={(e) => handleFilterChange('production_date_from', e.target.value)}
                                    className="filter-input"
                                />
                                <Text>до</Text>
                                <Input
                                    placeholder="По"
                                    type="date"
                                    value={selectedFilters.production_date_to}
                                    onChange={(e) => handleFilterChange('production_date_to', e.target.value)}
                                    className="filter-input"
                                />
                            </HStack>
                        </VStack>
                    </AccordionPanel>
                </AccordionItem>

                {/* Даты последнего ремонта */}
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Дата последнего ремонта
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <VStack spacing={4}>
                            <HStack>
                                <Input
                                    placeholder="С"
                                    type="date"
                                    value={selectedFilters.last_repair_date_from}
                                    onChange={(e) => handleFilterChange('last_repair_date_from', e.target.value)}
                                    className="filter-input"
                                />
                                <Text>до</Text>
                                <Input
                                    placeholder="По"
                                    type="date"
                                    value={selectedFilters.last_repair_date_to}
                                    onChange={(e) => handleFilterChange('last_repair_date_to', e.target.value)}
                                    className="filter-input"
                                />
                            </HStack>
                        </VStack>
                    </AccordionPanel>
                </AccordionItem>

                {/* Даты создания */}
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Дата создания
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <VStack spacing={4}>
                            <HStack>
                                <Input
                                    placeholder="С"
                                    type="date"
                                    value={selectedFilters.created_at_from}
                                    onChange={(e) => handleFilterChange('created_at_from', e.target.value)}
                                    className="filter-input"
                                />
                                <Text>до</Text>
                                <Input
                                    placeholder="По"
                                    type="date"
                                    value={selectedFilters.created_at_to}
                                    onChange={(e) => handleFilterChange('created_at_to', e.target.value)}
                                    className="filter-input"
                                />
                            </HStack>
                        </VStack>
                    </AccordionPanel>
                </AccordionItem>

                {/* Даты обновления */}
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Дата обновления
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <VStack spacing={4}>
                            <HStack>
                                <Input
                                    placeholder="С"
                                    type="date"
                                    value={selectedFilters.updated_at_from}
                                    onChange={(e) => handleFilterChange('updated_at_from', e.target.value)}
                                    className="filter-input"
                                />
                                <Text>до</Text>
                                <Input
                                    placeholder="По"
                                    type="date"
                                    value={selectedFilters.updated_at_to}
                                    onChange={(e) => handleFilterChange('updated_at_to', e.target.value)}
                                    className="filter-input"
                                />
                            </HStack>
                        </VStack>
                    </AccordionPanel>
                </AccordionItem>

                {/* Поиск */}
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Поиск
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <Input
                            placeholder="Введите модель или номер"
                            value={selectedFilters.search}
                            onChange={(e) => handleFilterChange('search', e.target.value)}
                            className="filter-input"
                        />
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            <Button
                colorScheme="red"
                mt={4}
                onClick={handleResetFilters}
                className="reset-filters-button"
            >
                Сбросить фильтры
            </Button>
        </Box>
    );

};

export default FiltersPanel;
