import axios from 'axios';

export const fetchImage = async (imageUrl) => {
    const serverUrl = process.env.REACT_APP_SERVER_URL;

    if (!imageUrl) {
        return '/images/placeholder.png';
    }

    let formattedImageUrl = imageUrl;
    if (!imageUrl.startsWith('/images/')) {
        if (imageUrl.startsWith('images/')) {
            formattedImageUrl = `/${imageUrl}`;
        } else {
            formattedImageUrl = `/images/${imageUrl}`;
        }
    }

    try {
        const response = await axios.get(`${serverUrl}${formattedImageUrl}`, {
            responseType: 'blob',
        });
        const imageBlob = response.data;
        const imageObjectURL = URL.createObjectURL(imageBlob);
        return imageObjectURL;
    } catch (error) {
        console.error('Ошибка при загрузке изображения:', error);
        return '/images/placeholder.png';
    }
};
