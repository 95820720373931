import React, { useState, useEffect, useContext } from 'react';
import { useToast } from '@chakra-ui/react';
import api from '../utils/api';
import { AccountContext } from './AccountContext';

const NotesContext = React.createContext();

export const NotesContextProvider = ({ children }) => {
    const [notes, setNotes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const { user } = useContext(AccountContext);

    const fetchNotes = async () => {
        if (!user.loggedIn) return;

        setIsLoading(true);
        try {
            const response = await api.get('/api/notes', { withCredentials: true });
            if (response.data.success) {
                setNotes(response.data.notes);
            } else {
                toast({
                    title: "Ошибка.",
                    description: response.data.message || "Не удалось загрузить заметки.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error('Ошибка при получении заметок:', error);
            toast({
                title: "Ошибка.",
                description: "Не удалось загрузить заметки.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const createNote = async (title, message, reminder_at) => {
        try {
            const response = await api.post('/api/notes', { title, message, reminder_at }, { withCredentials: true });
            if (response.data.success) {
                setNotes(prev => [response.data.note, ...prev]);
                toast({
                    title: "Успешно.",
                    description: "Заметка создана.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: "Ошибка.",
                    description: response.data.message || "Не удалось создать заметку.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error('Ошибка при создании заметки:', error);
            toast({
                title: "Ошибка.",
                description: "Не удалось создать заметку.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const updateNote = async (id, title, message) => {
        try {
            const response = await api.put(`/api/notes/${id}`, { title, message }, { withCredentials: true });
            if (response.data.success) {
                setNotes(prev =>
                    prev.map(note =>
                        note.id === id ? response.data.note : note
                    )
                );
                toast({
                    title: "Успешно.",
                    description: "Заметка обновлена.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: "Ошибка.",
                    description: response.data.message || "Не удалось обновить заметку.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error('Ошибка при обновлении заметки:', error);
            toast({
                title: "Ошибка.",
                description: "Не удалось обновить заметку.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const deleteNote = async (id) => {
        try {
            const response = await api.delete(`/api/notes/${id}`, { withCredentials: true });
            if (response.data.success) {
                setNotes(prev => prev.filter(note => note.id !== id));
                toast({
                    title: "Успешно.",
                    description: "Заметка удалена.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: "Ошибка.",
                    description: response.data.message || "Не удалось удалить заметку.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error('Ошибка при удалении заметки:', error);
            toast({
                title: "Ошибка.",
                description: "Не удалось удалить заметку.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        if (user.loggedIn) {
            fetchNotes();
        } else {
            setNotes([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.loggedIn]);

    return (
        <NotesContext.Provider
            value={{
                notes,
                isLoading,
                fetchNotes,
                createNote,
                updateNote,
                deleteNote,
            }}
        >
            {children}
        </NotesContext.Provider>
    );
};

export default NotesContext;
