import React, { useContext } from 'react';
import { VStack, Box, Text, HStack, IconButton, Badge, Tooltip } from '@chakra-ui/react';
import {EditIcon, DeleteIcon, BellIcon} from '@chakra-ui/icons';
import NotesContext from '../../contexts/NotesContext';

const NotesList = ({ notes }) => {
    const { updateNote, deleteNote } = useContext(NotesContext);

    const handleDelete = async (id) => {
        await deleteNote(id);
    };

    return (
        <VStack spacing={4} align="stretch">
            {notes.map((note) => (
                <Box
                    key={note.id}
                    p={4}
                    borderWidth="1px"
                    borderRadius="md"
                    boxShadow="sm"
                    bg="white"
                >
                    <HStack justifyContent="space-between" alignItems="start">
                        <Box>
                            <HStack>
                                <Text fontSize="lg" fontWeight="bold">{note.title}</Text>
                                {note.reminder_at && (
                                    <Tooltip label={`Напоминание: ${new Date(note.reminder_at).toLocaleString()}`}>
                                        <BellIcon color="blue.500" />
                                    </Tooltip>
                                )}
                            </HStack>
                            <Text mt={2}>{note.message}</Text>
                            <Badge mt={2} colorScheme="blue">
                                Создано: {new Date(note.created_at).toLocaleString()}
                            </Badge>
                        </Box>
                        <HStack>
                            {/* Кнопка редактирования */}
                            <IconButton
                                aria-label="Редактировать заметку"
                                icon={<EditIcon />}
                                colorScheme="yellow"
                                variant="outline"
                                // onClick={() => editNote(note)} // Реализуйте функцию редактирования
                            />
                            {/* Кнопка удаления */}
                            <IconButton
                                aria-label="Удалить заметку"
                                icon={<DeleteIcon />}
                                colorScheme="red"
                                variant="outline"
                                onClick={() => handleDelete(note.id)}
                            />
                        </HStack>
                    </HStack>
                </Box>
            ))}
        </VStack>
    );
};

export default NotesList;
