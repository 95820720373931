import React from 'react';
import { Box, Text, VStack } from '@chakra-ui/react';
import LoadingSpinner from "../components/common/LoadingSpinner";

const WaitingPage = () => {
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            bg="gray.50"
        >
            <VStack spacing={4}>
                <LoadingSpinner />
                <Text fontSize="xl" color="gray.700">
                    Подключение к серверу...
                </Text>
            </VStack>
        </Box>
    );
};

export default WaitingPage;
