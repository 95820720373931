import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Flex,
    Heading,
    Input,
    Select,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Tfoot,
    TableCaption,
    TableContainer,
    Text,
    Spinner,
    useToast,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Footer from "../../Footer";

const AdminLogsPage = () => {
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [filters, setFilters] = useState({
        user: '',
        action: '',
        startDate: '',
        endDate: '',
    });
    const toast = useToast();
    const navigate = useNavigate();

    const logsPerPage = 10;

    useEffect(() => {
        fetchLogs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, filters]);

    const fetchLogs = async () => {
        setLoading(true);
        setError(null);
        try {
            const queryParams = new URLSearchParams({
                page: currentPage,
                limit: logsPerPage,
                user: filters.user,
                action: filters.action,
                startDate: filters.startDate,
                endDate: filters.endDate,
            });

            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/admin/logs?${queryParams.toString()}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            setLogs(response.data.logs);
            setTotalPages(response.data.totalPages);
        } catch (err) {
            setError("Не удалось загрузить логи. Попробуйте позже.");
            toast({
                title: "Ошибка",
                description: "Не удалось загрузить логи. Попробуйте позже.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleResetFilters = () => {
        setFilters({
            user: '',
            action: '',
            startDate: '',
            endDate: '',
        });
        setCurrentPage(1);
    };

    const handlePreviousPage = () => {
        setCurrentPage(prev => Math.max(prev - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage(prev => Math.min(prev + 1, totalPages));
    };

    return (
        <Flex direction="column" align="center" p={6}>
            <Heading mb={6}>Системные Логи</Heading>
            <Box width="100%" maxW="800px" mb={6}>
                <Flex mb={4} justifyContent="space-between" alignItems="center">
                    <Flex gap={4}>
                        <Input
                            placeholder="Фильтр по пользователю"
                            name="user"
                            value={filters.user}
                            onChange={handleFilterChange}
                        />
                        <Select
                            placeholder="Фильтр по действию"
                            name="action"
                            value={filters.action}
                            onChange={handleFilterChange}
                        >
                            <option value="create">Создание</option>
                            <option value="update">Обновление</option>
                            <option value="delete">Удаление</option>
                            <option value="login">Вход</option>
                            <option value="logout">Выход</option>
                        </Select>
                    </Flex>
                    <Flex gap={4}>
                        <Input
                            type="date"
                            name="startDate"
                            value={filters.startDate}
                            onChange={handleFilterChange}
                            placeholder="Начальная дата"
                        />
                        <Input
                            type="date"
                            name="endDate"
                            value={filters.endDate}
                            onChange={handleFilterChange}
                            placeholder="Конечная дата"
                        />
                    </Flex>
                    <Button colorScheme="teal" onClick={handleResetFilters}>
                        Сбросить фильтры
                    </Button>
                </Flex>
                {loading ? (
                    <Flex justifyContent="center" alignItems="center" height="200px">
                        <Spinner size="xl" />
                    </Flex>
                ) : error ? (
                    <Text color="red.500">{error}</Text>
                ) : (
                    <TableContainer>
                        <Table variant="striped" colorScheme="teal">
                            <TableCaption placement="top">Системные Логи</TableCaption>
                            <Thead>
                                <Tr>
                                    <Th>ID</Th>
                                    <Th>Пользователь</Th>
                                    <Th>Действие</Th>
                                    <Th>Описание</Th>
                                    <Th>Время</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {logs.length > 0 ? logs.map(log => (
                                    <Tr key={log.id}>
                                        <Td>{log.id}</Td>
                                        <Td>{log.user}</Td>
                                        <Td>{log.action}</Td>
                                        <Td>{log.description}</Td>
                                        <Td>{new Date(log.timestamp).toLocaleString()}</Td>
                                    </Tr>
                                )) : (
                                    <Tr>
                                        <Td colSpan="5" textAlign="center">Нет логов для отображения</Td>
                                    </Tr>
                                )}
                            </Tbody>
                            <Tfoot>
                                <Tr>
                                    <Th>ID</Th>
                                    <Th>Пользователь</Th>
                                    <Th>Действие</Th>
                                    <Th>Описание</Th>
                                    <Th>Время</Th>
                                </Tr>
                            </Tfoot>
                        </Table>
                    </TableContainer>
                )}
                <Flex justifyContent="space-between" alignItems="center" mt={4}>
                    <Button
                        leftIcon={<ChevronLeftIcon />}
                        onClick={handlePreviousPage}
                        isDisabled={currentPage === 1}
                    >
                        Назад
                    </Button>
                    <Text>Страница {currentPage} из {totalPages}</Text>
                    <Button
                        rightIcon={<ChevronRightIcon />}
                        onClick={handleNextPage}
                        isDisabled={currentPage === totalPages}
                    >
                        Вперед
                    </Button>
                </Flex>
            </Box>
            <Button colorScheme="teal" onClick={() => navigate("/admin/dashboard")} mb={6}>
                Вернуться к панели администратора
            </Button>
            <Footer />
        </Flex>
    );
};

export default AdminLogsPage;
