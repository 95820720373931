import React, { useEffect, useState } from 'react';
import {
    Box,
    Heading,
    Spinner,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Input,
    VStack,
    HStack,
    useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import NavbarHeader from './Main/NavbarHeader';
import Footer from './Footer';

const PublicReports = () => {
    const [reports, setReports] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const toast = useToast();
    const navigate = useNavigate();

    const fetchReports = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get('/api/reports', { withCredentials: true });
            setReports(response.data.data.reports);
        } catch (error) {
            console.error('Ошибка при получении отчетов:', error);
            toast({
                title: "Ошибка",
                description: "Не удалось загрузить отчеты.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchReports();
    }, []);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredReports = reports.filter(report =>
        report.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        report.equipment_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        report.type.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (isLoading) {
        return (
            <Box textAlign="center" mt={10}>
                <Spinner size="xl" />
            </Box>
        );
    }

    return (
        <Box>
            <NavbarHeader />
            <Box p={5}>
                <Heading mb={6}>Отчетность</Heading>
                <VStack align="stretch" spacing={4} mb={6}>
                    <Input
                        placeholder="Поиск по отчетам..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </VStack>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>ID</Th>
                            <Th>Тип отчета</Th>
                            <Th>Оборудование</Th>
                            <Th>Описание</Th>
                            <Th>Дата</Th>
                            {/* Если хотите, добавьте действия для просмотра */}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {filteredReports.map(report => (
                            <Tr key={report.id}>
                                <Td>{report.id}</Td>
                                <Td>{report.type.replace('_', ' ').charAt(0).toUpperCase() + report.type.replace('_', ' ').slice(1)}</Td>
                                <Td>{report.equipment_name}</Td>
                                <Td>{report.description}</Td>
                                <Td>{new Date(report.date).toLocaleDateString()}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
                <Footer />
            </Box>
        </Box>
    );
};

export default PublicReports;
