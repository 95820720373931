import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AccountContext } from '../contexts/AccountContext';
import api from '../utils/api';

const Hotkeys = () => {
    const navigate = useNavigate();
    const { user } = useContext(AccountContext);

    useHotkeys('ctrl+s', (event) => {
        event.preventDefault();

        console.log('Сохранение...');

        // api.saveData(data).then(...).catch(...);
    });

    useHotkeys('ctrl+k', (event) => {
        event.preventDefault();
        navigate('/catalog');
    });

    useHotkeys('ctrl+h', (event) => {
        event.preventDefault();
        navigate('/history');
    });

    useHotkeys('ctrl+i', (event) => {
        event.preventDefault();
        navigate('/inventory');
    });

    return null;
};

export default Hotkeys;
