import React, { useEffect, useState, useContext } from 'react';
import { Box, Grid, Image, Text, Flex, Spinner, Button } from '@chakra-ui/react';
import NavbarHeader from '../components/Main/NavbarHeader';
import Footer from '../components/Footer';

import api from '../utils/api';

const BrandsPage = () => {
    const [brands, setBrands] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const response = await api.get('/api/brands');
                setBrands(response.data.brands);
                setLoading(false);
            } catch (err) {
                setError('Не удалось загрузить бренды');
                setLoading(false);
            }
        };
        fetchBrands();
    }, []);

    return (
        <div>
            <NavbarHeader />
            <Box p="6">
                <Text fontSize="2xl" mb="4">Бренды</Text>
                {loading ? (
                    <Flex justify="center" align="center">
                        <Spinner size="lg" />
                    </Flex>
                ) : error ? (
                    <Text color="red.500">{error}</Text>
                ) : (
                    <Grid templateColumns="repeat(auto-fit, minmax(250px, 1fr))" gap="6">
                        {brands.map((brand) => (
                            <Box key={brand.id} borderWidth="1px" borderRadius="md" overflow="hidden" p="4">
                                <Image src={brand.image_url || '/images/default-brand.png'} alt={brand.name} mb="4" />
                                <Text fontSize="lg" fontWeight="bold">{brand.name}</Text>
                                <Text fontSize="sm" color="gray.500">Категория: {brand.category_id}</Text>
                                <Text fontSize="sm" color="gray.500">{brand.description || 'Нет описания'}</Text>
                                {/* Добавьте кнопки для редактирования и удаления, если необходимо */}
                            </Box>
                        ))}
                    </Grid>
                )}
            </Box>
            <Footer />
        </div>
    );
};

export default BrandsPage;
