import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import '../../pages/styles/Main/MainMenu.css';
import { AccountContext } from '../../contexts/AccountContext';

const adminMenuItems = [
    {
        title: 'Учёт',
        link: '/inventory',
        imageUrl: '/images/card1.png',
    },
    {
        title: 'Склад',
        link: '/warehouse',
        imageUrl: '/images/card2.png',
    },
    {
        title: 'Пользователи',
        link: '/users',
        imageUrl: '/images/card3.png',
    },
    {
        title: 'Ремонт и Техобслуживание',
        link: '/maintenance',
        imageUrl: '/images/card4.png',
    },
    {
        title: 'Список оборудования',
        link: '/catalog',
        imageUrl: '/images/card5.png',
    },
    {
        title: 'История',
        link: '/history',
        imageUrl: '/images/card6.png',
    },
];

const managerMenuItems = [
    {
        title: 'Учёт',
        link: '/inventory',
        imageUrl: '/images/card1.png',
    },
    {
        title: 'Склад',
        link: '/warehouse',
        imageUrl: '/images/card2.png',
    },
    {
        title: 'Пользователи',
        link: '/users',
        imageUrl: '/images/card3.png',
    },
    {
        title: 'Ремонт и Техобслуживание',
        link: '/maintenance',
        imageUrl: '/images/card4.png',
    },
    {
        title: 'Список оборудования',
        link: '/catalog',
        imageUrl: '/images/card5.png',
    },
    {
        title: 'История',
        link: '/history',
        imageUrl: '/images/card6.png',
    },
];

const workerMenuItems = [
    {
        title: 'Склад',
        link: '/warehouse',
        imageUrl: '/images/card2.png',
    },
    {
        title: 'Ремонт и Техобслуживание',
        link: '/maintenance',
        imageUrl: '/images/card4.png',
    },
    {
        title: 'Список оборудования',
        link: '/catalog',
        imageUrl: '/images/card5.png',
    },
];

const MainMenu = () => {
    const { user } = useContext(AccountContext);

    const getMenuItems = () => {
        if (user.role === 'admin') {
            return adminMenuItems;
        } else if (user.role === 'manager') {
            return managerMenuItems;
        } else if (user.role === 'worker') {
            return workerMenuItems;
        } else {

            return [];
        }
    };

    const menuItems = getMenuItems();

    return (
        <div className="main-menu-container">
            <h1 className="main-menu-title">Главное Меню</h1>
            <div className="menu-grid">
                {menuItems.map((item, index) => (
                    <Link
                        key={index}
                        to={item.link}
                        className="menu-item"
                        aria-label={item.title}
                    >
                        <img
                            src={item.imageUrl}
                            alt={item.title}
                            className="menu-item-image"
                        />
                        <div className="menu-item-content">
                            <span className="menu-item-title">
                                {item.title}
                            </span>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default MainMenu;
