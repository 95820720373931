import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  Flex,
  Input,
  VStack,
  Heading,
  Text,
  Select,
  useToast,
} from '@chakra-ui/react';
import './styles/FullRegPage.css';
import { registerValidationSchema } from '@swop-cathev/common';

const getCurrentDate = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // Январь это 0!
  const dd = String(today.getDate()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
};

const SurnameField = ({ formik }) => (
  <Box className="form-field">
    <Text>Фамилия</Text>
    <Input
      name="surname"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={formik.values.surname}
      isInvalid={formik.touched.surname && formik.errors.surname}
    />
    {formik.touched.surname && formik.errors.surname ? (
      <Text color="red.500">{formik.errors.surname}</Text>
    ) : null}
  </Box>
);

const NameField = ({ formik }) => (
  <Box className="form-field">
    <Text>Имя</Text>
    <Input
      name="name"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={formik.values.name}
      isInvalid={formik.touched.name && formik.errors.name}
    />
    {formik.touched.name && formik.errors.name ? (
      <Text color="red.500">{formik.errors.name}</Text>
    ) : null}
  </Box>
);

const MiddleNameField = ({ formik }) => (
  <Box className="form-field">
    <Text>Отчество</Text>
    <Input
      name="middle_name"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={formik.values.middle_name}
      isInvalid={formik.touched.middle_name && formik.errors.middle_name}
    />
    {formik.touched.middle_name && formik.errors.middle_name ? (
      <Text color="red.500">{formik.errors.middle_name}</Text>
    ) : null}
  </Box>
);

const CityField = ({ formik }) => (
  <Box className="form-field">
    <Text>Город</Text>
    <Input
      name="city"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={formik.values.city}
      isInvalid={formik.touched.city && formik.errors.city}
    />
    {formik.touched.city && formik.errors.city ? (
      <Text color="red.500">{formik.errors.city}</Text>
    ) : null}
  </Box>
);

const BirthdateField = ({ formik }) => (
  <Box className="form-field">
    <Text>Дата рождения</Text>
    <Input
      name="birthdate"
      type="date"
      max={getCurrentDate()}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={formik.values.birthdate}
      isInvalid={formik.touched.birthdate && formik.errors.birthdate}
    />
    {formik.touched.birthdate && formik.errors.birthdate ? (
      <Text color="red.500">{formik.errors.birthdate}</Text>
    ) : null}
  </Box>
);

const GenderField = ({ formik }) => (
  <Box className="form-field">
    <Text>Пол</Text>
    <Select
      name="gender"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={formik.values.gender}
      isInvalid={formik.touched.gender && formik.errors.gender}
      size="lg"
    >
      <option value="" label="Выберите пол" />
      <option value="male" label="Мужской" />
      <option value="female" label="Женский" />
    </Select>
    {formik.touched.gender && formik.errors.gender ? (
      <Text color="red.500">{formik.errors.gender}</Text>
    ) : null}
  </Box>
);

const InstitutionField = ({ formik }) => (
  <Box className="form-field">
    <Text>Учебное заведение</Text>
    <Input
      name="institution"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={formik.values.institution}
      isInvalid={formik.touched.institution && formik.errors.institution}
    />
    {formik.touched.institution && formik.errors.institution ? (
      <Text color="red.500">{formik.errors.institution}</Text>
    ) : null}
  </Box>
);

const BuildingField = ({ formik }) => (
  <Box className="form-field">
    <Text>Корпус</Text>
    <Input
      name="building"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={formik.values.building}
      isInvalid={formik.touched.building && formik.errors.building}
    />
    {formik.touched.building && formik.errors.building ? (
      <Text color="red.500">{formik.errors.building}</Text>
    ) : null}
  </Box>
);

const GroupField = ({ formik }) => (
  <Box className="form-field">
    <Text>Группа</Text>
    <Input
      name="group"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={formik.values.group}
      isInvalid={formik.touched.group && formik.errors.group}
    />
    {formik.touched.group && formik.errors.group ? (
      <Text color="red.500">{formik.errors.group}</Text>
    ) : null}
  </Box>
);

const StudentIdField = ({ formik }) => (
  <Box className="form-field">
    <Text>Номер студенческого (Обычно указан над ФИО)</Text>
    <Input
      name="studentId"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={formik.values.studentId}
      isInvalid={formik.touched.studentId && formik.errors.studentId}
      />
      {formik.touched.studentId && formik.errors.studentId ? (
        <Text color="red.500">{formik.errors.studentId}</Text>
      ) : null}
    </Box>
  );
  
  function FullRegPage() {
    const toast = useToast();
    const navigate = useNavigate();
  
    const formik = useFormik({
      initialValues: {
        name: '',
        surname: '',
        middle_name: '',
        city: '',
        birthdate: '',
        gender: '',
        group: '',
        building: '',
        institution: '',
        studentId: '',
      },
      validationSchema: registerValidationSchema,
      onSubmit: async (values) => {
        try {
          const response = await fetch('/api/register', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
          });
  
          if (response.ok) {
            toast({
              title: 'Регистрация успешна.',
              description: 'Вы успешно зарегистрировались.',
              status: 'success',
              duration: 5000,
              isClosable: true,
            });
            navigate('/main');
          } else {
            const errorData = await response.json();
            toast({
              title: 'Ошибка регистрации.',
              description: errorData.message || 'Что-то пошло не так.',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          }
        } catch (error) {
          toast({
            title: 'Ошибка регистрации.',
            description: 'Не удалось подключиться к серверу.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });
  
    return (
      <Flex className="FullReg-container">
        <Box className="FullReg-content">
          <VStack spacing={4} align="flex-start">
            <Heading as="h1" size="lg">Регистрация</Heading>
            <form onSubmit={formik.handleSubmit}>
              <VStack spacing={4} align="flex-start">
                <SurnameField formik={formik} />
                <NameField formik={formik} />
                <MiddleNameField formik={formik} />
                <CityField formik={formik} />
                <BirthdateField formik={formik} />
                <GenderField formik={formik} />
                <InstitutionField formik={formik} />
                <BuildingField formik={formik} />
                <GroupField formik={formik} />
                <StudentIdField formik={formik} />
                <Button type="submit" colorScheme="teal" width="full">
                  Зарегистрироваться
                </Button>
              </VStack>
            </form>
          </VStack>
        </Box>
      </Flex>
    );
  }
  
  export default FullRegPage;