import { useContext } from 'react';
import { AccountContext } from '../contexts/AccountContext';

const useAccount = () => {
    const context = useContext(AccountContext);
    if (!context) {
        throw new Error('useAccount должен использоваться внутри AccountProvider');
    }
    return context;
};

export default useAccount;
