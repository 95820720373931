import { useColorMode } from '@chakra-ui/react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';

export default function ThemeToggle() {
    const { colorMode, toggleColorMode } = useColorMode();

    return (
        <button
            onClick={toggleColorMode}
            aria-label="Переключить тему"
            className="theme-toggle-btn"
        >
            {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
        </button>
    );
}
