import React, { useEffect, useState } from 'react';
import { Box, Text, Spinner, Flex, Tabs, Tab, TabList, TabPanel, TabPanels } from '@chakra-ui/react';
import api from '../utils/api';
import Breadcrumb from '../components/Main/Breadcrumb';
import NavbarHeader from "../components/Main/NavbarHeader";
import Footer from "../components/Footer";
import './styles/Main/HistoryPage.css';

const HistoryPage = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [maintenanceHistory, setMaintenanceHistory] = useState([]);
    const [movementHistory, setMovementHistory] = useState([]);

    useEffect(() => {
        fetchMaintenanceHistory();
        fetchMovementHistory();
    }, []);

    const fetchMaintenanceHistory = async () => {
        try {
            const response = await api.get('/api/maintenance/history');
            if (response.data.success && Array.isArray(response.data.history)) {
                setMaintenanceHistory(response.data.history);
            } else {
                console.warn('Ожидался массив данных истории в response.data.history (ремонт).');
                setMaintenanceHistory([]);
            }
        } catch (err) {
            console.error('Ошибка при загрузке истории ремонта:', err);
            setError('Ошибка при загрузке истории ремонта.');
        } finally {
            setLoading(false);
        }
    };

    const fetchMovementHistory = async () => {
        try {
            const response = await api.get('/api/inventory/history');
            if (response.data.success && Array.isArray(response.data.history)) {
                setMovementHistory(response.data.history);
            } else {
                console.warn('Ожидался массив данных истории в response.data.history (перемещение).');
                setMovementHistory([]);
            }
        } catch (err) {
            console.error('Ошибка при загрузке истории перемещений:', err);
            setError('Ошибка при загрузке истории перемещений.');
        }
    };

    if (loading) {
        return (
            <Flex justify="center" align="center" height="60vh">
                <Spinner size="xl" />
            </Flex>
        );
    }

    if (error) {
        return (
            <Flex justify="center" align="center" height="60vh">
                <Text fontSize="xl" color="red.500">
                    {error}
                </Text>
            </Flex>
        );
    }

    const breadcrumbItems = [
        { name: 'Главное Меню', link: '/main' },
        { name: 'История' }
    ];

    return (
        <div>
            <NavbarHeader />
        <div className="history-page-container">

        <div className="history-page-content">

            <Breadcrumb items={breadcrumbItems} />

            <Tabs variant="enclosed" colorScheme="teal" mt={4}>
                <TabList>
                    <Tab>История ремонта</Tab>
                    <Tab>История перемещения</Tab>
                </TabList>

                <TabPanels>
                    {/* История ремонта */}
                    <TabPanel>
                        {maintenanceHistory.length === 0 ? (
                            <Text>Нет записей в истории ремонта.</Text>
                        ) : (
                            maintenanceHistory.map((record) => (
                                <Box key={record.id} p="4" borderWidth="1px" borderRadius="md" mb="2">
                                    <Text><strong>Событие:</strong> {record.action_type}</Text>
                                    <Text><strong>Дата:</strong> {new Date(record.date).toLocaleString()}</Text>
                                    <Text><strong>Пользователь:</strong> {record.performed_by}</Text>
                                    <Text><strong>Описание:</strong> {record.details}</Text>
                                </Box>
                            ))
                        )}
                    </TabPanel>

                    {/* История перемещения */}
                    <TabPanel>
                        {movementHistory.length === 0 ? (
                            <Text>Нет записей в истории перемещения.</Text>
                        ) : (
                            movementHistory.map((record) => (
                                <Box key={record.id} p="4" borderWidth="1px" borderRadius="md" mb="2">
                                    <Text><strong>Событие:</strong> {record.action_type}</Text>
                                    <Text><strong>Дата:</strong> {new Date(record.date).toLocaleString()}</Text>
                                    <Text><strong>Пользователь:</strong> {record.performed_by}</Text>
                                    <Text><strong>Описание:</strong> {record.details}</Text>
                                </Box>
                            ))
                        )}
                    </TabPanel>
                </TabPanels>
            </Tabs>


        </div>
            <Footer />
        </div>
        </div>
    );
};

export default HistoryPage;
