import React, { useContext } from 'react';
import { Box, Flex, VStack, Link, Heading, Img } from '@chakra-ui/react';
import { Link as RouterLink, Routes, Route, useLocation, Navigate, useNavigate } from 'react-router-dom';
import Footer from "../components/Footer";
import GeneralSettings from "../components/private/settings/GeneralSettings";
import ProfileSettings from '../components/private/settings/ProfileSettings';
import SystemSettings from "../components/private/settings/SystemSettings";
import UserSettings from "../components/private/settings/UserSettings";
import UserList from "../components/admin/settings/UserList";
import NavbarHeader from "../components/Main/NavbarHeader";
import { AccountContext } from '../contexts/AccountContext';

import './styles/Settings/SettingsPage.css';
import ArrowBackIcon from "./styles/icons/back-fat-black.svg";

const SettingsPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = useContext(AccountContext);

    console.log('Текущая роль пользователя:', user.role);

    const handleBack = () => {
        navigate('/main');
    };

    const roleNameMap = {
        'admin': 'admin',
        'manager': 'manager',
        'worker': 'worker',
    };

    const userRole = roleNameMap[user.role] || 'worker';

    const getMenuItems = () => {
        const commonItems = [
            { path: '/settings/general', name: 'Общие' },
            { path: '/settings/userSettings', name: 'Пользовательские' },
            { path: '/settings/system', name: 'Системные' },
            { path: '/settings/profile', name: 'Профиль' },
        ];

        const roleBasedItems = {
            admin: [
                { path: '/settings/users', name: 'Список пользователей' },
            ],
            manager: [

            ],
            worker: [

            ],
        };

        return [
            ...commonItems,
            ...(roleBasedItems[userRole] || []),
        ];
    };

    const menuItems = getMenuItems();

    return (
        <div>
            <NavbarHeader />
            <Flex className="Settings-container">

                <Box className="Settings-sidebar">
                    <Img
                        src={ArrowBackIcon}
                        alt="Назад"
                        className="App-header-LogoutIcon"
                        onClick={handleBack}
                        cursor="pointer"
                    />
                    <Heading as="h3" size="md" mb={6}>Настройки</Heading>

                    <VStack align="stretch" spacing={4}>
                        {menuItems.map((item) => (
                            <Link
                                as={RouterLink}
                                to={item.path}
                                key={item.path}
                                p={2}
                                borderRadius="md"
                                _hover={{
                                    bg: 'gray.100',
                                    textDecoration: 'none'
                                }}
                                bg={location.pathname.startsWith(item.path) ? 'blue.500' : 'transparent'}
                                color={location.pathname.startsWith(item.path) ? 'white' : 'black'}
                            >
                                {item.name}
                            </Link>
                        ))}
                    </VStack>
                </Box>

                <Box className="Settings-content" flex="1">
                    <Routes>
                        <Route path="/*" element={<Navigate to="general" />} />
                        <Route path="general" element={<GeneralSettings />} />
                        <Route path="profile" element={<ProfileSettings />} />
                        <Route path="system" element={<SystemSettings />} />
                        <Route path="userSettings" element={<UserSettings />} />
                        {userRole === 'admin' && (
                            <Route path="users" element={<UserList />} />
                        )}
                    </Routes>
                </Box>

            </Flex>
            <Footer />
        </div>
    );
};

export default SettingsPage;
