import React, { useState, useEffect } from 'react';
import { Box, Flex, Text, Tabs, TabList, TabPanels, Tab, TabPanel, Spinner, Button, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useZxing } from 'react-zxing';
import { useNavigate } from 'react-router-dom';
import NavbarHeader from '../components/Main/NavbarHeader';
import Footer from '../components/Footer';
import api from '../utils/api';
import './styles/Main/MaintenancePage.css';

const MaintenancePage = () => {
    const [mode, setMode] = useState('manual'); // "manual" или "scan"
    const [equipmentId, setEquipmentId] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [maintenanceHistory, setMaintenanceHistory] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const navigate = useNavigate();

    const handleScan = (result) => {
        if (result) {
            setEquipmentId(result);
            setMode('scan');
        }
    };

    const handleError = (scanError) => {
        console.error(scanError);
        setError('Ошибка при сканировании QR кода. Повторите попытку.');
    };

    const { ref } = useZxing({
        onResult: (result, error) => {
            if (result) {
                handleScan(result.getText());
            }
            if (error) {
                handleError(error);
            }
        },
        constraints: {
            video: {
                facingMode: 'environment',
            },
        },
    });

    const sendToRepair = async () => {
        try {
            setLoading(true);
            const response = await api.post('/api/maintenance/send', { equipmentId }, { withCredentials: true });
            if (!response.data.success) {
                setError(response.data.message || 'Не удалось отправить оборудование на ремонт.');
            } else {
                setError(null);
                alert('Оборудование успешно отправлено на ремонт.');
            }
        } catch (err) {
            console.error('Ошибка при отправке на ремонт:', err);
            setError('Произошла ошибка при отправке на ремонт.');
        } finally {
            setLoading(false);
        }
    };

    const receiveFromRepair = async () => {
        try {
            setLoading(true);
            const response = await api.post('/api/maintenance/receive', { equipmentId }, { withCredentials: true });
            if (!response.data.success) {
                setError(response.data.message || 'Не удалось принять оборудование с ремонта.');
            } else {
                setError(null);
                alert('Оборудование успешно принято с ремонта.');
            }
        } catch (err) {
            console.error('Ошибка при приёмке с ремонта:', err);
            setError('Произошла ошибка при приёмке с ремонта.');
        } finally {
            setLoading(false);
        }
    };

    const openHistoryModal = async () => {
        setIsModalOpen(true);
        await fetchMaintenanceHistory();
    };

    const closeHistoryModal = () => {
        setIsModalOpen(false);
    };

    const fetchMaintenanceHistory = async () => {
        try {
            setLoading(true);
            const response = await api.get('/api/maintenance/history', { withCredentials: true });
            if (response.data.success) {
                setMaintenanceHistory(response.data.history || []);
                setError(null);
            } else {
                setError('Не удалось загрузить историю обслуживания.');
            }
        } catch (err) {
            console.error('Ошибка при загрузке истории обслуживания:', err);
            setError('Произошла ошибка при загрузке истории обслуживания.');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <div>
                <NavbarHeader />
                <Flex p="6" justify="center" align="center" height="100vh">
                    <Spinner size="xl" />
                </Flex>
            </div>
        );
    }

    return (
        <div>
            <NavbarHeader />
        <div className="maintenance-page-container">

            <Box p="6" maxW="1200px" mx="auto">
                <Text fontSize="2xl" mb="4">Ремонт и Обслуживание</Text>

                {error && (
                    <Box mb="4">
                        <Text color="red.500">{error}</Text>
                    </Box>
                )}

                <Flex gap="4" mb="6">
                    <Button colorScheme="teal" onClick={sendToRepair}>Отправить на ремонт</Button>
                    <Button colorScheme="blue" onClick={receiveFromRepair}>Принять с ремонта</Button>
                    <Button onClick={openHistoryModal}>История обслуживания</Button>
                </Flex>

                {/* Переключение режимов: сканировать или ввод вручную */}
                <Flex gap="4" mb="6">
                    <Button variant={mode === 'scan' ? 'solid' : 'outline'} onClick={() => setMode('scan')}>
                        Сканировать QR
                    </Button>
                    <Button variant={mode === 'manual' ? 'solid' : 'outline'} onClick={() => setMode('manual')}>
                        Ввод вручную
                    </Button>
                </Flex>

                {/* В режиме сканирования мы показываем виджет камеры, в режиме manual - поле ввода */}
                {mode === 'scan' && (
                    <Box mb="6">
                        <Text>Наведите камеру на QR код</Text>
                        <video ref={ref} style={{ width: '300px', height: '200px' }} />
                    </Box>
                )}

                {mode === 'manual' && (
                    <Box mb="6">
                        <FormControl>
                            <FormLabel>ID Оборудования</FormLabel>
                            <Input
                                type="text"
                                value={equipmentId}
                                onChange={(e) => setEquipmentId(e.target.value)}
                                placeholder="Введите ID оборудования"
                            />
                        </FormControl>
                    </Box>
                )}

            </Box>


            {/* Модальное окно для отображения истории обслуживания */}
            <Modal isOpen={isModalOpen} onClose={closeHistoryModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>История обслуживания</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {maintenanceHistory.length === 0 ? (
                            <Text>Нет записей в истории обслуживания.</Text>
                        ) : (
                            maintenanceHistory.map((record) => (
                                <Box key={record.id} p="4" borderWidth="1px" borderRadius="md" mb="2">
                                    <Text><strong>ID:</strong> {record.id}</Text>
                                    <Text><strong>ID Оборудования:</strong> {record.equipment_id}</Text>
                                    <Text><strong>Тип Действия:</strong> {record.action_type}</Text>
                                    <Text><strong>Дата:</strong> {new Date(record.date).toLocaleString()}</Text>
                                    <Text><strong>Описание:</strong> {record.details}</Text>
                                    {record.photos && (
                                        <Box mt="2">
                                            <Text><strong>Фото:</strong></Text>
                                            {record.photos.split(',').map((photoUrl, idx) => (
                                                <img
                                                    key={idx}
                                                    src={photoUrl}
                                                    alt={`Фото ${idx + 1}`}
                                                    style={{ maxWidth: '100px', marginRight: '8px' }}
                                                />
                                            ))}
                                        </Box>
                                    )}
                                </Box>
                            ))
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" onClick={closeHistoryModal}>Закрыть</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Footer />
        </div>
        </div>
    );
};

export default MaintenancePage;
