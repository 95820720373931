// packages/client/src/pages/AllEquipmentPage.jsx
import React, { useState, useEffect } from 'react';
import { Box, Flex, Spinner, Text } from '@chakra-ui/react';
import api from '../utils/api';
import FiltersPanel from '../components/Main/FiltersPanel';
import EquipmentList from '../components/Main/EquipmentList';
import '../pages/styles/AllEquipmentPage.css';
import NavbarHeader from "../components/Main/NavbarHeader";
import Footer from "../components/Footer";

const AllEquipmentPage = () => {
    const [equipment, setEquipment] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState({});
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchEquipment();
    }, [filters]);

    const fetchEquipment = async () => {
        try {
            setLoading(true);
            console.log('Отправка запроса с фильтрами:', filters); // Для отладки
            const response = await api.get('/api/equipment', {
                params: filters,
            });

            if (response.data.success) {
                setEquipment(response.data.equipment);
                setError(null);
            } else {
                setError('Не удалось загрузить оборудование.');
            }
        } catch (err) {
            console.error('Ошибка при загрузке оборудования:', err);
            setError('Произошла ошибка при загрузке оборудования.');
        } finally {
            setLoading(false);
        }
    };

    const handleFilterChange = (newFilters) => {
        setFilters(newFilters);
    };

    if (loading) {
        return (
            <Flex className="loading-container" justify="center" align="center" height="100vh">
                <Spinner size="xl" />
            </Flex>
        );
    }

    if (error) {
        return (
            <Flex className="error-container" justify="center" align="center" height="100vh">
                <Text className="error-text">
                    {error}
                </Text>
            </Flex>
        );
    }

    return (
        <div>
            <NavbarHeader/>
            <Flex className="all-equipment-page">

                <Box className="filters-panel">
                    <FiltersPanel onFilterChange={handleFilterChange} />
                </Box>

                {/* Список оборудования справа */}
                <Box className="equipment-list-container">
                    <EquipmentList equipment={equipment} />
                </Box>
            </Flex>
            <Footer/>
        </div>
    );
};

export default AllEquipmentPage;
