import React, { useEffect, useState, useContext } from 'react';
import { useToast } from '@chakra-ui/react';
import api from '../utils/api';
import { AccountContext } from './AccountContext';

const NotificationsContext = React.createContext();

const NotificationsContextProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const { user } = useContext(AccountContext);

    const fetchNotifications = async () => {
        const token = localStorage.getItem("token");
        if (token) {
            setIsLoading(true);
            try {
                const response = await api.get('/api/notifications', { withCredentials: true });
                setNotifications(response.data.notifications || []);
            } catch (error) {
                console.error('Ошибка при получении уведомлений:', error);
                setNotifications([]);
                toast({
                    title: "Ошибка",
                    description: "Не удалось загрузить уведомления",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setIsLoading(false);
            }
        } else {
            setNotifications([]);
        }
    };

    const markAsRead = async (notificationId) => {
        const token = localStorage.getItem("token");
        if (token) {
            try {
                const response = await api.post(`/api/notifications/${notificationId}/read`, {}, { withCredentials: true });
                if (response.data.success) {
                    setNotifications((prevNotifications) =>
                        prevNotifications.map((notif) =>
                            notif.id === notificationId ? { ...notif, isRead: true } : notif
                        )
                    );
                    toast({
                        title: 'Уведомление прочитано.',
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    });
                } else {
                    toast({
                        title: 'Ошибка.',
                        description: response.data.message || 'Не удалось отметить уведомление как прочитанное.',
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } catch (err) {
                console.error('Ошибка при обновлении уведомления:', err);
                toast({
                    title: 'Ошибка.',
                    description: 'Произошла ошибка при обновлении уведомления.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    };

    const deleteNotification = async (notificationId) => {
        const token = localStorage.getItem("token");
        if (token) {
            try {
                const response = await api.delete(`/api/notifications/${notificationId}`, { withCredentials: true });
                if (response.data.success) {
                    setNotifications((prevNotifications) => prevNotifications.filter((notif) => notif.id !== notificationId));
                    toast({
                        title: 'Уведомление удалено.',
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    });
                } else {
                    toast({
                        title: 'Ошибка.',
                        description: response.data.message || 'Не удалось удалить уведомление.',
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } catch (err) {
                console.error('Ошибка при удалении уведомления:', err);
                toast({
                    title: 'Ошибка.',
                    description: 'Произошла ошибка при удалении уведомления.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    };

    useEffect(() => {
        if (user.loggedIn) {
            fetchNotifications();
        }
    }, [user.loggedIn]);

    const unreadCount = notifications.filter((notif) => !notif.isRead).length;

    return (
        <NotificationsContext.Provider
            value={{
                notifications,
                isLoading,
                fetchNotifications,
                markAsRead,
                deleteNotification,
                unreadCount,
            }}
        >
            {children}
        </NotificationsContext.Provider>
    );
};

export { NotificationsContext };
export default NotificationsContextProvider;
