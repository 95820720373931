import React, { useEffect, useState } from 'react';
import { Box, Heading, Spinner, Table, Thead, Tbody, Tr, Th, Td, useToast } from '@chakra-ui/react';
import axios from 'axios';

const EmployeeReports = () => {
    const [reports, setReports] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();

    const fetchEmployeeReports = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get('/api/admin/employee-reports', { withCredentials: true });
            setReports(response.data.data.reports);
        } catch (error) {
            console.error('Ошибка при получении отчетов сотрудников:', error);
            toast({
                title: "Ошибка",
                description: "Не удалось загрузить отчетность сотрудников.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchEmployeeReports();
    }, []);

    if (isLoading) {
        return <Spinner size="xl" />;
    }

    return (
        <Box>
            <Heading mb={4}>Отчетность Сотрудников</Heading>
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>ID</Th>
                        <Th>Имя</Th>
                        <Th>Должность</Th>
                        <Th>Проекты</Th>
                        <Th>Отчет</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {reports.map(report => (
                        <Tr key={report.id}>
                            <Td>{report.id}</Td>
                            <Td>{report.first_name} {report.last_name}</Td>
                            <Td>{report.position}</Td>
                            <Td>{report.projects.join(', ')}</Td>
                            <Td>{report.report}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
};

export default EmployeeReports;
