import React from 'react';
import { Breadcrumb as ChakraBreadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import '../../pages/styles/Breadcrumb.css';

const Breadcrumb = ({ items }) => {
    return (
        <div className="Breadcrumb-container">
            <ChakraBreadcrumb separator="/">
                {items.map((item, index) => (
                    <BreadcrumbItem key={index} isCurrentPage={!item.link}>
                        {item.link ? (
                            <BreadcrumbLink as={Link} to={item.link}>
                                {item.name}
                            </BreadcrumbLink>
                        ) : (
                            <BreadcrumbLink>{item.name}</BreadcrumbLink>
                        )}
                    </BreadcrumbItem>
                ))}
            </ChakraBreadcrumb>
        </div>
    );
};

export default Breadcrumb;