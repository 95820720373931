import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Text, Button, Box, VStack } from '@chakra-ui/react';
import { AccountContext } from "../../contexts/AccountContext";
import '../../pages/styles/Main/NavbarHeader.css';
import MessagesIcon from "../../pages/styles/icons/messages.svg";
import ProfileIcon from "../../pages/styles/icons/profile.svg";
import MenuIcon from "../../pages/styles/icons/menu-burger.svg";
import NotificationsIcon from "../../pages/styles/icons/notifications.svg";
import logo from '../../pages/styles/images/logo_long_white.png';
import ThemeToggle from '../common/ThemeToggle';
import debounce from 'lodash.debounce';
import { FaSearch } from 'react-icons/fa';
import {NotificationsContext} from "../../contexts/NotificationsContext";
import GlobalSearch from '../common/GlobalSearch';

export default function NavbarHeader() {
    const [date, setDate] = useState(new Date());
    const { user, logout } = useContext(AccountContext);
    const { notifications, unreadCount } = useContext(NotificationsContext);
    const navigate = useNavigate();
    const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
    const [isMenuDropdownOpen, setIsMenuDropdownOpen] = useState(false);
    const [isNotificationsDropdownOpen, setIsNotificationsDropdownOpen] = useState(false);

    const [menuTimeout, setMenuTimeout] = useState(null);
    const [notificationsTimeout, setNotificationsTimeout] = useState(null);
    const [userTimeout, setUserTimeout] = useState(null);

    useEffect(() => {
        const timer = setInterval(() => {
            setDate(new Date());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const handleLogoutClick = () => {
        logout();
        navigate('/');
    };

    const handleSettingsClick = () => {
        navigate('/settings');
    };

    const toggleUserDropdown = () => {
        setIsUserDropdownOpen(!isUserDropdownOpen);
    };

    const toggleMenuDropdown = () => {
        setIsMenuDropdownOpen(!isMenuDropdownOpen);
    };

    const toggleNotificationsDropdown = () => {
        setIsNotificationsDropdownOpen(!isNotificationsDropdownOpen);
    };

    const handleMenuEnter = () => {
        if (menuTimeout) clearTimeout(menuTimeout);
        setIsMenuDropdownOpen(true);
    };

    const handleMenuLeave = () => {
        const timeout = setTimeout(() => {
            setIsMenuDropdownOpen(false);
        }, 200);
        setMenuTimeout(timeout);
    };

    const handleNotificationsEnter = () => {
        if (notificationsTimeout) clearTimeout(notificationsTimeout);
        setIsNotificationsDropdownOpen(true);
    };

    const handleNotificationsLeave = () => {
        const timeout = setTimeout(() => {
            setIsNotificationsDropdownOpen(false);
        }, 200);
        setNotificationsTimeout(timeout);
    };

    const handleUserEnter = () => {
        if (userTimeout) clearTimeout(userTimeout);
        setIsUserDropdownOpen(true);
    };

    const handleUserLeave = () => {
        const timeout = setTimeout(() => {
            setIsUserDropdownOpen(false);
        }, 200);
        setUserTimeout(timeout);
    };

    const getUserRole = () => {
        if (user.role === 'admin' || user.role === 'manager') {
            return `(${user.role})`;
        }
        return '';
    };

    return (
        <header className="navbar-header">
            <div className="navbar-main">
                <div className="navbar-header-left">
                    <Link to="/main" className="navbar-logo">
                        <img src={logo} alt="Логотип" className="navbar-logo-image"/>
                    </Link>
                </div>

                <GlobalSearch />

                <div className="navbar-header-right">
                    <div className="navbar-actions">
                        <div
                            className="navbar-menu-actions"
                            onMouseEnter={handleMenuEnter}
                            onMouseLeave={handleMenuLeave}
                        >
                            <button className="dropdown-trigger" onClick={toggleMenuDropdown}>
                                <img src={MenuIcon} alt="Меню" className="navbar-icon"/>
                            </button>
                            {isMenuDropdownOpen && (
                                <div
                                    className="dropdown-content dropdown-menu-content"
                                    onMouseLeave={() => setIsMenuDropdownOpen(false)}
                                >
                                    <ul className="navbar-menu-list">
                                        <li className="navbar-menu-item">
                                            <Link to="/all-equipment" className="menu-link">Все Оборудование</Link>
                                        </li>
                                        <li className="navbar-menu-item">
                                            <Link to="/dashboard" className="menu-link">Дашборд</Link>
                                        </li>
                                        <li className="navbar-menu-item">
                                            <Link to="/notes" className="menu-link">Заметки</Link>
                                        </li>
                                        <li className="navbar-menu-item">
                                            <Link to="/reports" className="menu-link">Отчёты</Link>
                                        </li>
                                        <li className="navbar-menu-item">
                                            <Link to="/inventory" className="menu-link">Инвентаризация</Link>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>

                        {/* Кнопка уведомлений */}
                        <div
                            className="navbar-notifications-actions"
                            onMouseEnter={handleNotificationsEnter}
                            onMouseLeave={handleNotificationsLeave}
                        >
                            <button className="dropdown-trigger" onClick={toggleNotificationsDropdown}>
                                <img src={NotificationsIcon} alt="Уведомления" className="navbar-icon"/>
                                {unreadCount > 0 && (
                                    <span className="notification-count">{unreadCount}</span>
                                )}
                            </button>
                            {isNotificationsDropdownOpen && (
                                <div className="dropdown-notifications-content">
                                    <div className="notifications-panel">
                                        <Link to="/notifications" className="dropdown-item-link">
                                            <img src={MessagesIcon} alt="Уведомления" className="dropdown-icon"/>
                                            Уведомления
                                        </Link>
                                        {notifications && notifications.length === 0 ? (
                                            <span className="no-notifications">Уведомлений нет</span>
                                        ) : (
                                            <ul className="notifications-list">
                                                {notifications.map((notification) => (
                                                    <li
                                                        key={notification.id}
                                                        className={`notification-item ${!notification.isRead ? 'unread' : ''}`}
                                                    >
                                                        <div className="notification-title">
                                                            {notification.title}
                                                        </div>
                                                        <div className="notification-message">
                                                            {notification.message.length > 50
                                                                ? `${notification.message.slice(0, 50)}...`
                                                                : notification.message}
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Кнопка пользователя */}
                        <div
                            className="navbar-user-actions"
                            onMouseEnter={handleUserEnter}
                            onMouseLeave={handleUserLeave}
                        >
                            <button className="dropdown-trigger" onClick={toggleUserDropdown}>
                                <img src={ProfileIcon} alt="Пользователь" className="navbar-icon"/>
                            </button>
                            {isUserDropdownOpen && (
                                <div className="dropdown-content dropdown-user-content">
                                    <div className="dropdown-flex-container">
                                        {/* Панель основных настроек */}
                                        <ul className="navbar-menu-list">
                                            <li>
                                                <span className="user-name">{user.first_name} {getUserRole()}</span>
                                            </li>
                                            <li className="navbar-menu-item">
                                                <Link to="/settings" className="menu-link">Настройки</Link>
                                            </li>
                                            <li className="navbar-menu-item">
                                                <Link to="/settings/profile" className="menu-link">Профиль</Link>
                                            </li>
                                            <li className="navbar-menu-item">
                                                <div className="menu-link"> Тема <ThemeToggle/></div>
                                            </li>
                                            <li className="dropdown-item" onClick={handleLogoutClick}>Выход</li>
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}
