import React from 'react';
import {
  Box,
  Flex,
} from '@chakra-ui/react';
import './styles/Login/LoginPage.css';
import Login from "../components/Login/Login";
import LoginHeading from "../components/Login/LoginHeading";
import Footer from "../components/Footer";

function LoginPage() {

return (
    <div>
      <div className="LoginPage-container">
          <LoginHeading />
        <div className="LoginPage-content">
          <Login />
        </div>
          <Footer />
      </div>

    </div>
);
}

export default LoginPage;
