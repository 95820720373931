import React, { useEffect, useState } from 'react';
import {
    Box,
    Heading,
    Spinner,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Select,
    Input,
    HStack,
    VStack,
    useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer';
import NavbarHeader from '../Main/NavbarHeader';

const Reports = () => {
    const [reports, setReports] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [filterType, setFilterType] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const toast = useToast();
    const navigate = useNavigate();

    const fetchReports = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get('/api/dashboard/reports', { withCredentials: true });
            setReports(response.data.data.reports);
        } catch (error) {
            console.error('Ошибка при получении отчетов:', error);
            toast({
                title: "Ошибка",
                description: "Не удалось загрузить отчеты.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchReports();
    }, []);

    const handleFilterChange = (event) => {
        setFilterType(event.target.value);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredReports = reports.filter(report => {
        return (
            (filterType === '' || report.type === filterType) &&
            (report.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
                report.equipment_name.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    });

    if (isLoading) {
        return <Spinner size="xl" />;
    }

    return (

            <Box>
                <Heading mb={6}>Отчетность</Heading>
                <VStack align="start" spacing={4} mb={6}>
                    <HStack spacing={4}>
                        <Select placeholder="Фильтр по типу" value={filterType} onChange={handleFilterChange}>
                            <option value="addition">Добавление оборудования</option>
                            <option value="decommission">Списание оборудования</option>
                            <option value="dispatch">Отправка на объект</option>
                            <option value="receive">Приемка с объекта</option>
                            <option value="service_dispatch">Отправка в сервисный центр</option>
                            <option value="service_receive">Приемка из сервисного центра</option>
                            <option value="work_documents">Документы о работе</option>
                        </Select>
                        <Input
                            placeholder="Поиск..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        <Button colorScheme="teal" onClick={() => navigate("/dashboard/reports/new")}>
                            Создать отчет
                        </Button>
                    </HStack>
                </VStack>

                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>ID</Th>
                            <Th>Тип отчета</Th>
                            <Th>Оборудование</Th>
                            <Th>Описание</Th>
                            <Th>Дата</Th>
                            <Th>Действия</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {filteredReports.map(report => (
                            <Tr key={report.id}>
                                <Td>{report.id}</Td>
                                <Td>{report.type}</Td>
                                <Td>{report.equipment_name}</Td>
                                <Td>{report.description}</Td>
                                <Td>{new Date(report.date).toLocaleDateString()}</Td>
                                <Td>
                                    <Button size="sm" colorScheme="blue" onClick={() => navigate(`/dashboard/reports/${report.id}`)}>
                                        Просмотреть
                                    </Button>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
                <Button colorScheme="teal" onClick={() => navigate("/dashboard")} mt={6}>
                    Вернуться к панели администратора
                </Button>
            </Box>
    );
}

export default Reports;
