import React, { createContext, useContext, useState } from 'react';

const SettingsContext = createContext();

export const useSettings = () => useContext(SettingsContext);

export const SettingsProvider = ({ children }) => {
    const [settings, setSettings] = useState({
        groups: {
            default: {
                attendance: 1,
                labs: 5,
                selfWorks: 3,
                extraTasks: 2,
                projects: 10
            }
        }
    });

    const value = {
        settings,
        setSettings
    };

    return (
        <SettingsContext.Provider value={value}>
            {children}
        </SettingsContext.Provider>
    );
};
