import React from 'react';
import {Text, Button, Box, VStack, Flex} from '@chakra-ui/react';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error('ErrorBoundary caught an error', error, errorInfo);
        this.setState({ error, errorInfo });
    }

    handleReload = () => {
        this.setState({ hasError: false, error: null, errorInfo: null });
        window.location.reload();
    };

    render() {
        if (this.state.hasError) {
            return (
                <Flex flex-direction="column" textAlign="center" mt="20">
                    <Text fontSize="2xl" mb="4">Что-то пошло не так.</Text>
                    <VStack spacing="8">
                        <Text>{this.state.error && this.state.error.toString()}</Text>
                        <Button onClick={this.handleReload} colorScheme="teal">
                            Перезагрузить страницу
                        </Button>
                    </VStack>
                </Flex>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
