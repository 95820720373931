// packages/client/src/components/admin/AdminDashboard.jsx
import React from 'react';
import { Routes, Route, Link, Navigate, useNavigate } from 'react-router-dom';
import { Box, Flex, VStack } from '@chakra-ui/react';
import { AccountContext } from '../../contexts/AccountContext';
import UserManagement from './UserManagement';
import Logs from './Logs';
import Settings from './Settings';
import FinancialMetrics from './FinancialMetrics';
import EmployeeReports from './EmployeeReports';
import DashboardHome from './DashboardHome';
import NavbarHeader from '../Main/NavbarHeader';
import Reports from './Reports';
import '../../pages/styles/Admin/AdminDashboard.css';
import ReportDetails from "../ReportDetails";
import CreateReport from "../CreateReport";
import Footer from "../Footer";

const AdminDashboard = () => {
    const { user } = React.useContext(AccountContext);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!user || user.role !== 'admin') {
            navigate('/');
        }
    }, [user, navigate]);

    if (!user || user.role !== 'admin') {
        return null;
    }

    return (
        <Box>
            <NavbarHeader />
            <Flex>
                <Box width="250px" bg="gray.700" color="white" minH="100vh" p={5}>
                    <VStack align="start" spacing={4}>
                        <Link to="/dashboard" className="admin-nav-link">Главная</Link>
                        <Link to="/dashboard/user-management" className="admin-nav-link">Управление пользователями</Link>
                        <Link to="/dashboard/logs" className="admin-nav-link">Логи</Link>
                        <Link to="/dashboard/settings" className="admin-nav-link">Настройки</Link>
                        <Link to="/dashboard/financial-metrics" className="admin-nav-link">Финансовые Метрики</Link>
                        <Link to="/dashboard/employee-reports" className="admin-nav-link">Отчетность Сотрудников</Link>
                        <Link to="/dashboard/reports" className="admin-nav-link">Отчетность</Link> {/* Новая ссылка */}
                    </VStack>
                </Box>

                {/* Область контента */}
                <Box flex="1" p={5}>
                    <Routes>
                        <Route path="/" element={<DashboardHome />} />
                        <Route path="user-management" element={<UserManagement />} />
                        <Route path="logs" element={<Logs />} />
                        <Route path="settings" element={<Settings />} />
                        <Route path="financial-metrics" element={<FinancialMetrics />} />
                        <Route path="employee-reports" element={<EmployeeReports />} />
                        <Route path="reports" element={<Reports />} />
                        <Route path="reports/new" element={<CreateReport />} />
                        <Route path="reports/:id" element={<ReportDetails />} />

                    </Routes>
                </Box>

            </Flex>
            <Footer />
        </Box>
    );
};

export default AdminDashboard;
