import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AccountContext } from '../contexts/AccountContext';

const ProtectedRoute = ({ children, roles }) => {
    const { user } = useContext(AccountContext);

    if (user.loggedIn === null) {
        return <div>Загрузка...</div>;
    }

    if (!user.loggedIn) {
        return <Navigate to="/" replace />;
    }

    if (roles && !roles.includes(user.role)) {
        return <Navigate to="/unauthorized" replace />;
    }

    return children;
};

export default ProtectedRoute;
