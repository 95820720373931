import React, { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input, VStack, useToast } from '@chakra-ui/react';
import api from '../../utils/api';

const BulkReceiptForm = ({ onSuccess }) => {
    const [itemName, setItemName] = useState('');
    const [quantity, setQuantity] = useState('');

    const toast = useToast();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post('/api/items/receipt', { name: itemName, quantity });
            if (response.data.success) {
                setItemName('');
                setQuantity('');
                onSuccess();
                toast({
                    title: 'Предметы приняты.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                throw new Error(response.data.message || 'Не удалось принять предметы.');
            }
        } catch (error) {
            console.error('Ошибка при приеме предметов:', error);
            toast({
                title: 'Ошибка при приеме предметов.',
                description: error.message || 'Произошла ошибка.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    return (
        <Box as="form" onSubmit={handleSubmit}>
            <VStack spacing="4" align="start">
                <FormControl id="itemName" isRequired>
                    <FormLabel>Название предмета</FormLabel>
                    <Input
                        type="text"
                        value={itemName}
                        onChange={(e) => setItemName(e.target.value)}
                        placeholder="Введите название предмета"
                    />
                </FormControl>

                <FormControl id="quantity" isRequired>
                    <FormLabel>Количество</FormLabel>
                    <Input
                        type="number"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        placeholder="Введите количество"
                        min="1"
                    />
                </FormControl>

                <Button type="submit" colorScheme="teal">
                    Принять Предметы
                </Button>
            </VStack>
        </Box>
    );
};

export default BulkReceiptForm;
