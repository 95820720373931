import React, {useState, useEffect, useContext} from 'react';
import {
    Box,
    VStack,
    Button,
    Input,
    Heading,
    FormControl,
    FormLabel,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import '../../../pages/styles/Settings/ProfileSettings.css';
import {AccountContext} from "../../../contexts/AccountContext";
import api from "../../../utils/api";


const ProfileSettings = () => {
    const { logout } = useContext(AccountContext);
    const [userData, setUserData] = useState({
        username: '',
        firstName: '',
        lastName: '',
        middleName: '',
        birthDate: '',
        email: ''
    });

    const [passwords, setPasswords] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });

    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const navigate = useNavigate();

    useEffect(() => {
        fetchUserData();
    }, []);
    const fetchUserData = async () => {
        try {
            console.log('Начало загрузки данных пользователя');
            const response = await api.get('/api/user');

            const data = response.data;
            console.log('Полученные данные:', data);

            if (!data || typeof data !== 'object') {
                throw new Error('Получены некорректные данные пользователя');
            }

            setUserData({
                username: data.username || '',
                firstName: data.first_name || '',
                lastName: data.last_name || '',
                middleName: data.middle_name || '',
                birthDate: data.birth_date || '',
                email: data.email || ''
            });

            console.log('Данные успешно установлены в состояние');
        } catch (error) {
            console.error('Ошибка при загрузке данных пользователя:', error);
            toast({
                title: "Ошибка",
                description: error.message || "Не удалось загрузить данные пользователя.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });

        }
    };


    const handleUpdateProfile = async () => {
        try {
            const response = await api.put('/api/user/update', userData);

            toast({
                title: "Успешно",
                description: "Профиль обновлён",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Ошибка",
                description: "Не удалось обновить профиль",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleChangePassword = async () => {
        if (passwords.newPassword !== passwords.confirmPassword) {
            toast({
                title: "Ошибка",
                description: "Пароли не совпадают",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        try {
            const response = await api.put('/api/user/password', passwords);

            toast({
                title: "Успешно",
                description: "Пароль изменен",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            onClose();
            setPasswords({ currentPassword: '', newPassword: '', confirmPassword: '' });
        } catch (error) {
            toast({
                title: "Ошибка",
                description: "Не удалось изменить пароль",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleLogoutClick = () => {
        logout();
    };

    return (
        <Box className="profile-settings-container">
            <Heading size="lg" className="profile-heading">Настройки профиля</Heading>

            <VStack spacing={4} className="profile-form">
                <FormControl>
                    <FormLabel>Имя пользователя</FormLabel>
                    <Input
                        value={userData.username}
                        onChange={(e) => setUserData({...userData, username: e.target.value})}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Имя</FormLabel>
                    <Input
                        value={userData.firstName}
                        onChange={(e) => setUserData({...userData, firstName: e.target.value})}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Фамилия</FormLabel>
                    <Input
                        value={userData.lastName}
                        onChange={(e) => setUserData({...userData, lastName: e.target.value})}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Отчество</FormLabel>
                    <Input
                        value={userData.middleName}
                        onChange={(e) => setUserData({...userData, middleName: e.target.value})}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Дата рождения</FormLabel>
                    <Input
                        type="date"
                        value={userData.birthDate}
                        onChange={(e) => setUserData({...userData, birthDate: e.target.value})}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input
                        type="email"
                        value={userData.email}
                        onChange={(e) => setUserData({...userData, email: e.target.value})}
                    />
                </FormControl>

                <Button colorScheme="blue" onClick={handleUpdateProfile} className="update-button">
                    Сохранить изменения
                </Button>

                <Button colorScheme="purple" onClick={onOpen} className="change-password-button">
                    Изменить пароль
                </Button>

                <Button colorScheme="red" onClick={handleLogoutClick} className="logout-button">
                    Выйти из аккаунта
                </Button>
            </VStack>

            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent className="password-modal">
                    <ModalHeader>Изменение пароля</ModalHeader>
                    <ModalBody>
                        <VStack spacing={4}>
                            <FormControl>
                                <FormLabel>Текущий пароль</FormLabel>
                                <Input
                                    type="password"
                                    value={passwords.currentPassword}
                                    onChange={(e) => setPasswords({...passwords, currentPassword: e.target.value})}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Новый пароль</FormLabel>
                                <Input
                                    type="password"
                                    value={passwords.newPassword}
                                    onChange={(e) => setPasswords({...passwords, newPassword: e.target.value})}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Подтвердите новый пароль</FormLabel>
                                <Input
                                    type="password"
                                    value={passwords.confirmPassword}
                                    onChange={(e) => setPasswords({...passwords, confirmPassword: e.target.value})}
                                />
                            </FormControl>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleChangePassword}>
                            Изменить
                        </Button>
                        <Button onClick={onClose}>Отмена</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default ProfileSettings;