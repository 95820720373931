import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { AccountContext } from "../contexts/AccountContext";
import ProtectedRoute from "../components/ProtectedRoute";
import useSettings from "../hooks/useSettings";
import UnauthorizedPage from "../pages/Errors/401UnauthorizedPage";
import WaitingPage from "../pages/WaitingPage";
import BadRequestPage from "../pages/Errors/400BadRequestPage";
import ForbiddenPage from "../pages/Errors/403ForbiddenPage";
import InternalServerErrorPage from "../pages/Errors/500InternalServerErrorPage";
import GonePage from "../pages/Errors/410GonePage";
import BadGatewayPage from "../pages/Errors/502BadGatewayPage";
import ServiceUnavailablePage from "../pages/Errors/503ServiceUnavailablePage";
import GatewayTimeoutPage from "../pages/Errors/504GatewayTimeoutPage";
import NotFoundPage from "../pages/Errors/404NotFoundPage";
import useIdleTimer from "../hooks/useIdleTimer";
import LoginPage from "../pages/LoginPage";
import FullRegPage from "../pages/FullRegPage";
import MainPage from "../pages/MainPage";
import SettingsPage from "../pages/SettingsPage";
import AboutPage from "../pages/AboutPage";
import AdminDashboard from "../components/admin/AdminDashboard";
import AdminLogsPage from "../components/admin/settings/AdminLogsPage";
import InventoryPage from "../pages/InventoryPage";
import CatalogPage from "../pages/CatalogPage";
import EquipmentReceiptPage from "../pages/EquipmentReceiptPage";
import EquipmentSendPage from "../pages/EquipmentSendPage";
import EquipmentCardPage from "../pages/EquipmentCardPage";
import AllEquipmentPage from "../pages/AllEquipmentPage";
import SearchPage from "../pages/SearchPage";
import WarehousePage from "../pages/WarehousePage";
import BrandsPage from "../pages/BrandsPage";
import UsersPage from "../pages/UsersPage";
import MaintenancePage from "../pages/MaintenancePage";
import HistoryPage from "../pages/HistoryPage";
import NotificationsPage from "../pages/NotificationsPage";
import NotesPage from "../pages/NotesPage";
import UserManagement from "./admin/UserManagement";
import Logs from "./admin/Logs";
import Settings from "./admin/Settings";
import EmployeeReports from "./admin/EmployeeReports";
import FinancialMetrics from "./admin/FinancialMetrics";
import PublicReports from "./PublicReports";

const Views = () => {
    const { user, logout } = useContext(AccountContext);
    const inactivityTimeout = useSettings(user.loggedIn);


    useIdleTimer(() => {
        if (inactivityTimeout !== Infinity) {
            logout();
        }
    }, inactivityTimeout);

    return (
        <Routes>
            {/* Открытые маршруты */}
            <Route path="/" element={<LoginPage />} />
            <Route path="/waiting" element={<WaitingPage />} />
            <Route path="/registration" element={<FullRegPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/unauthorized" element={<UnauthorizedPage />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/catalog" element={<CatalogPage />} />
            <Route path="/catalog/:type/:id" element={<CatalogPage />} />
            <Route path="/catalog/:categoryId" element={<CatalogPage />} />
            <Route path="/maintenance" element={<MaintenancePage />} />
            <Route path="/warehouse" element={<WarehousePage />} />
            <Route path="/brands" element={<BrandsPage />} />
            <Route path="/inventory" element={<InventoryPage />} />
            <Route path="/inventory/receipt" element={<EquipmentReceiptPage />} />
            <Route path="/inventory/send" element={<EquipmentSendPage />} />
            <Route path="/equipment/:id" element={<EquipmentCardPage />} />
            <Route path="/all-equipment" element={<AllEquipmentPage />} />



            {/* Админские маршруты */}
            <Route path="/users" element={
                <ProtectedRoute roles={['admin']}>
                    <UsersPage />
                </ProtectedRoute>
            } />

            <Route path="/dashboard/*" element={
                <ProtectedRoute roles={['admin']}>
                    <AdminDashboard />
                </ProtectedRoute>
            } />

            <Route path="/reports" element={
                <ProtectedRoute>
                    <PublicReports />
                </ProtectedRoute>
            } />

            <Route path="/admin/logs" element={
                <ProtectedRoute roles={['admin']}>
                    <AdminLogsPage />
                </ProtectedRoute>
            } />

            {/* Основные защищённые маршруты */}
            <Route path="/main" element={
                <ProtectedRoute>
                    <MainPage />
                </ProtectedRoute>
            } />
            <Route path="/catalog/equipment/:category" element={
                <ProtectedRoute>
                    <CatalogPage />
                </ProtectedRoute>
            } />
            <Route path="/equipment/:id" element={
                <ProtectedRoute>
                    <EquipmentCardPage />
                </ProtectedRoute>
            } />

            <Route path="/settings/*" element={
                <ProtectedRoute>
                    <SettingsPage />
                </ProtectedRoute>
            } />
            <Route path="/history" element={
                <ProtectedRoute>
                    <HistoryPage />
                </ProtectedRoute>
            } />

            <Route path="/notes" element={
                <ProtectedRoute>
                    <NotesPage />
                </ProtectedRoute>
            } />

            <Route path="/notifications" element={
                <ProtectedRoute>
                    <NotificationsPage />
                </ProtectedRoute>
            } />

            {/* Маршруты для страниц ошибок */}
            <Route path="/400" element={<BadRequestPage />} />
            <Route path="/401" element={<UnauthorizedPage />} />
            <Route path="/403" element={<ForbiddenPage />} />
            <Route path="/410" element={<GonePage />} />
            <Route path="/500" element={<InternalServerErrorPage />} />
            <Route path="/502" element={<BadGatewayPage />} />
            <Route path="/503" element={<ServiceUnavailablePage />} />
            <Route path="/504" element={<GatewayTimeoutPage />} />
            {/* Маршрут по умолчанию для 404 */}
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
};

export default Views;
