import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import {
    Box,
    Flex,
    Image,
    Heading,
    Text,
} from '@chakra-ui/react';
import '../../pages/styles/Login/LoginHeading.css';
import logo from '../../pages/styles/images/logo.png';

export default function LoginHeading() {
    return (
        <header className="LoginHeading-header">
            <Image src={logo} alt="Логотип" className="LoginHeading-logo"/>
            <Heading as="h1" size="lg" className="LoginHeading-name">
                СВОП<br/>
                Система Веб-учёта Оборудования Предприятия
            </Heading>
        </header>
    );
}
