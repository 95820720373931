// packages/client/src/components/Main/EquipmentCard.jsx
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Flex, Spinner, Text, Button, HStack, VStack } from '@chakra-ui/react';
import useEquipment from '../../hooks/useEquipment';
import EquipmentImage from './EquipmentImage';
import EquipmentSummary from './EquipmentSummary';
import EquipmentDetailsTable from './EquipmentDetailsTable';
import EditButton from './EditButton';
import QRCodeDisplay from './QRCodeDisplay';
import useAccount from '../../hooks/useAccount';
import './EquipmentCard.css';

const EquipmentCard = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { equipment, loading, error } = useEquipment(id);
    const { user, loading: userLoading } = useAccount();

    const handleBackClick = () => {
        navigate(-1);
    };

    if (loading || userLoading) {
        return (
            <Flex className="loading-container">
                <Spinner size="xl" />
            </Flex>
        );
    }

    if (error || !equipment) {
        return (
            <Flex className="error-container">
                <Text className="error-text">
                    Произошла ошибка при загрузке данных оборудования.
                </Text>
            </Flex>
        );
    }

    console.log('Полученные данные оборудования:', equipment);

    const equipmentImages = equipment.images && equipment.images.length > 0
        ? equipment.images
        : [equipment.photo_url].filter(url => url); // Убедитесь, что photo_url не пустой

    return (
        <Box className="equipment-card-container">
            <Flex justify="space-between" align="center" mb="4">
                <Button colorScheme="teal" onClick={handleBackClick}>
                    Назад
                </Button>
                <HStack spacing={4}>
                    <EditButton equipmentId={equipment.id} />
                    <QRCodeDisplay equipment={equipment} />
                </HStack>
            </Flex>
            <Flex className="equipment-card-content" direction={{ base: 'column', md: 'row' }} gap="6">
                {/* Предпросмотр изображения слева */}
                <EquipmentImage images={equipmentImages} alt={`${equipment.category_name} ${equipment.model}`} />

                {/* Краткие данные справа */}
                <VStack align="start" spacing="4" flex="1">
                    <EquipmentSummary
                        brand={equipment.brand_name || 'Не указано'}
                        model={equipment.model || 'Не указано'}
                        serial_number={equipment.serial_number || 'Не указано'}
                        location={equipment.location || 'Не указано'}
                    />
                </VStack>
            </Flex>

            {/* Таблица с подробными данными */}
            <EquipmentDetailsTable equipment={equipment} />
        </Box>
    );
};

export default EquipmentCard;