import React from 'react';
import {
    Box,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Container
} from '@chakra-ui/react';


const GeneralSettings = () => {
    return (
        <Container maxW="container.xl" p={4}>
            <Tabs variant="enclosed" colorScheme="blue">
                <TabList>

                </TabList>

                <TabPanels>

                </TabPanels>
            </Tabs>
        </Container>
    );
};

export default GeneralSettings;
