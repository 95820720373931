import React, { useState } from 'react';
import { Box, Image, HStack, IconButton } from '@chakra-ui/react';
import { ViewIcon, CloseIcon } from '@chakra-ui/icons';
import Lightbox from 'yet-another-react-lightbox';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';
import './EquipmentImage.css';

const EquipmentImage = ({ images, alt }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    if (!images || images.length === 0) {
        images = ['/images/placeholder.png'];
    }

    const openLightbox = () => {
        setIsOpen(true);
    };

    const closeLightbox = () => {
        setIsOpen(false);
    };

    const handleMove = (index) => {
        setCurrentIndex(index);
    };

    return (
        <Box className="equipment-image-container">
            <Box position="relative" cursor="pointer" onClick={openLightbox}>
                <Image
                    src={images[currentIndex]}
                    alt={alt}
                    className="equipment-large-image"
                    onError={(e) => { e.target.src = '/images/placeholder.png'; }}
                />
                <IconButton
                    aria-label="Увеличить изображение"
                    icon={<ViewIcon />}
                    position="absolute"
                    top="10px"
                    right="10px"
                    size="sm"
                    colorScheme="teal"
                    onClick={openLightbox}
                />
            </Box>

            {isOpen && (
                <Lightbox
                    open={isOpen}
                    close={closeLightbox}
                    index={currentIndex}
                    slides={images.map((img) => ({ src: img }))}
                    plugins={[Thumbnails, Zoom]}
                    on={{
                        move(index) {
                            setCurrentIndex(index);
                        },
                        close: closeLightbox,
                    }}
                />
            )}

            {images.length > 1 && (
                <HStack spacing="2" mt="4" overflowX="auto">
                    {images.map((img, index) => (
                        <Box
                            key={index}
                            borderWidth={currentIndex === index ? '2px' : '1px'}
                            borderColor={currentIndex === index ? 'teal.500' : 'gray.200'}
                            borderRadius="md"
                            overflow="hidden"
                            cursor="pointer"
                            onClick={() => setCurrentIndex(index)}
                        >
                            <Image
                                src={img}
                                alt={`${alt} ${index + 1}`}
                                boxSize="60px"
                                objectFit="cover"
                                onError={(e) => { e.target.src = '/images/placeholder.png'; }}
                            />
                        </Box>
                    ))}
                </HStack>
            )}
        </Box>
    );
};

export default EquipmentImage;
