import React, { useEffect, useState } from 'react';
import { Box, Heading, Text, Spinner, useToast } from '@chakra-ui/react';
import api from '../../utils/api';

const Logs = () => {
    const [logs, setLogs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();

    const fetchLogs = async () => {
        setIsLoading(true);
        try {
            const response = await api.get('/api/admin/logs', { withCredentials: true });
            setLogs(response.data.logs);
        } catch (error) {
            console.error('Ошибка при получении логов:', error);
            toast({
                title: "Ошибка",
                description: "Не удалось загрузить логи.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchLogs();
    }, []);

    if (isLoading) {
        return <Spinner size="xl" />;
    }

    return (
        <Box>
            <Heading mb={4}>Логи системы</Heading>
            {logs.length === 0 ? (
                <Text>Логи отсутствуют.</Text>
            ) : (
                logs.map((log, index) => (
                    <Box key={index} mb={3} p={3} borderWidth="1px" borderRadius="md">
                        <Text fontWeight="bold">{log.timestamp}</Text>
                        <Text>{log.message}</Text>
                    </Box>
                ))
            )}
        </Box>
    );
};

export default Logs;
