import React, { useContext, useState } from 'react';
import {
    Box,
    Flex,
    Spinner,
    Text,
    Button,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    useColorModeValue
} from '@chakra-ui/react';
import NavbarHeader from '../components/Main/NavbarHeader';
import Footer from '../components/Footer';
import NotesList from '../components/Notes/NotesList';
import NotesContext from '../contexts/NotesContext';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const NotesPage = () => {
    const { notes, isLoading, createNote } = useContext(NotesContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [reminderAt, setReminderAt] = useState(new Date());
    const toast = useToast();

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => {
        setIsModalOpen(false);
        setTitle('');
        setMessage('');
        setReminderAt(new Date());
    };

    const handleCreateNote = async () => {
        if (!title.trim() || !message.trim()) {
            toast({
                title: "Ошибка.",
                description: "Пожалуйста, заполните все поля.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        await createNote(title, message, reminderAt);
        closeModal();
    };

    const customInputStyle = useColorModeValue({
        border: '1px solid',
        borderColor: 'gray.300',
        borderRadius: 'md',
        padding: '8px',
        width: '100%',
    }, {
        border: '1px solid',
        borderColor: 'gray.600',
        borderRadius: 'md',
        padding: '8px',
        width: '100%',
    });

    return (
        <div>
            <NavbarHeader />
            <Box p={4} maxW="1200px" mx="auto" minH="80vh">
                <Flex justify="space-between" align="center" mb={4}>
                    <Text fontSize="2xl" fontWeight="bold">Мои Заметки</Text>
                    <Button colorScheme="teal" onClick={openModal}>
                        Создать Заметку
                    </Button>
                </Flex>
                {isLoading ? (
                    <Flex justify="center" align="center" height="60vh">
                        <Spinner size="xl" />
                    </Flex>
                ) : notes.length === 0 ? (
                    <Flex justify="center" align="center" height="60vh">
                        <Text>У вас пока нет заметок.</Text>
                    </Flex>
                ) : (
                    <NotesList notes={notes} />
                )}
            </Box>
            <Footer />

            {/* Модальное окно для создания заметки */}
            <Modal isOpen={isModalOpen} onClose={closeModal} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Создать Заметку</ModalHeader>
                    <ModalBody>
                        <FormControl id="title" mb={4} isRequired>
                            <FormLabel>Заголовок</FormLabel>
                            <Input
                                placeholder="Введите заголовок"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </FormControl>
                        <FormControl id="message" isRequired>
                            <FormLabel>Содержание</FormLabel>
                            <Textarea
                                placeholder="Введите содержание заметки"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </FormControl>
                        <FormControl id="reminder" mt={4}>
                            <FormLabel>Установить напоминание</FormLabel>
                            <DatePicker
                                selected={reminderAt}
                                onChange={(date) => setReminderAt(date)}
                                showTimeSelect
                                dateFormat="Pp"
                                minDate={new Date()}
                                customInput={<Input />}
                                wrapperClassName="react-datepicker-wrapper"
                                calendarClassName="react-datepicker-calendar"
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="teal" mr={3} onClick={handleCreateNote}>
                            Создать
                        </Button>
                        <Button variant="ghost" onClick={closeModal}>Отмена</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default NotesPage;
