import React, { useState } from 'react';
import {
    Box,
    Flex,
    Text,
    Button,
    FormControl,
    FormLabel,
    Input,
    VStack,
    Image,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useZxing } from 'react-zxing';
import NavbarHeader from '../components/Main/NavbarHeader';
import Footer from '../components/Footer';
import api from '../utils/api';
import { FaQrcode } from 'react-icons/fa';
import './styles/EquipmentReceiptPage.css';

const EquipmentReceiptPage = () => {
    const [mode, setMode] = useState('manual');
    const [equipmentId, setEquipmentId] = useState('');
    const [quantity, setQuantity] = useState('');
    const [receivedFrom, setReceivedFrom] = useState('');
    const [receiptDate, setReceiptDate] = useState(new Date().toISOString().split('T')[0]);
    const [photos, setPhotos] = useState([]);
    const [addedItems, setAddedItems] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentComment, setCurrentComment] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const toast = useToast();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!equipmentId || !quantity || !receivedFrom || !photos.length) {
            toast({
                title: 'Ошибка.',
                description: 'Необходимо заполнить все поля и прикрепить фотографии.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        try {
            const formData = new FormData();
            formData.append('equipment_id', equipmentId);
            formData.append('quantity', quantity);
            formData.append('receipt_date', receiptDate);
            formData.append('received_from', receivedFrom);
            photos.forEach((photo) => {
                formData.append('photos', photo);
            });

            const response = await api.post('/api/inventory/receipt', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.success) {
                setAddedItems([...addedItems, response.data.history]);
                toast({
                    title: 'Успех.',
                    description: response.data.message,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                setEquipmentId('');
                setQuantity('');
                setReceivedFrom('');
                setReceiptDate(new Date().toISOString().split('T')[0]);
                setPhotos([]);
            } else {
                toast({
                    title: 'Ошибка.',
                    description: response.data.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error('Ошибка при приёмке оборудования:', error);
            toast({
                title: 'Ошибка при приёмке оборудования.',
                description: error.response?.data?.message || 'Произошла ошибка.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleScan = (result) => {
        if (result) {
            setEquipmentId(result);
        }
    };

    const handleError = (error) => {
        console.error(error);
        toast({
            title: 'Ошибка при сканировании QR кода.',
            description: 'Попробуйте снова.',
            status: 'error',
            duration: 3000,
            isClosable: true,
        });
    };

    const { ref } = useZxing({
        onResult: (result, error) => {
            if (result) {
                handleScan(result.getText());
            }
            if (error) {
                handleError(error);
            }
        },
        constraints: {
            video: {
                facingMode: 'environment',
            },
        },
    });

    const openCommentModal = (item) => {
        setSelectedItem(item);
        setCurrentComment(item.comments || '');
        setIsModalOpen(true);
    };

    const closeCommentModal = () => {
        setIsModalOpen(false);
        setSelectedItem(null);
        setCurrentComment('');
    };

    const saveComment = async () => {
        if (!selectedItem) return;

        try {
            await api.put(`/api/inventory/receipt/comment/${selectedItem.id}`, { comment: currentComment });
            setAddedItems(prevItems => prevItems.map(item =>
                item.id === selectedItem.id ? { ...item, comments: currentComment } : item
            ));
            toast({
                title: 'Комментарий сохранён.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            closeCommentModal();
        } catch (error) {
            console.error('Ошибка при сохранении комментария:', error);
            toast({
                title: 'Ошибка при сохранении комментария.',
                description: error.response?.data?.message || 'Произошла ошибка.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleFileChange = (e) => {
        setPhotos(Array.from(e.target.files));
    };

    return (
        <Box className="receipt-container">
            <NavbarHeader />
            <Box className="receipt-content">
                <Text className="receipt-header" mb="4">Приёмка Оборудования</Text>
                <Box className="receipt-form">
                    <form onSubmit={handleSubmit}>
                        <FormControl mb="4">
                            <FormLabel>Режим</FormLabel>
                            <Box className="button-group">
                                <Button onClick={() => setMode('manual')} mr="2">
                                    Ручной ввод
                                </Button>
                                <Button onClick={() => setMode('scan')}>
                                    Сканирование QR кода
                                </Button>
                            </Box>
                        </FormControl>

                        {mode === 'scan' && (
                            <Box mb="4">
                                <div ref={ref} className="scanner-container" />
                            </Box>
                        )}

                        <FormControl mb="4">
                            <FormLabel>ID Оборудования</FormLabel>
                            <Input
                                type="text"
                                value={equipmentId}
                                onChange={(e) => setEquipmentId(e.target.value)}
                                placeholder="Введите ID оборудования"
                            />
                        </FormControl>

                        <FormControl mb="4">
                            <FormLabel>Количество</FormLabel>
                            <Input
                                type="number"
                                value={quantity}
                                onChange={(e) => setQuantity(e.target.value)}
                                placeholder="Введите количество"
                            />
                        </FormControl>

                        <FormControl mb="4">
                            <FormLabel>От кого получено</FormLabel>
                            <Input
                                type="text"
                                value={receivedFrom}
                                onChange={(e) => setReceivedFrom(e.target.value)}
                                placeholder="Введите источник получения"
                            />
                        </FormControl>

                        <FormControl mb="4">
                            <FormLabel>Дата приёмки</FormLabel>
                            <Input
                                type="date"
                                value={receiptDate}
                                onChange={(e) => setReceiptDate(e.target.value)}
                            />
                        </FormControl>

                        <FormControl mb="4">
                            <FormLabel>Фотографии</FormLabel>
                            <Input
                                type="file"
                                multiple
                                accept="image/*"
                                onChange={handleFileChange}
                            />
                        </FormControl>

                        <Button type="submit" colorScheme="blue" width="full">
                            Принять оборудование
                        </Button>
                    </form>
                </Box>

                <Box w="100%" maxW="600px" mt="8">
                    <Text fontSize="xl" mb="4">Принятое Оборудование</Text>
                    {addedItems.length > 0 ? (
                        addedItems.map(item => (
                            <Box key={item.id} p="4" borderWidth="1px" borderRadius="md" mb="4">
                                <Text><strong>ID:</strong> {item.equipment_id}</Text>
                                <Text><strong>Количество:</strong> {item.quantity}</Text>
                                <Text><strong>Принимает от:</strong> {item.details.split(' ')[2]}</Text>
                                <Text><strong>Дата:</strong> {item.date}</Text>
                                {item.photos && (
                                    <Box mt="2" className="image-gallery">
                                        {item.photos.split(',').map((photo, index) => (
                                            <Image key={index} src={photo} alt={`Фото ${index + 1}`} />
                                        ))}
                                    </Box>
                                )}
                                {item.comments ? (
                                    <Text mt="2"><strong>Комментарий:</strong> {item.comments}</Text>
                                ) : (
                                    <Button mt="2" size="sm" onClick={() => openCommentModal(item)}>
                                        Добавить комментарий
                                    </Button>
                                )}
                            </Box>
                        ))
                    ) : (
                        <Text>Нет принятых предметов.</Text>
                    )}
                </Box>
                <Footer />
            </Box>


            {/* Модальное окно для добавления комментария */}
            <Modal isOpen={isModalOpen} onClose={closeCommentModal}>
                <ModalOverlay />
                <ModalContent className="comment-modal">
                    <ModalHeader>Добавить Комментарий</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl id="comment">
                            <FormLabel>Комментарий</FormLabel>
                            <Input
                                type="text"
                                value={currentComment}
                                onChange={(e) => setCurrentComment(e.target.value)}
                                placeholder="Введите комментарий"
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={saveComment}>
                            Сохранить
                        </Button>
                        <Button variant="ghost" onClick={closeCommentModal}>Отмена</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
}

    export default EquipmentReceiptPage;
