import React, { Suspense } from 'react';
import { ColorModeScript } from '@chakra-ui/react';
import theme from './theme';
import ErrorBoundary from "./components/ErrorBoundary";
import Hotkeys from "./components/Hotkeys";
import AccountContextProvider from "./contexts/AccountContext";
import NotificationsContextProvider from "./contexts/NotificationsContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import PageTitle from './components/common/PageTitle';
import Views from "./components/Views";
import AlfaVersionModal from "./components/common/AlphaVersionModal";
import {NotesContextProvider} from "./contexts/NotesContext";

function App() {
    return (
        <ErrorBoundary>
            <Suspense fallback={<div>Loading...</div>}>
                <ColorModeScript initialColorMode={theme.config.initialColorMode} />
                <AccountContextProvider>
                    <NotificationsContextProvider>
                        <NotesContextProvider>
                            <SettingsProvider>
                                <PageTitle />
                                <Hotkeys />
                                <Views />
                                <AlfaVersionModal />
                            </SettingsProvider>
                        </NotesContextProvider>
                    </NotificationsContextProvider>
                </AccountContextProvider>
            </Suspense>
        </ErrorBoundary>
    );
}

export default React.memo(App);