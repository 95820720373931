import React from 'react';
import { Spinner, Box } from '@chakra-ui/react';

const LoadingSpinner = () => (
    <Box
        position="fixed"
        top="0"
        left="0"
        right="0"
        bottom="0"
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundColor="rgba(255, 255, 255, 0.8)"
        zIndex="9999"
    >
        <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
        />
    </Box>
);

export default LoadingSpinner;