import React from 'react';
import './styles/AboutPage.css';
import logo from './styles/images/logo.png';
import Footer from "../components/Footer";

function AboutPage() {
  return (
    <div className="About-container">
      <header className="About-header">
        <img src={logo} alt="Логотип системы Веб-учёта" className="About-logo" />
        <h1 className="college-name">CATHEV<br/>Система Веб-учёта Оборудования Предприятия </h1>
      </header>
      <div className="main-About-content">
        <div className="text-block">
            <h2>Удобный и интуитивно понятный интерфейс</h2>
            <p>Наша система Веб-учёта разработана с учетом удобства пользователей и обеспечивает плавный переход от традиционных бумажных журналов. </p>
        </div>
        <div className="text-block">
            <h2>Оперативное обновление данных</h2>
            <p>Система Веб-учёта гарантирует, что все данные обновляются мгновенно, предоставляя сотрудникам доступ к самой актуальной информации. Это устраняет задержки и обеспечивает своевременную обратную связь.</p>
        </div>
        <div className="text-block">
            <h2>Индивидуальные рейтинги и сравнительный анализ</h2>
            <p>.</p>
        </div>
        <div className="text-block">
            <h2>Конструктивная обратная связь для эффективного развития</h2>
            <p>.</p>
        </div>
        <div className="text-block">
            <h2>Точное и объективное определение рейтингов</h2>
            <p> .</p>
        </div>
        <div className="text-block">
            <h2>Прозрачность для укрепления доверия и ответственности</h2>
            <p>.</p>
        </div>
      </div>
        <Footer />
    </div>
  );
}

export default AboutPage;
