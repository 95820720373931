import React from 'react';
import {
    IconButton,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    useDisclosure,
    Text,
    VStack,
    Box,
} from '@chakra-ui/react';
import { FaQrcode } from 'react-icons/fa';
import { QRCodeCanvas } from 'qrcode.react';

const QRCodeDisplay = ({ equipment }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const qrValue = `Номер: ${equipment.number}, Модель: ${equipment.model}, Серийный номер: ${equipment.serial_number}`;

    return (
        <>
            <IconButton
                icon={<FaQrcode />}
                aria-label="Показать QR-код"
                onClick={onOpen}
                variant="outline"
                colorScheme="teal"
                size="sm"
            />

            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>QR-код Оборудования</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={4}>
                            <Box position="relative" display="inline-block">
                                <QRCodeCanvas value={qrValue} size={200} />
                                <Box
                                    position="absolute"
                                    top="50%"
                                    left="50%"
                                    transform="translate(-50%, -50%)"
                                    bg="white"
                                    p={2}
                                    borderRadius="full"
                                >
                                    <Text fontSize="22px" fontWeight="bold">
                                        KVCG
                                    </Text>
                                </Box>
                            </Box>
                            <Text><strong>Номер:</strong> {equipment.number}</Text>
                            <Text><strong>Модель:</strong> {equipment.model}</Text>
                            <Text><strong>Серийный номер:</strong> {equipment.serial_number}</Text>
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default QRCodeDisplay;
