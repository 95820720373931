import React, { useEffect, useState } from 'react';
import {
    Box,
    Heading,
    FormControl,
    FormLabel,
    Select,
    Input,
    Textarea,
    Button,
    useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import NavbarHeader from './Main/NavbarHeader';
import Footer from './Footer';

const CreateReport = () => {
    const [types] = useState([
        'addition',
        'decommission',
        'dispatch',
        'receive',
        'service_dispatch',
        'service_receive',
        'work_documents',
    ]);
    const [equipment, setEquipment] = useState([]);
    const [formData, setFormData] = useState({
        type: '',
        equipment_id: '',
        description: '',
        date: '',
    });
    const toast = useToast();
    const navigate = useNavigate();

    const fetchEquipment = async () => {
        try {
            const response = await axios.get('/api/dashboard/equipment', { withCredentials: true });
            setEquipment(response.data.data.equipment);
        } catch (error) {
            console.error('Ошибка при получении оборудования:', error);
            toast({
                title: "Ошибка",
                description: "Не удалось загрузить оборудование.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        fetchEquipment();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('/api/dashboard/reports', formData, { withCredentials: true });
            toast({
                title: "Успех",
                description: "Отчет успешно создан.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            navigate("/dashboard/reports");
        } catch (error) {
            console.error('Ошибка при создании отчета:', error);
            toast({
                title: "Ошибка",
                description: "Не удалось создать отчет.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    return (
        <Box>
            <NavbarHeader />
            <Box p={5}>
                <Heading mb={6}>Создать Отчет</Heading>
                <form onSubmit={handleSubmit}>
                    <FormControl id="type" mb={4} isRequired>
                        <FormLabel>Тип отчета</FormLabel>
                        <Select
                            name="type"
                            placeholder="Выберите тип отчета"
                            value={formData.type}
                            onChange={handleChange}
                        >
                            {types.map(type => (
                                <option key={type} value={type}>
                                    {type.replace('_', ' ').charAt(0).toUpperCase() + type.replace('_', ' ').slice(1)}
                                </option>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl id="equipment_id" mb={4} isRequired>
                        <FormLabel>Оборудование</FormLabel>
                        <Select
                            name="equipment_id"
                            placeholder="Выберите оборудование"
                            value={formData.equipment_id}
                            onChange={handleChange}
                        >
                            {equipment.map(eq => (
                                <option key={eq.id} value={eq.id}>
                                    {eq.name} ({eq.model})
                                </option>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl id="description" mb={4} isRequired>
                        <FormLabel>Описание</FormLabel>
                        <Textarea
                            name="description"
                            placeholder="Введите описание отчета"
                            value={formData.description}
                            onChange={handleChange}
                        />
                    </FormControl>

                    <FormControl id="date" mb={4} isRequired>
                        <FormLabel>Дата</FormLabel>
                        <Input
                            type="date"
                            name="date"
                            value={formData.date}
                            onChange={handleChange}
                        />
                    </FormControl>

                    <Button colorScheme="teal" type="submit">
                        Создать
                    </Button>
                </form>
                <Button colorScheme="gray" mt={4} onClick={() => navigate("/dashboard/reports")}>
                    Отмена
                </Button>
            </Box>
            <Footer />
        </Box>
    );
}

export default CreateReport;
