import React from 'react';
import './styles/Main/CatalogPage.css';
import NavbarHeader from "../components/Main/NavbarHeader";
import Footer from "../components/Footer";
import Catalog from "../components/Main/Catalog";

function CatalogPage() {
    return (
        <div>
            <NavbarHeader/>
            <div className="CatalogPage-container">
                <div className="CatalogPage-content">
                    <Catalog/>
                </div>
                <Footer/>
            </div>
        </div>
    );
}

export default CatalogPage;