import React from "react";
import { Link } from "react-router-dom";
import './ErrorPage.css';

const ForbiddenPage = () => {
    return (
        <div className="error-container">
            <div className="error-content">
                <h1 className="error-heading">403 - Доступ запрещен</h1>
                <p className="error-text">У вас нет прав для просмотра этой страницы.</p>
                <Link to="/main" className="error-button">
                    Вернуться на главную
                </Link>

            </div>
            <div className="error-footer">
                <p className="error-footer-text">Узнать информацию об обновлениях и датах проведения тех-работ: <br/>
                    <a href="https://swop.su/">SWOP.SU</a></p>
                <p className="error-footer-text">©<a href="https://cathev.ru/">Cathev</a>. Все права защищены.</p>
            </div>
        </div>
    );
};

export default ForbiddenPage;
