import React from "react";
import '../pages/styles/Footer.css';
import CatheV from "../pages/styles/images/cathev.png";
// import BotQR from "../pages/styles/images/bot.png";
import {Link} from "react-router-dom";

export default function Footer() {
    return (
        <footer className="App-footer">
            <div className="Footer-body">
                <img src={CatheV} alt="Cathev logo" className="CatheV"/>
                {/*<img src={BotQR} alt="QR tg bot" className="QRBOT"/>*/}
                {/*<img src={BotQR} alt="QR tg bot" className="QRBOT" onClick={(e) => {*/}
                {/*    e.stopPropagation();*/}
                {/*    const qrElement = document.querySelector('.QRBOT');*/}
                {/*    qrElement.classList.toggle('fullscreen');*/}
                {/*    qrElement.classList.toggle('active');*/}
                {/*    document.querySelector('.overlay').classList.toggle('active');*/}
                {/*}}/>*/}
                <div className="Footer-links">
                    <div className="Footer-links-left">
                        <span className="Copyright-text">©️ 2024 Cathev</span>
                        <Link to="/about" className="About-project">Информация о проекте</Link>
                        <a href="https://kvcg.ru/" target="_blank" rel="noopener noreferrer" className="Site-KKRIT">Сайт предприятия</a>
                    </div>
                    <div className="Footer-links-right">
                        <a href="https://swop.ru/" target="_blank" rel="noopener noreferrer" className="TGBOT">Официальное приложение<br></br>(в разработке)</a>
                        <a href="https://t.me/gennadiius" target="_blank" rel="noopener noreferrer" className="Support">Тех
                            поддержка <br></br>(Администрация в Телеграме)</a>
                    </div>
                </div>
            </div>
        </footer>
    );
}
