import React, { useEffect, useState } from 'react';
import { Box, Heading, Spinner, useToast } from '@chakra-ui/react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from 'axios';

const FinancialMetrics = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();

    const fetchFinancialMetrics = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get('/api/admin/financial-metrics', { withCredentials: true });
            const { repairExpenses, lossExpenses, maintenanceExpenses } = response.data.data;

            const formattedData = repairExpenses.map((item, index) => ({
                month: `Месяц ${item.month}`,
                Ремонт: parseFloat(item.total),
                Утрата: parseFloat(lossExpenses[index]?.total || 0),
                Плановое_обслуживание: parseFloat(maintenanceExpenses[index]?.total || 0),
            }));

            setData(formattedData);
        } catch (error) {
            console.error('Ошибка при получении финансовых метрик:', error);
            toast({
                title: "Ошибка",
                description: "Не удалось загрузить финансовые метрики.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchFinancialMetrics();
    }, []);

    if (isLoading) {
        return <Spinner size="xl" />;
    }

    return (
        <Box>
            <Heading mb={4}>Финансовые Метрики</Heading>
            <ResponsiveContainer width="100%" height={400}>
                <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="Ремонт" stroke="#8884d8" />
                    <Line type="monotone" dataKey="Утрата" stroke="#82ca9d" />
                    <Line type="monotone" dataKey="Плановое_обслуживание" stroke="#ffc658" />
                </LineChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default FinancialMetrics;
