import React, { useState } from 'react';
import {
    Box,
    Flex,
    Text,
    Button,
    FormControl,
    FormLabel,
    Input,
    VStack,
    Image,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useZxing } from 'react-zxing';
import NavbarHeader from '../components/Main/NavbarHeader';
import Footer from '../components/Footer';
import api from '../utils/api';
import './styles/EquipmentSendPage.css';

const EquipmentSendPage = () => {
    const [mode, setMode] = useState('scan');
    const [equipmentId, setEquipmentId] = useState('');
    const [sentTo, setSentTo] = useState('');
    const [sentDate, setSentDate] = useState(new Date().toISOString().split('T')[0]);
    const [photos, setPhotos] = useState([]);
    const [addedItems, setAddedItems] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentComment, setCurrentComment] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const toast = useToast();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!equipmentId || !sentTo || !photos.length) {
            toast({
                title: 'Ошибка.',
                description: 'Необходимо заполнить все поля и прикрепить фотографии.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        try {
            const formData = new FormData();
            formData.append('equipment_id', equipmentId);
            formData.append('sent_date', sentDate);
            formData.append('sent_to', sentTo);
            photos.forEach((photo) => {
                formData.append('photos', photo);
            });

            const response = await api.post('/api/inventory/send', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.success) {
                setAddedItems([...addedItems, response.data.history]);
                toast({
                    title: 'Успех.',
                    description: response.data.message,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                setEquipmentId('');
                setSentTo('');
                setSentDate(new Date().toISOString().split('T')[0]);
                setPhotos([]);
            } else {
                toast({
                    title: 'Ошибка.',
                    description: response.data.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error('Ошибка при отправке оборудования:', error);
            toast({
                title: 'Ошибка.',
                description: 'Не удалось отправить оборудование.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleScan = (result) => {
        if (result) {
            setEquipmentId(result);
            setMode('scan');
        }
    };

    const handleError = (error) => {
        console.error(error);
        toast({
            title: 'Ошибка при сканировании QR кода.',
            description: 'Попробуйте снова.',
            status: 'error',
            duration: 3000,
            isClosable: true,
        });
    };

    const {ref} = useZxing({
        onResult: (result, error) => {
            if (result) {
                handleScan(result.getText());
            }
            if (error) {
                handleError(error);
            }
        },
        constraints: {
            video: {
                facingMode: 'environment',
            },
        },
    });

    const openCommentModal = (item) => {
        setSelectedItem(item);
        setCurrentComment(item.comments || '');
        setIsModalOpen(true);
    };

    const closeCommentModal = () => {
        setIsModalOpen(false);
        setSelectedItem(null);
        setCurrentComment('');
    };

    const saveComment = async () => {
        if (!selectedItem) return;

        try {
            await api.put(`/api/inventory/send/comment/${selectedItem.id}`, {comment: currentComment});
            setAddedItems(prevItems => prevItems.map(item =>
                item.id === selectedItem.id ? {...item, comments: currentComment} : item
            ));
            toast({
                title: 'Комментарий сохранён.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            closeCommentModal();
        } catch (error) {
            console.error('Ошибка при сохранении комментария:', error);
            toast({
                title: 'Ошибка при сохранении комментария.',
                description: error.response?.data?.message || 'Произошла ошибка.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleFileChange = (e) => {
        setPhotos(Array.from(e.target.files));
    };

    const openManualInputModal = () => {
        setIsModalOpen(true);
    };

    const handleManualInputSubmit = () => {
        if (!selectedItem || !sentTo) {
            toast({
                title: 'Ошибка.',
                description: 'Необходимо заполнить все поля.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        handleSubmit();
    };

    return (
        <Box>
            <NavbarHeader/>
            <Flex direction="column" align="center" p="6">
                <Text className="send-header" mb="4">Отправка Оборудования</Text>
                <Box className="send-form">
                    <form onSubmit={handleSubmit}>
                        <FormControl mb="4">
                            <FormLabel>Режим</FormLabel>
                            <Box className="button-group">
                                <Button onClick={openManualInputModal} mr="2">
                                    Ручной ввод
                                </Button>
                                <Button onClick={() => setMode('scan')}>
                                    Сканирование QR кода
                                </Button>
                            </Box>
                        </FormControl>

                        {mode === 'scan' && (
                            <Box mb="4">
                                <div ref={ref} className="scanner-container"/>
                            </Box>
                        )}

                        {mode === 'scan' && (
                            <>
                                <FormControl mb="4">
                                    <FormLabel>ID Оборудования</FormLabel>
                                    <Input
                                        type="text"
                                        value={equipmentId}
                                        isReadOnly
                                        placeholder="ID будет получен после сканирования"
                                    />
                                </FormControl>
                            </>
                        )}

                        {mode === 'scan' && (
                            <>
                                <FormControl mb="4">
                                    <FormLabel>Куда отправлено</FormLabel>
                                    <Input
                                        type="text"
                                        value={sentTo}
                                        onChange={(e) => setSentTo(e.target.value)}
                                        placeholder="Введите объект назначения"
                                    />
                                </FormControl>

                                <FormControl mb="4">
                                    <FormLabel>Дата отправки</FormLabel>
                                    <Input
                                        type="date"
                                        value={sentDate}
                                        onChange={(e) => setSentDate(e.target.value)}
                                    />
                                </FormControl>

                                <FormControl mb="4">
                                    <FormLabel>Фотографии</FormLabel>
                                    <Input
                                        type="file"
                                        multiple
                                        accept="image/*"
                                        onChange={handleFileChange}
                                    />
                                </FormControl>

                                <Button type="submit" colorScheme="blue" width="full">
                                    Отправить оборудование
                                </Button>
                            </>
                        )}
                    </form>
                </Box>

                <Box w="100%" maxW="600px" mt="8">
                    <Text fontSize="xl" mb="4">Отправленное Оборудование</Text>
                    {addedItems.length > 0 ? (
                        addedItems.map(item => (
                            <Box key={item.id} p="4" borderWidth="1px" borderRadius="md" mb="4">
                                <Text><strong>ID:</strong> {item.equipment_id}</Text>
                                <Text><strong>Куда отправлено:</strong> {item.details.split(' ')[2]}</Text>
                                <Text><strong>Дата:</strong> {item.date}</Text>
                                {item.photos && (
                                    <Box mt="2" className="image-gallery">
                                        {item.photos.split(',').map((photo, index) => (
                                            <Image key={index} src={photo} alt={`Фото ${index + 1}`}/>
                                        ))}
                                    </Box>
                                )}
                                {item.comments ? (
                                    <Text mt="2"><strong>Комментарий:</strong> {item.comments}</Text>
                                ) : (
                                    <Button mt="2" size="sm" onClick={() => openCommentModal(item)}>
                                        Добавить комментарий
                                    </Button>
                                )}
                            </Box>
                        ))
                    ) : (
                        <Text>Нет отправленного оборудования.</Text>
                    )}
                </Box>
            </Flex>
            <Footer/>

            {/* Модальное окно для добавления комментария */}
            <Modal isOpen={isModalOpen} onClose={closeCommentModal}>
                <ModalOverlay/>
                <ModalContent className="comment-modal">
                    <ModalHeader>Добавить Комментарий</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        {mode === 'manual' && (
                            <FormControl id="manual-input">
                                <FormLabel>ID Оборудования</FormLabel>
                                <Input
                                    type="text"
                                    value={equipmentId}
                                    onChange={(e) => setEquipmentId(e.target.value)}
                                    placeholder="Введите ID оборудования"
                                    isReadOnly={mode === 'manual'}
                                />
                                <FormLabel mt="4">Куда отправлено</FormLabel>
                                <Input
                                    type="text"
                                    value={sentTo}
                                    onChange={(e) => setSentTo(e.target.value)}
                                    placeholder="Введите объект назначения"
                                />
                                <FormLabel mt="4">Дата отправки</FormLabel>
                                <Input
                                    type="date"
                                    value={sentDate}
                                    onChange={(e) => setSentDate(e.target.value)}
                                />
                                <FormLabel mt="4">Фотографии</FormLabel>
                                <Input
                                    type="file"
                                    multiple
                                    accept="image/*"
                                    onChange={handleFileChange}
                                />
                            </FormControl>
                        )}
                        {mode === 'scan' && (
                            <FormControl id="comment">
                                <FormLabel>Комментарий</FormLabel>
                                <Input
                                    type="text"
                                    value={currentComment}
                                    onChange={(e) => setCurrentComment(e.target.value)}
                                    placeholder="Введите комментарий"
                                />
                            </FormControl>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        {mode === 'manual' ? (
                            <>
                                <Button colorScheme="blue" mr={3} onClick={handleManualInputSubmit}>
                                    Сохранить
                                </Button>
                                <Button variant="ghost" onClick={closeCommentModal}>Отмена</Button>
                            </>
                        ) : (
                            <>
                                <Button colorScheme="blue" mr={3} onClick={saveComment}>
                                    Сохранить
                                </Button>
                                <Button variant="ghost" onClick={closeCommentModal}>Отмена</Button>
                            </>
                        )}
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
}

    export default EquipmentSendPage;
