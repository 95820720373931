import React from 'react';
import { Box, Table, Tbody, Tr, Td } from '@chakra-ui/react';
import './EquipmentDetailsTable.css';

const EquipmentDetailsTable = ({ equipment }) => {
    return (
        <Box className="equipment-details-table">
            <Table variant="simple">
                <Tbody>
                    <Tr>
                        <Td>Тип оборудования</Td>
                        <Td>{equipment.type || 'Нет данных'}</Td>
                    </Tr>
                    <Tr>
                        <Td>Бренд</Td>
                        <Td>{equipment.brand_name || 'Нет данных'}</Td>
                    </Tr>
                    <Tr>
                        <Td>Комплектация</Td>
                        <Td>{equipment.configuration || 'Нет данных'}</Td>
                    </Tr>
                    <Tr>
                        <Td>Описание</Td>
                        <Td>{equipment.description || 'Нет данных'}</Td>
                    </Tr>
                    <Tr>
                        <Td>Примечания</Td>
                        <Td>{equipment.notes || 'Нет данных'}</Td>
                    </Tr>
                    <Tr>
                        <Td>Документ покупки</Td>
                        <Td>
                            {equipment.purchase_document_url ? (
                                <a
                                    href={equipment.purchase_document_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="purchase-document-link"
                                >
                                    Ссылка
                                </a>
                            ) : (
                                'Нет'
                            )}
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>Гарантийный талон</Td>
                        <Td>
                            {equipment.warranty_card_url ? (
                                <a
                                    href={equipment.warranty_card_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="warranty-card-link"
                                >
                                    Ссылка
                                </a>
                            ) : (
                                'Нет'
                            )}
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>Дата покупки</Td>
                        <Td>{equipment.purchase_date ? new Date(equipment.purchase_date).toLocaleDateString() : 'Нет данных'}</Td>
                    </Tr>
                    <Tr>
                        <Td>Дата производства</Td>
                        <Td>{equipment.production_date ? new Date(equipment.production_date).toLocaleDateString() : 'Нет данных'}</Td>
                    </Tr>
                    <Tr>
                        <Td>Состояние</Td>
                        <Td>{equipment.condition || 'Нет данных'}</Td>
                    </Tr>
                    <Tr>
                        <Td>Местоположение</Td>
                        <Td>{equipment.location || 'Нет данных'}</Td>
                    </Tr>
                    <Tr>
                        <Td>Дата последнего ремонта</Td>
                        <Td>{equipment.last_repair_date ? new Date(equipment.last_repair_date).toLocaleDateString() : 'Нет данных'}</Td>
                    </Tr>
                    <Tr>
                        <Td>Работы по ремонту</Td>
                        <Td>{equipment.repair_works || 'Нет данных'}</Td>
                    </Tr>
                    <Tr>
                        <Td>Гарантия</Td>
                        <Td>{equipment.warranty ? 'Да' : 'Нет'}</Td>
                    </Tr>
                    <Tr>
                        <Td>Цена</Td>
                        <Td>{equipment.price ? `${equipment.price} ₽` : 'Нет данных'}</Td>
                    </Tr>
                    <Tr>
                        <Td>Операционные расходы</Td>
                        <Td>{equipment.operational_expenses ? `${equipment.operational_expenses} ₽` : 'Нет данных'}</Td>
                    </Tr>
                    <Tr>
                        <Td>Категория</Td>
                        <Td>{equipment.category_name || 'Нет данных'}</Td>
                    </Tr>
                    <Tr>
                        <Td>Дата создания</Td>
                        <Td>{equipment.created_at ? new Date(equipment.created_at).toLocaleString() : 'Нет данных'}</Td>
                    </Tr>
                    <Tr>
                        <Td>Дата обновления</Td>
                        <Td>{equipment.updated_at ? new Date(equipment.updated_at).toLocaleString() : 'Нет данных'}</Td>
                    </Tr>
                </Tbody>
            </Table>
        </Box>
    );
};

export default EquipmentDetailsTable;
