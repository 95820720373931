import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageTitle = () => {
    const location = useLocation();

    useEffect(() => {
        const titles = {
            '/': 'Вход в систему | SWOP',
            '/main': 'Главная | SWOP',
            '/register': 'Регистрация | SWOP',
            '/catalog': 'Каталог | SWOP',
            '/settings': 'Настройки | SWOP',
            '/settings/general': 'Общие настройки | SWOP',
            '/settings/profile': 'Профиль пользователя | SWOP',


            '/400': '400 - Неверный запрос | SWOP',
            '/401': '401 - Неавторизован | SWOP',
            '/403': '403 - Доступ запрещен | SWOP',
            '/404': '404 - Страница не найдена | SWOP',
            '/410': '410 - Удалено | SWOP',
            '/500': '500 - Серверная ошибка | SWOP',
            '/502': '502 - Плохой шлюз | SWOP',
            '/503': '503 - Сервер не доступен | SWOP',
            '/504': '504 - Тайм-аут | SWOP',

        };

        if (location.pathname.startsWith('/group/')) {
            document.title = 'Группа | SWOP';
        } else {
            document.title = titles[location.pathname] || 'SWOP';
        }
    }, [location]);

    return null;
};

export default PageTitle;
