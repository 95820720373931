import React, { useEffect, useState, useContext, useRef } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Text,
    useDisclosure,
    useMediaQuery
} from '@chakra-ui/react';
import { AccountContext } from '../../contexts/AccountContext';
import '../../pages/styles/common/AlphaVersionModal.css';

const AlphaVersionModal = () => {
    const { user } = useContext(AccountContext);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isMobile] = useMediaQuery('(max-width: 768px)');
    const [hasShown, setHasShown] = useState(false);
    const prevLoggedInRef = useRef(false);

    useEffect(() => {
        const prevLoggedIn = prevLoggedInRef.current;

        if (user.loggedIn && !prevLoggedIn) {
            setHasShown(false);
        }

        prevLoggedInRef.current = user.loggedIn;
    }, [user.loggedIn]);

    useEffect(() => {
        if (user.loggedIn && isMobile && !hasShown) {
            onOpen();
            setHasShown(true);
        }
    }, [user.loggedIn, isMobile, hasShown, onOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
            <ModalOverlay />
            <ModalContent className="AlphaVersionModal-content">
                <ModalHeader>Ранняя Альфа Версия</ModalHeader>
                <ModalBody>
                    <Text>
                        Вы используете раннюю альфа-версию приложения. Возможны проблемы с графическим отображением и другими функциями.
                        <br />Благодарим за понимание!
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="teal" onClick={onClose} width={{ base: '100%', md: 'auto' }}>
                        Понятно
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default AlphaVersionModal;