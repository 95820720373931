import React, { useState, useEffect } from 'react';
import {
    Box,
    VStack,
    FormControl,
    FormLabel,
    Input,
    Button,
    useToast,
    FormErrorMessage,
    Switch,
    Heading,
    useColorMode
} from '@chakra-ui/react';
import api from '../../../utils/api';

const UserSettings = () => {
    const toast = useToast();
    const { colorMode, toggleColorMode } = useColorMode();
    const [notifications, setNotifications] = useState({
        email: true,
        // sms: false,
        push: true
    });
    const [errors, setErrors] = useState({});
    const [theme, setTheme] = useState(colorMode);
    const [userData, setUserData] = useState({
        username: '',
        firstName: '',
        lastName: ''
    });

    useEffect(() => {
        const fetchUserSettings = async () => {
            try {
                const response = await api.get('/api/settings');

                setNotifications({
                    email: response.data.notifications_email,
                    sms: response.data.notifications_sms,
                    push: response.data.notifications_push
                });

                if (response.data.theme === 'dark' && colorMode !== 'dark') {
                    toggleColorMode();
                    setTheme('dark');
                } else if (response.data.theme === 'light' && colorMode !== 'light') {
                    toggleColorMode();
                    setTheme('light');
                }

            } catch (error) {
                console.error('Ошибка при загрузке пользовательских настроек:', error);
                toast({
                    title: "Ошибка",
                    description: "Не удалось загрузить пользовательские настройки.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        };

        fetchUserSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (name in notifications) {
            setNotifications(prev => ({
                ...prev,
                [name]: type === 'checkbox' ? checked : value
            }));
        } else {
            setUserData(prev => ({
                ...prev,
                [name]: value
            }));
        }

        setErrors(prev => ({
            ...prev,
            [name]: ''
        }));
    };

    const validate = () => {
        const newErrors = {};

        if (!userData.username.trim()) {
            newErrors.username = 'Имя пользователя обязательно.';
        }
        if (!userData.firstName.trim()) {
            newErrors.firstName = 'Имя обязательно.';
        }
        if (!userData.lastName.trim()) {
            newErrors.lastName = 'Фамилия обязательна.';
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const saveUserSettings = async () => {
        if (!validate()) {
            toast({
                title: "Ошибка",
                description: "Пожалуйста, исправьте ошибки в форме.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        try {
            const response = await api.get('/api/settings');

            const currentSettings = response.data;

            await api.put('/api/settings', {
                theme: theme,
                notifications_email: notifications.email,
                notifications_sms: notifications.sms,
                notifications_push: notifications.push,
                autoSaveTime: currentSettings.auto_save_time,
                autoLogout: currentSettings.auto_logout,
                saveLastWindow: currentSettings.save_last_window,
                inactivityTimeout: currentSettings.inactivity_timeout
            });

            toast({
                title: "Настройки сохранены",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Ошибка при сохранении пользовательских настроек:', error);
            toast({
                title: "Ошибка",
                description: "Не удалось сохранить пользовательские настройки.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleUpdateProfile = async () => {
        try {
            const response = await api.put('/api/user/update', userData);
            toast({
                title: "Успешно",
                description: "Профиль обновлен",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Ошибка",
                description: "Не удалось обновить профиль",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    return (
        <Box className="user-settings-container" p={5}>
            <Heading as="h2" size="lg" mb={6}>Настройки пользователя</Heading>
            <VStack spacing={4} align="stretch">
                <FormControl isInvalid={errors.username}>
                    <FormLabel htmlFor="username">Имя пользователя</FormLabel>
                    <Input
                        id="username"
                        name="username"
                        value={userData.username}
                        onChange={handleInputChange}
                    />
                    {errors.username && <FormErrorMessage>{errors.username}</FormErrorMessage>}
                </FormControl>

                <FormControl isInvalid={errors.firstName}>
                    <FormLabel htmlFor="firstName">Имя</FormLabel>
                    <Input
                        id="firstName"
                        name="firstName"
                        value={userData.firstName}
                        onChange={handleInputChange}
                    />
                    {errors.firstName && <FormErrorMessage>{errors.firstName}</FormErrorMessage>}
                </FormControl>

                <FormControl isInvalid={errors.lastName}>
                    <FormLabel htmlFor="lastName">Фамилия</FormLabel>
                    <Input
                        id="lastName"
                        name="lastName"
                        value={userData.lastName}
                        onChange={handleInputChange}
                    />
                    {errors.lastName && <FormErrorMessage>{errors.lastName}</FormErrorMessage>}
                </FormControl>

                {/* Дополнительные настройки */}
                <FormControl display="flex" alignItems="center">
                    <FormLabel htmlFor="theme" mb="0">
                        Тема
                    </FormLabel>
                    <Switch
                        id="theme"
                        isChecked={theme === 'dark'}
                        onChange={() => {
                            toggleColorMode();
                            setTheme(prev => (prev === 'light' ? 'dark' : 'light'));
                        }}
                    />
                </FormControl>

                <FormControl display="flex" alignItems="center">
                    <FormLabel htmlFor="email" mb="0">
                        Уведомления по Email
                    </FormLabel>
                    <Switch
                        id="email"
                        name="email"
                        isChecked={notifications.email}
                        onChange={handleInputChange}
                    />
                </FormControl>

                {/* Если требуется SMS уведомления */}
                {/*
                <FormControl display="flex" alignItems="center">
                    <FormLabel htmlFor="sms" mb="0">
                        Уведомления по SMS
                    </FormLabel>
                    <Switch
                        id="sms"
                        name="sms"
                        isChecked={notifications.sms}
                        onChange={handleInputChange}
                    />
                </FormControl>
                */}

                <FormControl display="flex" alignItems="center">
                    <FormLabel htmlFor="push" mb="0">
                        Уведомления по Push
                    </FormLabel>
                    <Switch
                        id="push"
                        name="push"
                        isChecked={notifications.push}
                        onChange={handleInputChange}
                    />
                </FormControl>

                <Button colorScheme="blue" onClick={saveUserSettings}>
                    Сохранить настройки
                </Button>

                <Button colorScheme="teal" onClick={handleUpdateProfile}>
                    Обновить профиль
                </Button>
            </VStack>
        </Box>
    );
};

export default UserSettings;
