import React, { useEffect, useState } from 'react';
import {
    Box,
    Heading,
    Text,
    Spinner,
    Button,
    VStack,
    HStack, useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import NavbarHeader from './Main/NavbarHeader';
import Footer from './Footer';

const ReportDetails = () => {
    const { id } = useParams();
    const [report, setReport] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const navigate = useNavigate();

    const fetchReportDetails = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`/api/dashboard/reports/${id}`, { withCredentials: true });
            setReport(response.data.data.report);
        } catch (error) {
            console.error('Ошибка при получении деталей отчета:', error);
            toast({
                title: "Ошибка",
                description: "Не удалось загрузить детали отчета.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchReportDetails();
    }, [id]);

    if (isLoading) {
        return <Spinner size="xl" />;
    }

    if (!report) {
        return null;
    }

    return (
        <Box>
            <NavbarHeader />
            <Box p={5}>
                <Heading mb={6}>Детали Отчета #{report.id}</Heading>
                <VStack align="start" spacing={4} mb={6}>
                    <HStack>
                        <Text fontWeight="bold">Тип отчета:</Text>
                        <Text>{report.type.replace('_', ' ').charAt(0).toUpperCase() + report.type.replace('_', ' ').slice(1)}</Text>
                    </HStack>
                    <HStack>
                        <Text fontWeight="bold">Оборудование:</Text>
                        <Text>{report.equipment_name}</Text>
                    </HStack>
                    <HStack>
                        <Text fontWeight="bold">Описание:</Text>
                        <Text>{report.description}</Text>
                    </HStack>
                    <HStack>
                        <Text fontWeight="bold">Дата:</Text>
                        <Text>{new Date(report.date).toLocaleString()}</Text>
                    </HStack>
                </VStack>
                <Button colorScheme="teal" onClick={() => navigate("/dashboard/reports")}>
                    Вернуться к отчетам
                </Button>
            </Box>
            <Footer />
        </Box>
    );
}

export default ReportDetails;
