import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Box, Flex, Spinner, Text, Button } from '@chakra-ui/react';
import NavbarHeader from '../components/Main/NavbarHeader';
import Footer from '../components/Footer';
import api from '../utils/api';
import EquipmentList from '../components/Main/EquipmentList';
import LoadingSpinner from "../components/common/LoadingSpinner";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const SearchPage = () => {
    const query = useQuery();
    const searchTerm = query.get('q') || '';
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSearchResults = async () => {
            try {
                const response = await api.get(`/api/search/global?q=${encodeURIComponent(searchTerm)}`);
                if (response.data.success) {
                    // Если ваши результаты также объединены, убедитесь, что вы их обрабатываете корректно
                    setSearchResults(response.data.results);
                } else {
                    setError('Ошибка при выполнении поиска.');
                }
            } catch (err) {
                console.error(err);
                setError('Произошла ошибка при выполнении поиска.');
            } finally {
                setLoading(false);
            }
        };

        if (searchTerm.trim() !== '') {
            fetchSearchResults();
        } else {
            setSearchResults([]);
            setLoading(false);
        }
    }, [searchTerm]);

    if (loading) {
        return (
            <Flex justify="center" align="center" height="100vh">
                <Spinner size="xl" />
            </Flex>
        );
    }

    if (error) {
        return (
            <Flex justify="center" align="center" height="100vh">
                <Text fontSize="xl" color="red.500">
                    {error}
                </Text>
            </Flex>
        );
    }

    return (
        <div>
            <NavbarHeader />
            <Box p="6" maxW="1200px" mx="auto">
                <Text fontSize="2xl" mb="4">Результаты поиска для: "{searchTerm}"</Text>
                {loading && <LoadingSpinner />}
                {error && <Text color="red.500">{error}</Text>}
                {searchResults.length === 0 ? (
                    <Text>Нет результатов для данного запроса.</Text>
                ) : (
                    <EquipmentList equipment={searchResults} />
                )}
                <Flex mt="6" justify="center">
                    <Link to={`/search?q=${encodeURIComponent(searchTerm)}`}>
                        <Button colorScheme="teal">Просмотреть все результаты</Button>
                    </Link>
                </Flex>
            </Box>
            <Footer />
        </div>
    );
}

export default SearchPage;