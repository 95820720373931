import React, { useState, useEffect } from 'react';
import {
    Box,
    VStack,
    FormControl,
    FormLabel,
    Select,
    Button,
    Heading,
    useToast,
    FormErrorMessage,
    Switch
} from '@chakra-ui/react';
import api from '../../../utils/api';

const SystemSettings = () => {
    const toast = useToast();
    const [settings, setSettings] = useState({
        autoSaveTime: '5',
        saveLastWindow: false,
        inactivityTimeout: 'never',
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await api.get('/api/settings');
                setSettings({
                    autoSaveTime: response.data.auto_save_time.toString(),
                    saveLastWindow: response.data.save_last_window,
                    inactivityTimeout: response.data.auto_logout ? response.data.inactivity_timeout.toString() : 'never'
                });
                console.log('Полученные настройки:', response.data);
            } catch (error) {
                console.error('Ошибка при загрузке настроек:', error);
                toast({
                    title: "Ошибка",
                    description: "Не удалось загрузить настройки.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        };

        fetchSettings();
    }, [toast]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setSettings(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));

        setErrors(prev => ({
            ...prev,
            [name]: ''
        }));
    };

    const validate = () => {
        const newErrors = {};

        const autoSaveTime = parseInt(settings.autoSaveTime);
        if (isNaN(autoSaveTime) || autoSaveTime < 1 || autoSaveTime > 60) {
            newErrors.autoSaveTime = 'Значение должно быть от 1 до 60 минут.';
        }

        if (settings.inactivityTimeout !== 'never') {
            const inactivity = parseInt(settings.inactivityTimeout);
            if (isNaN(inactivity) || inactivity < 1 || inactivity > 120) {
                newErrors.inactivityTimeout = 'Значение должно быть от 1 до 120 минут или "never".';
            }
        }

        setErrors(newErrors);

        return true;
    };

    const saveSystemSettings = async () => {
        if (!validate()) {
            toast({
                title: "Ошибка",
                description: "Пожалуйста, исправьте ошибки в форме.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        try {
            const payload = {
                auto_save_time: parseInt(settings.autoSaveTime),
                save_last_window: settings.saveLastWindow,
                auto_logout: settings.inactivityTimeout !== 'never',
                inactivity_timeout: settings.inactivityTimeout !== 'never' ? parseInt(settings.inactivityTimeout) : null
            };

            await api.put('/api/settings', payload);

            toast({
                title: "Успешно",
                description: "Настройки сохранены",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Ошибка при сохранении настроек:', error);
            toast({
                title: "Ошибка",
                description: "Не удалось сохранить настройки.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    return (
        <Box p={5}>
            <Heading as="h2" size="lg" mb={6}>Системные настройки</Heading>
            <VStack spacing={4} align="stretch">
                {/* Настройка времени авто-сохранения */}
                <FormControl isInvalid={errors.autoSaveTime}>
                    <FormLabel htmlFor="autoSaveTime">Авто-сохранение</FormLabel>
                    <Select
                        id="autoSaveTime"
                        name="autoSaveTime"
                        value={settings.autoSaveTime}
                        onChange={handleInputChange}
                    >
                        <option value="1">1 минута</option>
                        <option value="5">5 минут</option>
                        <option value="10">10 минут</option>
                        <option value="15">15 минут</option>
                        <option value="20">20 минут</option>
                        <option value="30">30 минут</option>
                        <option value="60">1 час</option>
                    </Select>
                    {errors.autoSaveTime && <FormErrorMessage>{errors.autoSaveTime}</FormErrorMessage>}
                </FormControl>

                {/* Настройка сохранения последнего окна */}
                <FormControl display="flex" alignItems="center">
                    <FormLabel htmlFor="saveLastWindow" mb="0">
                        Сохранение и открытие последнего открытого окна
                    </FormLabel>
                    <Switch
                        id="saveLastWindow"
                        name="saveLastWindow"
                        isChecked={settings.saveLastWindow}
                        onChange={handleInputChange}
                    />
                </FormControl>

                {/* Настройка авто-выхода по бездействию */}
                <FormControl isInvalid={errors.inactivityTimeout}>
                    <FormLabel htmlFor="inactivityTimeout">Авто-выход по бездействию</FormLabel>
                    <Select
                        id="inactivityTimeout"
                        name="inactivityTimeout"
                        value={settings.inactivityTimeout}
                        onChange={handleInputChange}
                    >
                        <option value="never">Никогда</option>
                        <option value="1">1 минута</option>
                        <option value="5">5 минут</option>
                        <option value="10">10 минут</option>
                        <option value="15">15 минут</option>
                        <option value="20">20 минут</option>
                        <option value="30">30 минут</option>
                        <option value="60">1 час</option>
                        <option value="120">2 часа</option>
                    </Select>
                    {errors.inactivityTimeout && <FormErrorMessage>{errors.inactivityTimeout}</FormErrorMessage>}
                </FormControl>

                <Button colorScheme="blue" onClick={saveSystemSettings}>
                    Сохранить настройки
                </Button>
            </VStack>
        </Box>
    );
};

export default SystemSettings;
