import React, { useEffect, useState } from 'react';
import { Box, Heading, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Spinner, useToast } from '@chakra-ui/react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import axios from 'axios';

const DashboardHome = () => {
    const [summary, setSummary] = useState({
        totalUsers: 0,
        usersByRole: [],
        totalLogs: 0,
        financialMetrics: [],
        expenseCategories: [],
    });
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();

    const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042'];

    const fetchDashboardData = async () => {
        setIsLoading(true);
        try {
            const [dashboardRes, financialRes, expenseRes] = await Promise.all([
                axios.get('/api/dashboard/dashboard', { withCredentials: true }),
                axios.get('/api/dashboard/financial-metrics', { withCredentials: true }),
                axios.get('/api/dashboard/expense-categories', { withCredentials: true }),
            ]);

            setSummary({
                totalUsers: parseInt(dashboardRes.data.data.totalUsers, 10),
                usersByRole: dashboardRes.data.data.usersByRole,
                totalLogs: parseInt(dashboardRes.data.data.totalLogs, 10),
                financialMetrics: financialRes.data.data,
                expenseCategories: expenseRes.data.data.categories,
            });
        } catch (error) {
            console.error('Ошибка при получении данных дашборда:', error);
            toast({
                title: "Ошибка",
                description: "Не удалось загрузить данные дашборда.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchDashboardData();
    }, []);

    if (isLoading) {
        return <Spinner size="xl" />;
    }

    return (
        <Box>

            <Heading mb={6}>Главная Панель Администратора</Heading>
            <Box boxShadow="lg" p={4} borderRadius="md" bg="white">
            <Grid templateColumns="repeat(auto-fit, minmax(200px, 1fr))" gap={6} mb={16}>

                <GridItem>
                    <Stat>
                        <StatLabel>Всего пользователей</StatLabel>
                        <StatNumber>{summary.totalUsers}</StatNumber>
                        <StatHelpText>Активных пользователей</StatHelpText>
                    </Stat>
                </GridItem>
                <GridItem>
                    <Stat>
                        <StatLabel>Всего логов</StatLabel>
                        <StatNumber>{summary.totalLogs}</StatNumber>
                        <StatHelpText>Системных событий</StatHelpText>
                    </Stat>
                </GridItem>
                <GridItem>
                    <Stat>
                        <StatLabel>Расходы на ремонт</StatLabel>
                        <StatNumber>
                            {summary.financialMetrics.repairExpenses
                                ? `${summary.financialMetrics.repairExpenses.total} ₽`
                                : '0 ₽'}
                        </StatNumber>
                        <StatHelpText>За последний месяц</StatHelpText>
                    </Stat>
                </GridItem>
                <GridItem>
                    <Stat>
                        <StatLabel>Расходы на утерю</StatLabel>
                        <StatNumber>
                            {summary.financialMetrics.lossExpenses
                                ? `${summary.financialMetrics.lossExpenses.total} ₽`
                                : '0 ₽'}
                        </StatNumber>
                        <StatHelpText>За последний месяц</StatHelpText>
                    </Stat>
                </GridItem>
                <GridItem>
                    <Stat>
                        <StatLabel>Расходы на обслуживание</StatLabel>
                        <StatNumber>
                            {summary.financialMetrics.maintenanceExpenses
                                ? `${summary.financialMetrics.maintenanceExpenses.total} ₽`
                                : '0 ₽'}
                        </StatNumber>
                        <StatHelpText>За последний месяц</StatHelpText>
                    </Stat>
                </GridItem>

            </Grid>
</Box>


            <Grid templateColumns="repeat(auto-fit, minmax(400px, 1fr))" gap={6} mb={6}>
                <Box boxShadow="lg" p={4} borderRadius="md" bg="white">
                <GridItem>

                    <Heading size="md" mb={4}>Распределение пользователей по ролям</Heading>
                    <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                            <Pie
                                data={summary.usersByRole}
                                dataKey="count"
                                nameKey="role_name"
                                cx="50%"
                                cy="50%"
                                outerRadius={80}
                                fill="#8884d8"
                                label
                            >
                                {summary.usersByRole.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip />
                            <Legend />
                        </PieChart>
                    </ResponsiveContainer>
                </GridItem>
        </Box>

                <Box boxShadow="lg" p={4} borderRadius="md" bg="white">
                <GridItem>

                    <Heading size="md" mb={4}>Финансовые Метрики</Heading>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={summary.financialMetrics}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="Ремонт" stroke="#8884d8" />
                            <Line type="monotone" dataKey="Утрата" stroke="#82ca9d" />
                            <Line type="monotone" dataKey="Плановое_обслуживание" stroke="#ffc658" />
                        </LineChart>
                    </ResponsiveContainer>

                </GridItem>
        </Box>
            </Grid>


            <Box boxShadow="lg" p={4} borderRadius="md" bg="white">
            <Grid templateColumns="repeat(auto-fit, minmax(400px, 1fr))" gap={6} mb={6}>
                <GridItem>

                    <Heading size="md" mb={4}>Распределение расходов по категориям</Heading>
                    <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                            <Pie
                                data={summary.expenseCategories}
                                dataKey="total"
                                nameKey="category"
                                cx="50%"
                                cy="50%"
                                outerRadius={80}
                                fill="#82ca9d"
                                label
                            >
                                {summary.expenseCategories.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip />
                            <Legend />
                        </PieChart>
                    </ResponsiveContainer>

                </GridItem>
            </Grid>
</Box>
        </Box>
    );

}

export default DashboardHome;
