import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Button,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Select,
    useToast,
    Spinner,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Input,
    FormControl,
    FormLabel,
    FormErrorMessage,
    VStack,
    Heading,
    HStack,
    IconButton,
    Text,
} from '@chakra-ui/react';
import axios from 'axios';
import { EditIcon, AddIcon } from '@chakra-ui/icons';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

const UserSchema = Yup.object().shape({
    username: Yup.string().required('Имя пользователя обязательно'),
    email: Yup.string().email('Неверный формат email').required('Email обязателен'),
    password: Yup.string().min(6, 'Пароль должен содержать минимум 6 символов'),
    first_name: Yup.string().required('Имя обязательно'),
    last_name: Yup.string().required('Фамилия обязательна'),
    role: Yup.string().oneOf(['worker', 'manager', 'admin'], 'Неверная роль').required('Роль обязательна'),
});

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedUser, setSelectedUser] = useState(null);
    const [newRole, setNewRole] = useState('');
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: onCreateClose } = useDisclosure();
    const [filters, setFilters] = useState({ role: '', searchTerm: '' });
    const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'ascending' });

    const fetchUsers = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/api/admin/users`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            setUsers(response.data.users);
            setLoading(false);
        } catch (error) {
            console.error("Ошибка при получении списка пользователей:", error);
            toast({
                title: "Ошибка",
                description: "Не удалось загрузить список пользователей.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const handleEditClick = (user) => {
        setSelectedUser(user);
        setNewRole(user.role);
        onOpen();
    };

    const handleUpdateRole = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/api/admin/update-role`,
                { userId: selectedUser.id, newRole },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            toast({
                title: "Успешно",
                description: response.data.message,
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            onClose();
            fetchUsers();
        } catch (error) {
            console.error("Ошибка при обновлении роли пользователя:", error);
            toast({
                title: "Ошибка",
                description: error.response?.data?.message || "Не удалось обновить роль пользователя.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleCreateUser = async (values, actions) => {
        try {
            const token = localStorage.getItem("token");
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/api/admin/create-user`,
                values,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            toast({
                title: "Успешно",
                description: "Пользователь успешно создан",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            actions.resetForm();
            onCreateClose();
            fetchUsers();
        } catch (error) {
            console.error("Ошибка при создании пользователя:", error);
            toast({
                title: "Ошибка",
                description: error.response?.data?.message || "Не удалось создать пользователя.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedUsers = useMemo(() => {
        let sortableUsers = [...users];

        if (sortConfig !== null) {
            sortableUsers.sort((a, b) => {
                let aValue = a[sortConfig.key];
                let bValue = b[sortConfig.key];

                if (sortConfig.key === 'full_name') {
                    aValue = `${a.first_name} ${a.last_name}`;
                    bValue = `${b.first_name} ${b.last_name}`;
                }

                if (aValue < bValue) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }

        return sortableUsers;
    }, [users, sortConfig]);

    const filteredUsers = useMemo(() => {
        return sortedUsers.filter(user => {
            const matchesRole = filters.role ? user.role === filters.role : true;
            const matchesSearch = filters.searchTerm
                ? user.username.toLowerCase().includes(filters.searchTerm.toLowerCase()) ||
                user.email.toLowerCase().includes(filters.searchTerm.toLowerCase()) ||
                `${user.first_name} ${user.last_name}`.toLowerCase().includes(filters.searchTerm.toLowerCase())
                : true;
            return matchesRole && matchesSearch;
        });
    }, [sortedUsers, filters]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Spinner size="xl" />
            </Box>
        );
    }

    return (
        <Box width="100%" overflowX="auto" p={4}>
            <Heading mb={4}>Список Пользователей</Heading>

            {/* Фильтры и кнопка создания пользователя */}
            <HStack mb={4} spacing={4}>
                <FormControl width="200px">
                    <FormLabel>Фильтр по роли</FormLabel>
                    <Select
                        placeholder="Все роли"
                        value={filters.role}
                        onChange={(e) => setFilters({ ...filters, role: e.target.value })}
                    >
                        <option value="worker">Работник</option>
                        <option value="manager">Менеджер</option>
                        <option value="admin">Администратор</option>
                    </Select>
                </FormControl>
                <FormControl width="300px">
                    <FormLabel>Поиск</FormLabel>
                    <Input
                        placeholder="Поиск по имени или email"
                        value={filters.searchTerm}
                        onChange={(e) => setFilters({ ...filters, searchTerm: e.target.value })}
                    />
                </FormControl>
                <Button
                    leftIcon={<AddIcon />}
                    colorScheme="teal"
                    onClick={onCreateOpen}
                >
                    Создать Пользователя
                </Button>
            </HStack>

            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th cursor="pointer" onClick={() => requestSort('id')}>
                            ID {sortConfig.key === 'id' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : null}
                        </Th>
                        <Th cursor="pointer" onClick={() => requestSort('username')}>
                            Имя пользователя {sortConfig.key === 'username' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : null}
                        </Th>
                        <Th cursor="pointer" onClick={() => requestSort('email')}>
                            Электронная почта {sortConfig.key === 'email' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : null}
                        </Th>
                        <Th cursor="pointer" onClick={() => requestSort('role')}>
                            Роль {sortConfig.key === 'role' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : null}
                        </Th>
                        <Th cursor="pointer" onClick={() => requestSort('created_at')}>
                            Дата создания {sortConfig.key === 'created_at' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : null}
                        </Th>
                        <Th>Действия</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {filteredUsers.map(user => (
                        <Tr key={user.id}>
                            <Td>{user.id}</Td>
                            <Td>{user.username}</Td>
                            <Td>{user.email}</Td>
                            <Td textTransform="capitalize">{user.role}</Td>
                            <Td>{new Date(user.created_at).toLocaleDateString()}</Td>
                            <Td>
                                <IconButton
                                    aria-label="Редактировать"
                                    icon={<EditIcon />}
                                    size="sm"
                                    colorScheme="blue"
                                    onClick={() => handleEditClick(user)}
                                />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Редактировать Роль Пользователя</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {selectedUser && (
                            <Box>
                                <Box mb={4}>
                                    <strong>ID:</strong> {selectedUser.id}
                                </Box>
                                <Box mb={4}>
                                    <strong>Имя пользователя:</strong> {selectedUser.username}
                                </Box>
                                <Box mb={4}>
                                    <strong>Электронная почта:</strong> {selectedUser.email}
                                </Box>
                                <FormControl>
                                    <FormLabel>Новая Роль</FormLabel>
                                    <Select
                                        value={newRole}
                                        onChange={(e) => setNewRole(e.target.value)}
                                    >
                                        <option value="worker">Работник</option>
                                        <option value="manager">Менеджер</option>
                                        <option value="admin">Администратор</option>
                                    </Select>
                                </FormControl>
                            </Box>
                        )}
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={onClose}>
                            Отмена
                        </Button>
                        <Button colorScheme="teal" onClick={handleUpdateRole}>
                            Сохранить
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={isCreateOpen} onClose={onCreateClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Создать нового пользователя</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Formik
                            initialValues={{
                                username: "",
                                email: "",
                                password: "",
                                first_name: "",
                                last_name: "",
                                role: "worker"
                            }}
                            validationSchema={UserSchema}
                            onSubmit={handleCreateUser}
                        >
                            {({ errors, touched }) => (
                                <Form>
                                    <VStack spacing={4}>
                                        <FormControl isInvalid={errors.username && touched.username}>
                                            <FormLabel htmlFor="username">Имя пользователя</FormLabel>
                                            <Field
                                                as={Input}
                                                id="username"
                                                name="username"
                                                placeholder="Введите имя пользователя"
                                            />
                                            <FormErrorMessage>{errors.username}</FormErrorMessage>
                                        </FormControl>

                                        <FormControl isInvalid={errors.email && touched.email}>
                                            <FormLabel htmlFor="email">Email</FormLabel>
                                            <Field
                                                as={Input}
                                                id="email"
                                                name="email"
                                                type="email"
                                                placeholder="Введите email"
                                            />
                                            <FormErrorMessage>{errors.email}</FormErrorMessage>
                                        </FormControl>

                                        <FormControl isInvalid={errors.password && touched.password}>
                                            <FormLabel htmlFor="password">Пароль</FormLabel>
                                            <Field
                                                as={Input}
                                                id="password"
                                                name="password"
                                                type="password"
                                                placeholder="Введите пароль"
                                            />
                                            <FormErrorMessage>{errors.password}</FormErrorMessage>
                                        </FormControl>

                                        <FormControl isInvalid={errors.first_name && touched.first_name}>
                                            <FormLabel htmlFor="first_name">Имя</FormLabel>
                                            <Field
                                                as={Input}
                                                id="first_name"
                                                name="first_name"
                                                placeholder="Введите имя"
                                            />
                                            <FormErrorMessage>{errors.first_name}</FormErrorMessage>
                                        </FormControl>

                                        <FormControl isInvalid={errors.last_name && touched.last_name}>
                                            <FormLabel htmlFor="last_name">Фамилия</FormLabel>
                                            <Field
                                                as={Input}
                                                id="last_name"
                                                name="last_name"
                                                placeholder="Введите фамилию"
                                            />
                                            <FormErrorMessage>{errors.last_name}</FormErrorMessage>
                                        </FormControl>

                                        <FormControl isInvalid={errors.role && touched.role}>
                                            <FormLabel htmlFor="role">Роль</FormLabel>
                                            <Field
                                                as={Select}
                                                id="role"
                                                name="role"
                                                placeholder="Выберите роль"
                                            >
                                                <option value="worker">Работник</option>
                                                <option value="manager">Менеджер</option>
                                                <option value="admin">Администратор</option>
                                            </Field>
                                            <FormErrorMessage>{errors.role}</FormErrorMessage>
                                        </FormControl>

                                        <Button type="submit" colorScheme="teal" width="100%">
                                            Создать Пользователя
                                        </Button>
                                    </VStack>
                                </Form>
                            )}
                        </Formik>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={onCreateClose}>
                            Отмена
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default UserList;
