import React, { useEffect, useState } from 'react';
import { Box, Flex, Spinner, Text, VStack, Button, IconButton, useToast } from '@chakra-ui/react';
import NavbarHeader from '../components/Main/NavbarHeader';
import Footer from '../components/Footer';
import NotificationsList from '../components/Notifications/NotificationsList';
import api from '../utils/api';

const NotificationsPage = () => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const toast = useToast();

    useEffect(() => {
        fetchNotifications();
    }, []);

    const fetchNotifications = async () => {
        try {
            setLoading(true);
            const response = await api.get('/api/notifications', { withCredentials: true });
            if (response.data.success) {
                setNotifications(response.data.notifications);
                setError(null);
            } else {
                console.warn('Ошибка от API:', response.data.message);
                setError(response.data.message || 'Не удалось загрузить уведомления.');
            }
        } catch (err) {
            console.error('Ошибка при загрузке уведомлений:', err);
            setError('Произошла ошибка при загрузке уведомлений.');
        } finally {
            setLoading(false);
        }
    };

    const markAsRead = async (id) => {
        try {
            const response = await api.post(`/api/notifications/${id}/read`, {}, { withCredentials: true });
            if (response.data.success) {
                setNotifications((prevNotifications) =>
                    prevNotifications.map((notif) =>
                        notif.id === id ? { ...notif, isRead: true } : notif
                    )
                );
                toast({
                    title: 'Уведомление прочитано.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: 'Ошибка.',
                    description: response.data.message || 'Не удалось отметить уведомление как прочитанное.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (err) {
            console.error('Ошибка при обновлении уведомления:', err);
            toast({
                title: 'Ошибка.',
                description: 'Произошла ошибка при обновлении уведомления.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const deleteNotification = async (id) => {
        try {
            const response = await api.delete(`/api/notifications/${id}`, { withCredentials: true });
            if (response.data.success) {
                setNotifications((prevNotifications) => prevNotifications.filter((notif) => notif.id !== id));
                toast({
                    title: 'Уведомление удалено.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: 'Ошибка.',
                    description: response.data.message || 'Не удалось удалить уведомление.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (err) {
            console.error('Ошибка при удалении уведомления:', err);
            toast({
                title: 'Ошибка.',
                description: 'Произошла ошибка при удалении уведомления.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    return (
        <div>
            <NavbarHeader />
            <Box p={4} maxW="1200px" mx="auto" minH="80vh">
                <Text fontSize="2xl" mb={4} fontWeight="bold">Уведомления</Text>
                {loading ? (
                    <Flex justify="center" align="center" height="60vh">
                        <Spinner size="xl" />
                    </Flex>
                ) : error ? (
                    <Flex justify="center" align="center" height="60vh">
                        <Text color="red.500">{error}</Text>
                    </Flex>
                ) : (
                    <NotificationsList
                        notifications={notifications}
                        onMarkAsRead={markAsRead}
                        onDelete={deleteNotification}
                    />
                )}
            </Box>
            <Footer />
        </div>
    );
};

export default NotificationsPage;
