const Yup = require("yup");

const loginValidationSchema = Yup.object({
  username: Yup.string()
    .required("Введите ваш логин")
    .min(5, "Логин должен быть больше 5 символов")
    .max(28, "Слишком длинный логин!"),
  password: Yup.string()
    .required("Введите пароль")
    .min(8, "Пароль слишком короткий")
    .max(32, "Пароль слишком длинный!"),
});

const baseRegisterValidationSchema = Yup.object({
  username: Yup.string()
      .required("Придумайте логин")
      .min(5, "Логин должен содержать больше 5 символов")
      .max(28, "Слишком длинный логин!"),
  email: Yup.string().email('Неверный формат email')
      .required("Введите ваш email"),
  password: Yup.string()
      .required("Придумайте пароль")
      .min(8, "Пароль слишком короткий")
      .max(32, "Пароль слишком длинный!"),
});

const registerValidationSchema = Yup.object({
  name: Yup.string()
      .required("Введите ваше имя")
      .max(64, "Слишком длинное имя!"),
  surname: Yup.string()
      .required("Введите вашу фамилию")
      .max(64, "Слишком длинная фамилия!"),
  middle_name: Yup.string()
      .max(64, "Слишком длинное отчество!"),
  city: Yup.string()
      .max(64, "Слишком длинное название города!"),
});

module.exports = {loginValidationSchema, baseRegisterValidationSchema, registerValidationSchema};
