import { useEffect, useRef } from "react";

const useIdleTimer = (onIdle, timeout) => {
    const timerRef = useRef();

    const resetTimer = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(onIdle, timeout);
    };

    useEffect(() => {
        const handleActivity = () => resetTimer();

        window.addEventListener("mousemove", handleActivity);
        window.addEventListener("keydown", handleActivity);

        resetTimer();

        return () => {
            clearTimeout(timerRef.current);
            window.removeEventListener("mousemove", handleActivity);
            window.removeEventListener("keydown", handleActivity);
        };
    }, [onIdle, timeout]);
};

export default useIdleTimer;
