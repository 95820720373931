import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box } from "@chakra-ui/react";
import CatalogList from './CatalogList';
import Breadcrumb from './Breadcrumb';

const Catalog = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [currentCategory, setCurrentCategory] = useState(null);
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                setLoading(true);
                const endpoint = slug ? `/api/categories/${slug}` : '/api/categories';
                const response = await axios.get(endpoint);

                if (response.data.success) {
                    setCategories(response.data.categories);
                    setCurrentCategory(response.data.current);
                    setBreadcrumbs(response.data.breadcrumbs);
                }
            } catch (error) {
                console.error('Ошибка при загрузке категорий:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCategories();
    }, [slug]);

    return (
        <Box>
            <Breadcrumb items={breadcrumbs} />
            <CatalogList
                items={categories}
                loading={loading}
                currentCategory={currentCategory}
            />
        </Box>
    );
};

export default Catalog;
