import React, { useEffect, useState } from 'react';
import {
    Box, Grid, Image, Text, Flex, Spinner, Select, Button
} from '@chakra-ui/react';
import NavbarHeader from "../components/Main/NavbarHeader";
import Footer from '../components/Footer';
import api from '../utils/api';

const WarehousePage = () => {

    const [warehouses, setWarehouses] = useState([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const [floors, setFloors] = useState([]);
    const [selectedFloor, setSelectedFloor] = useState(null);
    const [equipment, setEquipment] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchWarehouses = async () => {
            try {
                const response = await api.get('/api/warehouses', {
                    params: { include_floors: true }
                });
                setWarehouses(response.data.warehouses);
                setLoading(false);
            } catch (err) {
                setError('Не удалось загрузить склады');
                setLoading(false);
            }
        };
        fetchWarehouses();
    }, []);

    const handleWarehouseSelect = (warehouse) => {
        setSelectedWarehouse(warehouse);
        setSelectedFloor(null);
        setEquipment([]);
        if (warehouse.floors && warehouse.floors.length > 1) {
            setFloors(warehouse.floors);
        } else {
            setFloors([]);
            fetchEquipment(warehouse.id, null);
        }
    };

    const handleFloorSelect = (floorId) => {
        if (floorId === 'all') {
            fetchEquipment(selectedWarehouse.id, null);
        } else {
            fetchEquipment(selectedWarehouse.id, floorId);
        }
    };

    const fetchEquipment = async (warehouseId, floorId) => {
        setLoading(true);
        try {
            const params = { warehouse_id: warehouseId };
            if (floorId) {
                params.floor_id = floorId;
            }
            const response = await api.get('/api/warehouses/equipment', { params });
            setEquipment(response.data.equipment);
            setLoading(false);
        } catch (err) {
            setError('Не удалось загрузить оборудование');
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <div>
                <NavbarHeader />
                <Flex p="6" justify="center" align="center" height="100vh">
                    <Spinner size="xl" />
                </Flex>
                <Footer />
            </div>
        );
    }

    if (error) {
        return (
            <div>
                <NavbarHeader />
                <Flex p="6" justify="center" align="center" height="100vh">
                    <Text color="red.500">{error}</Text>
                </Flex>
                <Footer />
            </div>
        );
    }

    return (
        <div>
            <NavbarHeader />
            <Box p="6" maxW="1200px" mx="auto">
                <Text fontSize="2xl" mb="4">Склады</Text>
                {/* Отображение складов */}
                <Grid templateColumns="repeat(auto-fit, minmax(250px, 1fr))" gap="6">
                    {warehouses.map((warehouse) => (
                        <Box
                            key={warehouse.id}
                            borderWidth="1px"
                            borderRadius="md"
                            overflow="hidden"
                            p="4"
                            cursor="pointer"
                            onClick={() => handleWarehouseSelect(warehouse)}
                            bg={selectedWarehouse && selectedWarehouse.id === warehouse.id ? 'gray.100' : 'white'}
                        >
                            <Image src={warehouse.image_url || '/images/default-warehouse.png'} alt={warehouse.name} mb="4" />
                            <Text fontSize="lg" fontWeight="bold">{warehouse.name}</Text>
                            <Text fontSize="sm" color="gray.500">{warehouse.address}</Text>
                        </Box>
                    ))}
                </Grid>

                {/* Отображение этажей, если выбрано помещение с множеством этажей */}
                {selectedWarehouse && floors.length > 0 && (
                    <Box mt="6">
                        <Text fontSize="xl" mb="4">Выберите этаж</Text>
                        <Select placeholder="Выберите этаж" onChange={(e) => handleFloorSelect(e.target.value)}>
                            <option value="all">Показать всё</option>
                            {floors.map((floor) => (
                                <option key={floor.id} value={floor.id}>{floor.name || `Этаж ${floor.floor_number}`}</option>
                            ))}
                        </Select>
                    </Box>
                )}

                {/* Отображение списка оборудования */}
                {selectedWarehouse && (floors.length === 0 || selectedFloor || selectedFloor === 'all') && (
                    <Box mt="6">
                        <Text fontSize="xl" mb="4">Оборудование</Text>
                        {loading ? (
                            <Flex justify="center" align="center">
                                <Spinner size="lg" />
                            </Flex>
                        ) : equipment.length > 0 ? (
                            <Grid templateColumns="repeat(auto-fit, minmax(250px, 1fr))" gap="6">
                                {equipment.map((item) => (
                                    <Box key={item.id} borderWidth="1px" borderRadius="md" overflow="hidden" p="4">
                                        <Image src={item.photo_url || '/images/default-equipment.png'} alt={item.number} mb="4" />
                                        <Text fontSize="lg" fontWeight="bold">{item.brand} {item.model}</Text>
                                        <Text fontSize="sm" color="gray.500">Номер: {item.number}</Text>
                                        <Text fontSize="sm" color="gray.500">Состояние: {item.condition}</Text>
                                        {item.storage_location && (
                                            <Text fontSize="sm" color="gray.500">Место хранения: {item.storage_location}</Text>
                                        )}
                                        {item.location && (
                                            <Text fontSize="sm" color="gray.500">Текущее местонахождение: {item.location}</Text>
                                        )}
                                    </Box>
                                ))}
                            </Grid>
                        ) : (
                            <Text>Оборудование не найдено.</Text>
                        )}
                    </Box>
                )}
            </Box>
            <Footer />
        </div>
    );
};

export default WarehousePage;
