import React, { useEffect, useState } from 'react';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Spinner, Text } from '@chakra-ui/react';
import api from '../../utils/api';

const MovementHistory = () => {
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchHistory = async () => {
            try {
                const response = await api.get('/api/inventory/history');
                if (response.data.success) {
                    setHistory(response.data.history);
                } else {
                    setError('Не удалось загрузить историю перемещений.');
                }
            } catch (err) {
                console.error('Ошибка при загрузке истории перемещений:', err);
                setError('Произошла ошибка при загрузке истории перемещений.');
            } finally {
                setLoading(false);
            }
        };

        fetchHistory();
    }, []);

    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <Text color="red.500">{error}</Text>;
    }

    return (
        <Box overflowX="auto">
            <Table variant="striped" colorScheme="teal">
                <Thead>
                    <Tr>
                        <Th>ID</Th>
                        <Th>ID Оборудования</Th>
                        <Th>Тип Действия</Th>
                        <Th>Дата</Th>
                        <Th>Кем Выполнено</Th>
                        <Th>Дополнительно</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {history.map((record) => (
                        <Tr key={record.id}>
                            <Td>{record.id}</Td>
                            <Td>{record.equipment_id}</Td>
                            <Td>{record.action_type}</Td>
                            <Td>{new Date(record.date).toLocaleString()}</Td>
                            <Td>{record.performed_by}</Td>
                            <Td>{record.details}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
};

export default MovementHistory;
