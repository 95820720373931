import React, {useContext, useEffect, useState} from "react";
import { Formik, Form, Field } from "formik";
import { useNavigate } from "react-router";
import api from '../../utils/api';

import {
    Box,
    Button,
    ButtonGroup,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading, Image,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useToast,
    VStack,
} from "@chakra-ui/react";
import { baseRegisterValidationSchema, loginValidationSchema } from "@swop-cathev/common";
import { AccountContext } from "../../contexts/AccountContext";
import '../../pages/styles/Login/Login.css';
import TextField from "../TextField";


const Login = () => {
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const { updateUser, fetchUserData, user } = useContext(AccountContext);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (user.loggedIn) {
            navigate("/main");
        }
    }, [user.loggedIn, navigate]);

    const handleSubmit = async (values, actions) => {
        if (isLoading) return;

        try {
            setIsLoading(true);
            setError(null);

            const response = await api.login(values.username, values.password);

            if (response.loggedIn) {
                localStorage.setItem("token", response.token);
                updateUser({
                    username: response.username,
                    first_name: response.first_name,
                    role: response.role,
                    token: response.token
                });

                await fetchUserData();

                setTimeout(() => {
                    navigate("/main");
                }, 100);
            }
        } catch (error) {
            setError(error.message);
            toast({
                title: 'Ошибка',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true
            });
        } finally {
            setIsLoading(false);
            actions.setSubmitting(false);
        }
    };

    return (
        <Flex className="Login-container" direction="column" align="center" justify="center">
            <Box className="Login-content">
                <Box className="Login-login-modal-wrapper">
                    <Heading as="h2" size="md" className="Login-header">Вход в систему</Heading>
                    <Formik
                        initialValues={{ username: "", password: "" }}
                        validationSchema={loginValidationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ errors, touched, isSubmitting }) => (
                            <div className="Login-modal-content">
                                <VStack as={Form}>
                                    <Text as="p" color="red.500">
                                        {error}
                                    </Text>
                                    <FormControl isInvalid={errors.username && touched.username}>
                                        <TextField
                                            name="username"
                                            placeholder="Введите имя пользователя"
                                            autoComplete="off"
                                            label="Имя пользователя"
                                        />
                                        {/*<FormErrorMessage>{errors.username}</FormErrorMessage>*/}
                                    </FormControl>

                                    <FormControl isInvalid={errors.password && touched.password}>
                                        <TextField
                                            name="password"
                                            placeholder="Введите пароль"
                                            autoComplete="off"
                                            label="Пароль"
                                            type="password"
                                        />
                                        {/*<FormErrorMessage>{errors.password}</FormErrorMessage>*/}
                                    </FormControl>

                                    <div className="Login-button-group">
                                        <Button
                                                type="submit"
                                                isLoading={isLoading || isSubmitting}
                                                disabled={isLoading || isSubmitting}
                                                className="Login-button-login"
                                        >
                                            Вход
                                        </Button>
                                    </div>
                                </VStack>
                            </div>
                        )}
                    </Formik>
                </Box>
            </Box>
        </Flex>
    );
};

export default Login;
