import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import './EquipmentSummary.css';

const EquipmentSummary = ({ brand_name, model, serial_number, location }) => {
    return (
        <Box className="equipment-summary">
            <Text className="equipment-title">
                {brand_name || ''} {model || ''}
            </Text>
            <Text className="equipment-serial-number">
                <strong>Серийный номер:</strong> {serial_number || ''}
            </Text>
            <Text className="equipment-location">
                <strong>Текущее местонахождение:</strong> {location || ''}
            </Text>
        </Box>
    );
};

export default EquipmentSummary;