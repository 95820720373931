import React, { useState, useEffect, createContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import api from '../utils/api';

export const AccountContext = createContext();

const AccountContextProvider = ({ children }) => {
  const [user, setUser] = useState({
    loggedIn: null,
    username: null,
    first_name: null,
    last_name: null,
    middle_name: null,
    birth_date: null,
    email: null,
    role: null,
    roleId: null
  });
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  const fetchUserData = async () => {
    try {
      const response = await api.get('/api/user');
      console.log('Полученные данные от /api/user:', response.data);

      if (response.data.username) {
        setUser({
          loggedIn: true,
          username: response.data.username,
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          middle_name: response.data.middle_name,
          birth_date: response.data.birth_date,
          email: response.data.email,
          role: response.data.role,
          roleId: response.data.roleId
        });
      } else {
        setUser({
          loggedIn: false,
          username: null,
          first_name: null,
          last_name: null,
          middle_name: null,
          birth_date: null,
          email: null,
          role: null,
          roleId: null
        });
        localStorage.removeItem("token");
        if (location.pathname !== '/') {
          navigate('/');
        }
      }
    } catch (error) {
      console.error('Ошибка при запросе /api/user:', error);
      setUser({
        loggedIn: false,
        username: null,
        first_name: null,
        last_name: null,
        middle_name: null,
        birth_date: null,
        email: null,
        role: null,
        roleId: null
      });
      localStorage.removeItem("token");
      if (location.pathname !== '/') {
        navigate('/');
      }
    }
  };

  const updateUser = (userData) => {
    setUser({
      loggedIn: true,
      username: userData.username,
      first_name: userData.first_name || null,
      last_name: userData.last_name || null,
      middle_name: userData.middle_name || null,
      birth_date: userData.birth_date || null,
      email: userData.email || null,
      role: userData.role || 'worker',
      roleId: userData.roleId || null
    });
  };

  const logout = async () => {
    try {
      await api.logout();
      setUser({
        loggedIn: false,
        username: null,
        first_name: null,
        last_name: null,
        middle_name: null,
        birth_date: null,
        email: null,
        role: null,
        roleId: null
      });
      setNotifications([]);
      navigate("/");
    } catch (error) {
      console.error("Ошибка при выходе:", error);
      toast({
        title: "Ошибка",
        description: "Не удалось выполнить выход из аккаунта",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchNotifications = async () => {
    try {
      const response = await api.get('/api/notifications');
      setNotifications(response.data.notifications || []);
    } catch (error) {
      console.error('Ошибка при получении уведомлений:', error);
      setNotifications([]);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && user.loggedIn === null) {
      fetchUserData();
    } else if (!token) {
      setUser({
        loggedIn: false,
        username: null,
        first_name: null,
        last_name: null,
        middle_name: null,
        birth_date: null,
        email: null,
        role: null,
        roleId: null
      });
      if (location.pathname !== '/') {
        navigate("/");
      }
    }
  }, []);

  useEffect(() => {
    if (user.loggedIn) {
      fetchNotifications();
    }
  }, [user.loggedIn]);

  return (
      <AccountContext.Provider value={{ user, updateUser, logout, fetchUserData, notifications, fetchNotifications }}>
        {children}
      </AccountContext.Provider>
  );
};

export default AccountContextProvider;
