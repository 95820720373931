import { useState, useEffect } from 'react';
import axios from 'axios';

const useEquipment = (id) => {
    const [equipment, setEquipment] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchEquipment = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`/api/equipment/${id}`, { withCredentials: true });
                if (response.data.success) {
                    setEquipment(response.data.data.equipment);
                } else {
                    setError(response.data.message);
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchEquipment();
    }, [id]);

    return { equipment, loading, error };
};

export default useEquipment;
