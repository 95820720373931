import React, { useState, useEffect } from 'react';
import './styles/Main/MainPage.css';
import NavbarHeader from "../components/Main/NavbarHeader";
import Footer from "../components/Footer";
import MainMenu from "../components/Main/MainMenu";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';


ChartJS.register(ArcElement, Tooltip, Legend);

function MainPage() {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);

  const toggleSummary = () => {
    setIsSummaryOpen(!isSummaryOpen);
  };

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
      <div>
        <NavbarHeader/>
        <div className="Main-container">
          <div className="Main-content">
            <MainMenu />
          </div>
          <Footer/>
        </div>
      </div>
  );
}

export default MainPage;