import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Image, Text } from '@chakra-ui/react';
import { fetchImage } from '../../utils/imageHandler';
import './CatalogList.css';
import axios from "axios";
import LoadingSpinner from "../common/LoadingSpinner";

const CatalogList = ({ items = [], loading }) => {
    const navigate = useNavigate();
    const [images, setImages] = useState({});
    const [totalCount, setTotalCount] = useState(0);

    const handleItemClick = (item) => {
        if (item.slug) {
            navigate(`/catalog/${item.slug}`);
        } else {
            console.warn('Неизвестный формат записи:', item);
        }
    };

    useEffect(() => {
        const loadImages = async () => {
            const newImages = {};
            for (const item of items) {
                if (item.image_url) {
                    const fetchedImage = await fetchImage(item.image_url);
                    newImages[item.id] = fetchedImage;
                } else {
                    newImages[item.id] = '/images/placeholder.png';
                }
            }
            setImages(newImages);
        };

        loadImages();
    }, [items]);

    const fetchTotalCount = async () => {
        try {
            const response = await axios.get('/api/equipment/count', {
                withCredentials: true
            });
            if (response.data.success) {
                setTotalCount(response.data.count);
            } else {
                console.warn('Не удалось получить общее количество техники.');
            }
        } catch (error) {
            console.error('Ошибка при получении общего количества техники:', error);
        }
    };

    useEffect(() => {
        fetchTotalCount();
    }, []);

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <div className="catalog-list-grid">
            <Box
                className="catalog-item"
                onClick={() => navigate('/all-equipment')}
            >
                <Image
                    src={'/images/placeholder.png'}
                    alt="Весь список"
                    className="catalog-image"
                    onError={(e) => { e.target.src = '/images/placeholder.png'; }}
                />
                <Box className="catalog-content">
                    <Text className="catalog-title">
                        Весь список
                    </Text>
                    <Text className="catalog-description">
                        Просмотреть все категории и товары
                    </Text>
                    <Text className="catalog-info">
                        {`Количество: ${totalCount}`}
                    </Text>
                </Box>
            </Box>

            {items.map((item) => (
                <Box
                    key={item.id}
                    className="catalog-item"
                    onClick={() => handleItemClick(item)}
                >
                    <Image
                        src={images[item.id] || '/images/placeholder.png'}
                        alt={item.name}
                        className="catalog-image"
                        onError={(e) => { e.target.src = '/images/placeholder.png'; }}
                    />
                    <Box className="catalog-content">
                        <Text className="catalog-title">
                            {item.name}
                        </Text>
                        <Text className="catalog-description">
                            {item.description}
                        </Text>
                        <Text className="catalog-info">
                            {/* Если на сервере возвращается items_count – можно отобразить. */}
                            {`Количество: ${item.items_count || 0}`}
                        </Text>
                    </Box>
                </Box>
            ))}
        </div>
    );
};

export default CatalogList;
