import React from 'react';
import { VStack, Box, Text, HStack, IconButton, Badge, Image, Tooltip } from '@chakra-ui/react';
import { CheckIcon, DeleteIcon } from '@chakra-ui/icons';
import ReminderIcon from '../../assets/reminder-icon.png';

const NotificationsList = ({ notifications, onMarkAsRead, onDelete }) => {
    if (notifications.length === 0) {
        return <Text>Нет уведомлений.</Text>;
    }

    return (
        <VStack spacing={4} align="stretch">
            {notifications.map((notification) => (
                <Box
                    key={notification.id}
                    p={4}
                    borderWidth="1px"
                    borderRadius="md"
                    bg={notification.isRead ? 'gray.100' : 'blue.50'}
                    boxShadow="sm"
                >
                    <HStack justifyContent="space-between" alignItems="center">
                        <HStack>
                            {notification.type === 'reminder' && (
                                <Image src={ReminderIcon} alt="Напоминание" boxSize="20px" />
                            )}
                            <Box>
                                <Text fontSize="lg" fontWeight="bold">
                                    {notification.title}
                                </Text>
                                <Text mt={2}>{notification.message}</Text>
                                <Badge mt={2} colorScheme={notification.isRead ? 'green' : 'red'}>
                                    {notification.isRead ? 'Прочитано' : 'Непрочитано'}
                                </Badge>
                            </Box>
                        </HStack>
                        <HStack>
                            {!notification.isRead && (
                                <IconButton
                                    aria-label="Отметить как прочитанное"
                                    icon={<CheckIcon />}
                                    colorScheme="green"
                                    onClick={() => onMarkAsRead(notification.id)}
                                    variant="outline"
                                />
                            )}
                            <IconButton
                                aria-label="Удалить уведомление"
                                icon={<DeleteIcon />}
                                colorScheme="red"
                                onClick={() => onDelete(notification.id)}
                                variant="outline"
                            />
                        </HStack>
                    </HStack>
                </Box>
            ))}
        </VStack>
    );
};

export default NotificationsList;
