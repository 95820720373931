import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Spinner,
  Text,
  Heading,
  Divider,
  Badge,
  Box,
  VStack,
  Tooltip,
} from "@chakra-ui/react";
import api from "../../utils/api";

function UserModal({ userId, onClose }) {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchUserData = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/api/admin/users/${userId}`);
      if (response.data.success) {
        setUserData(response.data.user);
      } else {
        setError(
          response.data.message || "Не удалось загрузить данные пользователя."
        );
      }
    } catch (err) {
      console.error("Ошибка при получении данных пользователя:", err);
      setError("Произошла ошибка при загрузке данных пользователя.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
    const interval = setInterval(fetchUserData, 30000);
    return () => clearInterval(interval);
  }, [userId]);

  const formatLastActive = (lastActive) => {
    if (!lastActive) return "Нет данных";
    const date = new Date(lastActive);
    const now = new Date();
    const diff = now - date;

    if (diff < 60000) return "Только что";
    if (diff < 3600000) return `${Math.floor(diff / 60000)} мин. назад`;
    if (diff < 86400000) return `${Math.floor(diff / 3600000)} ч. назад`;
    return date.toLocaleString();
  };

  return (
    <Modal isOpen={true} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Информация о пользователе</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {loading && <Spinner />}
          {error && <Text color="red.500">{error}</Text>}
          {!loading && !error && userData && (
            <VStack align="start" spacing={4}>
              <Box>
                <Heading size="md">Основная информация</Heading>
                <Text>
                  <strong>Имя пользователя:</strong> {userData.username}
                </Text>
                <Text>
                  <strong>Email:</strong> {userData.email}
                </Text>
                <Text>
                  <strong>Имя:</strong> {userData.first_name}
                </Text>
                <Text>
                  <strong>Фамилия:</strong> {userData.last_name}
                </Text>
                <Text>
                  <strong>Роль:</strong> {userData.role}
                </Text>
                <Tooltip label={new Date(userData.lastActive).toLocaleString()}>
                  <Text>
                    <strong>Последняя активность:</strong>{" "}
                    {formatLastActive(userData.lastActive)}
                  </Text>
                </Tooltip>
                <Text>
                  <strong>Статус:</strong>
                  <Badge
                    colorScheme={userData.isOnline ? "green" : "gray"}
                    ml={2}
                  >
                    {userData.isOnline ? "Online" : "Offline"}
                  </Badge>
                </Text>
              </Box>
              <Divider />
              <Box w="100%">
                <Heading size="sm" mb={2}>
                  История приёмки и отправки
                </Heading>
                {userData.history && userData.history.length > 0 ? (
                  userData.history.map((record) => (
                    <Box key={record.id} mb={2} pl={2}>
                      <Text fontSize="sm">
                        [{new Date(record.date).toLocaleString()}]{" "}
                        {record.action_type} - {record.details}
                      </Text>
                    </Box>
                  ))
                ) : (
                  <Text fontSize="sm">Нет исторических записей.</Text>
                )}
              </Box>
              <Divider />
              <Box w="100%">
                <Heading size="sm" mb={2}>
                  Привязанное оборудование
                </Heading>
                {userData.equipment && userData.equipment.length > 0 ? (
                  userData.equipment.map((eq) => (
                    <Box key={eq.id} mb={2} pl={2}>
                      <Text fontSize="sm">
                        {eq.name} (Серийный номер: {eq.serial_number})
                      </Text>
                    </Box>
                  ))
                ) : (
                  <Text fontSize="sm">Нет привязанного оборудования.</Text>
                )}
              </Box>
              <Divider />
              <Box w="100%">
                <Heading size="sm" mb={2}>
                  Настройки пользователя
                </Heading>
                {userData.settings ? (
                  <VStack align="start" spacing={2}>
                    <Text fontSize="sm">
                      Автосохранение: каждые {userData.settings.auto_save_time}{" "}
                      мин
                    </Text>
                    <Text fontSize="sm">
                      Автовыход:{" "}
                      {userData.settings.auto_logout ? "Включен" : "Выключен"}
                    </Text>
                    <Text fontSize="sm">
                      Сохранять последнее окно:{" "}
                      {userData.settings.save_last_window ? "Да" : "Нет"}
                    </Text>
                  </VStack>
                ) : (
                  <Text fontSize="sm">Настройки не заданы</Text>
                )}
              </Box>
              <Divider />
              <Box w="100%">
                <Heading size="sm" mb={2}>
                  Документы пользователя
                </Heading>
                {userData.documents && userData.documents.length > 0 ? (
                  userData.documents.map((doc) => (
                    <Box key={doc.id} mb={2} pl={2}>
                      <Text fontSize="sm">
                        {doc.title} ({new Date(doc.created_at).toLocaleString()}
                        )
                      </Text>
                    </Box>
                  ))
                ) : (
                  <Text fontSize="sm">Нет документов</Text>
                )}
              </Box>
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default UserModal;
