import React, { useState, useEffect } from 'react';
import { Box, Flex, Text, Tabs, TabList, TabPanels, Tab, TabPanel, Spinner, Button } from '@chakra-ui/react';
import '../pages/styles/InventoryPage.css';
import NavbarHeader from '../components/Main/NavbarHeader';
import Footer from '../components/Footer';
import EquipmentList from '../components/Main/EquipmentList';
import AddEquipmentForm from '../components/Inventory/AddEquipmentForm';
import EditEquipmentForm from '../components/Inventory/EditEquipmentForm';
import SendEquipmentForm from '../components/Inventory/SendEquipmentForm';
import MovementHistory from '../components/Inventory/MovementHistory';
import BulkReceiptForm from '../components/Inventory/BulkReceiptForm';
import api from '../utils/api';
import { useNavigate } from 'react-router-dom';

const InventoryPage = () => {
    const [selectedEquipment, setSelectedEquipment] = useState(null);
    const [equipment, setEquipment] = useState([]);
    const [bulkItems, setBulkItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleEditClick = (equipmentItem) => {
        setSelectedEquipment(equipmentItem);
    };

    useEffect(() => {
        fetchEquipment();
        fetchBulkItems();
    }, []);

    const fetchEquipment = async () => {
        try {
            setLoading(true);
            const response = await api.get('/api/equipment/all', {
                withCredentials: true,
            });

            if (response.data.success) {
                setEquipment(response.data.equipment);
                setError(null);
            } else {
                setError('Не удалось загрузить оборудование.');
            }
        } catch (err) {
            console.error('Ошибка при загрузке оборудования:', err);
            setError('Произошла ошибка при загрузке оборудования.');
        } finally {
            setLoading(false);
        }
    };

    const fetchBulkItems = async () => {
        try {
            setLoading(true);
            const response = await api.get('/api/items/all', {
                withCredentials: true,
            });

            if (response.data.success) {
                setBulkItems(response.data.items);
                setError(null);
            } else {
                setError('Не удалось загрузить предметы.');
            }
        } catch (err) {
            console.error('Ошибка при загрузке предметов:', err);
            setError('Произошла ошибка при загрузке предметов.');
        } finally {
            setLoading(false);
        }
    };

    const navigateToEquipmentReceipt = () => {
        navigate('/inventory/receipt');
    };

    const navigateToEquipmentSend = () => {
        navigate('/inventory/send');
    };

    if (loading) {
        return (
            <div>
                <NavbarHeader />
                <Flex p="6" justify="center" align="center" height="100vh">
                    <Spinner size="xl" />
                </Flex>
            </div>
        );
    }

    if (error) {
        return (
            <div>
                <NavbarHeader />
                <Flex p="6" justify="center" align="center" height="100vh">
                    <Text color="red.500">{error}</Text>
                </Flex>
                <Footer />
            </div>
        );
    }

    return (
        <div>
            <NavbarHeader />
            <Box p="6" maxW="1200px" mx="auto">
                <Text fontSize="2xl" mb="4">Управление Инвентаризацией</Text>
                <Flex mb="4" gap="4">
                    <Button colorScheme="teal" onClick={navigateToEquipmentReceipt}>
                        Перейти к Приёмке Оборудования
                    </Button>
                    <Button colorScheme="blue" onClick={navigateToEquipmentSend}>
                        Перейти к Отправке Оборудования
                    </Button>
                </Flex>
                <Tabs variant="enclosed" colorScheme="teal">
                    <TabList>
                        <Tab>Приёмка Предметов</Tab>
                        <Tab>История перемещений</Tab>
                        <Tab>Добавить</Tab>

                    </TabList>

                    <TabPanels>
                        {/* Вкладка Приёмка Предметов */}
                        <TabPanel>
                            <BulkReceiptForm onSuccess={fetchBulkItems} />
                        </TabPanel>


                        {/* Вкладка История перемещений */}
                        <TabPanel>
                            <MovementHistory />
                        </TabPanel>

                        {/* Вкладка Добавить */}
                        <TabPanel>
                            <AddEquipmentForm onSuccess={fetchEquipment} />
                        </TabPanel>


                    </TabPanels>
                </Tabs>

                <Box mt="8">
                    <Text fontSize="xl" mb="4">Список Оборудования</Text>
                    <EquipmentList equipment={equipment} onEditClick={handleEditClick} />
                </Box>

                <Box mt="8">
                    <Text fontSize="xl" mb="4">Список Предметов</Text>
                    {/* Создайте и используйте компонент для отображения списка предметов */}
                    <BulkItemsList items={bulkItems} />
                </Box>
            </Box>
            <Footer />
        </div>
    );
};

const BulkItemsList = ({ items }) => {
    return (
        <Box>
            {items.length > 0 ? (
                items.map((item) => (
                    <Box key={item.id} p="4" borderWidth="1px" borderRadius="md" mb="2">
                        <Text><strong>Название:</strong> {item.name}</Text>
                        <Text><strong>Количество:</strong> {item.quantity}</Text>
                        {/* Добавьте дополнительные поля по необходимости */}
                    </Box>
                ))
            ) : (
                <Text>Нет принятых предметов.</Text>
            )}
        </Box>
    );
};

export default InventoryPage;