import React, { useState } from 'react';
import {
    Box,
    Input,
    Spinner,
    Text,
    VStack,
    HStack,
    Divider,
    Image,
    SimpleGrid,
    Card,
    CardBody,
    CardFooter,
    Button,
    useColorModeValue,
    Tag,
    TagLabel,
    TagLeftIcon,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import api from '../../utils/api';
import debounce from 'lodash.debounce';
import { FiLayers } from 'react-icons/fi';
import { MdCategory } from 'react-icons/md';
import { AiOutlineApartment } from 'react-icons/ai';
import { BsBoxSeam } from 'react-icons/bs';
import './GlobalSearch.css';
import '../../pages/styles/Main/NavbarHeader.css';

const categoryIcons = {
    equipment: FiLayers,
    items: MdCategory,
    stores: AiOutlineApartment,
    warehouses: AiOutlineApartment,
    boxes: BsBoxSeam,
};

const GlobalSearch = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Вызов хуков вне условий и циклов
    const bgColor = useColorModeValue('white', 'gray.700');
    const borderColor = useColorModeValue('gray.200', 'gray.600');

    // Функция для выполнения поиска
    const performSearch = async (term) => {
        if (!term || term.trim() === '') {
            setResults([]);
            setLoading(false);
            setError(null);
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const response = await api.get(`/api/search/global?q=${encodeURIComponent(term)}`);
            if (response.data.success) {
                // Объединяем результаты из всех категорий в один массив с указанием категории
                const combinedResults = [];
                Object.keys(response.data.results).forEach((categoryKey) => {
                    response.data.results[categoryKey].forEach((item) => {
                        combinedResults.push({ ...item, category: categoryKey });
                    });
                });
                setResults(combinedResults);
            } else {
                setError('Не удалось выполнить поиск.');
            }
        } catch (err) {
            console.error('Ошибка при выполнении поиска:', err);
            setError('Внутренняя ошибка сервера при выполнении поиска.');
        } finally {
            setLoading(false);
        }
    };

    // Дебаунсинг поиска для уменьшения количества запросов
    const debouncedSearch = debounce(performSearch, 300);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        debouncedSearch(value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        performSearch(searchTerm);
    };

    return (
        <Box className="navbar-search-greeting">
            <Box
                as="form"
                className="navbar-search-form"
                onSubmit={handleSearchSubmit}
            >
                <Input
                    type="search"
                    placeholder="Поиск по системе"
                    value={searchTerm}
                    onChange={handleInputChange}
                    className="search-input"
                />
                <Box className="search-icon">
                    {loading ? (
                        <Spinner size="sm" color="gray.400" />
                    ) : (
                        <FaSearch color="gray.400" />
                    )}
                </Box>
            </Box>
            {/* Ошибки и результаты отображаются ниже формы поиска */}
            <Box width="100%">
                {error && (
                    <Text color="red.500" mt="2">{error}</Text>
                )}
                {results.length > 0 && (
                    <Box
                        className="search-results"
                        bg={bgColor}
                    >
                        <VStack>
                            {results.map((item) => (
                                <Card key={item.id} className="card-container">
                                    {item.photo_url && (
                                        <Image className="search-card-image"
                                            objectFit="cover"
                                            // maxW={{ base: '100%', sm: '200px' }}
                                            src={item.photo_url}
                                            alt={item.name || item.type}
                                        />
                                    )}
                                    <HStack spacing="4" flex="1" justify="space-between" className="card-content">
                                        <Box>
                                            <HStack>
                                                <Tag size="sm" colorScheme="teal" className="category-tag">
                                                    <TagLeftIcon boxSize="12px" as={categoryIcons[item.category]} />
                                                    <TagLabel className="tag-label">{item.category.charAt(0).toUpperCase() + item.category.slice(1)}</TagLabel>
                                                </Tag>
                                            </HStack>
                                            <CardBody>
                                                <Text fontWeight="bold">{item.name || `${item.type} - ${item.brand} ${item.model}`}</Text>
                                                {item.model && <Text mt="2">Модель: {item.model}</Text>}
                                                {/*{item.description && <Text mt="2">Описание: {item.description}</Text>}*/}
                                                {item.address && <Text mt="2">Адрес: {item.address}</Text>}
                                                {item.number && <Text mt="2">Номер: {item.number}</Text>}
                                                {item.serial_number && <Text mt="2">Серийный номер: {item.serial_number}</Text>}
                                            </CardBody>
                                        </Box>
                                        <CardFooter>
                                            <Button as={Link} to={`/${item.category.toLowerCase()}/${item.id}`} colorScheme="teal" variant="solid">
                                                Подробнее
                                            </Button>
                                        </CardFooter>
                                    </HStack>
                                </Card>
                            ))}
                        </VStack>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default GlobalSearch;
