import { extendTheme } from "@chakra-ui/react";

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  styles: {
    global: (props) => ({
      body: {
        margin: 0,
        fontFamily:
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif",
        WebkitFontSmoothing: "antialiased",
        MozOsxFontSmoothing: "grayscale",
        bg: props.colorMode === 'dark' ? 'gray.800' : 'white',
        color: props.colorMode === 'dark' ? 'white' : 'gray.800',
      },
      code: {
        fontFamily:
            "source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace",
      },
    }),
  },
  colors: {
    brand: {
      50: '#f7fafc',
      100: '#edf2f7',
    },
  },
});

export default theme;
