import React from 'react';
import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import useAccount from '../../hooks/useAccount';
import { EditIcon } from '@chakra-ui/icons';

const EditButton = ({ equipmentId }) => {
    const navigate = useNavigate();
    const { user, loading } = useAccount();

    if (loading || !user) return null;

    const isAuthorized = user.role === 'admin' || user.role === 'manager';

    if (!isAuthorized) return null;

    const handleEdit = () => {
        navigate(`/inventory/edit/${equipmentId}`);
    };

    return (
        <Button leftIcon={<EditIcon />} colorScheme="blue" onClick={handleEdit}>
            Изменить
        </Button>
    );
};

export default EditButton;
