import { useState, useEffect } from 'react';
import api from '../utils/api';

const useSettings = (userLoggedIn) => {
    const [inactivityTimeout, setInactivityTimeout] = useState(Infinity);

    useEffect(() => {
        const fetchAndSetInactivityTimeout = async () => {
            try {
                const response = await api.get('/api/settings');
                const timeout = response.data.auto_logout ? parseInt(response.data.inactivity_timeout) * 60000 : Infinity;
                setInactivityTimeout(timeout);
                localStorage.setItem('userSettings', JSON.stringify({
                    inactivityTimeout: response.data.auto_logout ? response.data.inactivity_timeout.toString() : 'never'
                }));
            } catch (error) {
                console.error('Ошибка при загрузке настроек:', error);
                setInactivityTimeout(Infinity);
            }
        };

        if (userLoggedIn) {
            fetchAndSetInactivityTimeout();
        }

        const handleSettingsChange = () => {
            const savedSettings = localStorage.getItem('userSettings');
            if (savedSettings) {
                const settings = JSON.parse(savedSettings);
                if (settings.inactivityTimeout === 'never') {
                    setInactivityTimeout(Infinity);
                } else {
                    setInactivityTimeout(parseInt(settings.inactivityTimeout) * 60000);
                }
            } else {
                setInactivityTimeout(Infinity);
            }
        };

        window.addEventListener('settingsChanged', handleSettingsChange);

        return () => {
            window.removeEventListener('settingsChanged', handleSettingsChange);
        };
    }, [userLoggedIn]);

    return inactivityTimeout;
};

export default useSettings;
