import axios from 'axios';
import { createStandaloneToast } from '@chakra-ui/react';

const { toast } = createStandaloneToast();

const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL || 'http://localhost:4000',
    timeout: 10000, // Таймаут в 10 секунд
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

const showToast = (title, description, status, duration = 3000, isClosable = true) => {
    toast({
        title,
        description,
        status,
        duration,
        isClosable,
        position: "bottom-center",
    });
};

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
            // console.log('Authorization заголовок установлен:', config.headers['Authorization']);
        } else {
            console.warn('Токен не найден в localStorage');
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => response,
    (error) => {
        const { response } = error;
        if (response) {
            const { status } = response;
            switch (status) {
                case 400:
                    showToast("Неверный запрос", "Пожалуйста, проверьте введённые данные.", "error");
                    break;
                case 401:
                    showToast("Неавторизованный доступ", "Пожалуйста, войдите в систему.", "warning");
                    break;
                case 403:
                    showToast("Запрещено", "У вас нет доступа к этому ресурсу.", "error");
                    break;
                case 404:
                    showToast("Не найдено", "Запрашиваемый ресурс не найден.", "error");
                    break;
                case 500:
                    showToast("Внутренняя ошибка сервера", "Произошла ошибка на сервере.", "error");
                    break;

                default:
                    showToast("Ошибка", `Ошибка: ${status}`, "error");
            }
        } else {
            showToast("Ошибка сети", "Проверьте подключение к интернету.", "error");
        }
        return Promise.reject(error);
    }
);

api.login = async (username, password) => {
    try {
        const response = await api.post('/auth/login', { username, password });

        if (response.data.token) {
            localStorage.setItem('token', response.data.token);
            console.log('Токен сохранён в localStorage:', response.data.token);

            api.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;

            return response.data;
        }

        throw new Error('Токен не получен');
    } catch (error) {
        console.error('Login error:', error);
        throw error;
    }
};

api.logout = async () => {
    try {
        const response = await api.post('/auth/logout');

        if (response.data.success) {
            localStorage.removeItem('token');
            delete api.defaults.headers.common['Authorization'];
            console.log('Токен удалён из localStorage и заголовков.');
            return response.data;
        } else {
            throw new Error(response.data.message || "Ошибка при выходе");
        }
    } catch (error) {
        console.error("Ошибка при выходе:", error);
        throw error;
    }
};

export default api;
