import React, { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Textarea, Select, useToast } from '@chakra-ui/react';
import api from '../../utils/api';

const AddEquipmentForm = () => {
    const [formData, setFormData] = useState({
        number: '',
        type: '',
        brand: '',
        model: '',
        serial_number: '',
        configuration: '',
        photo_url: '',
        description: '',
        notes: '',
        purchase_document_url: '',
        warranty_card_url: '',
        purchase_date: '',
        production_date: '',
        condition: '',
        location: '',
        last_repair_date: '',
        repair_works: '',
        warranty: '',
        price: '',
        operational_expenses: '',
        category_id: '',
        subcategory_id: '',
        equipment_type_id: '',
    });

    const toast = useToast();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post('/api/equipment', formData);
            if (response.data.success) {
                toast({
                    title: 'Оборудование добавлено.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                setFormData({
                    number: '',
                    type: '',
                    brand: '',
                    model: '',
                    serial_number: '',
                    configuration: '',
                    photo_url: '',
                    description: '',
                    notes: '',
                    purchase_document_url: '',
                    warranty_card_url: '',
                    purchase_date: '',
                    production_date: '',
                    condition: '',
                    location: '',
                    last_repair_date: '',
                    repair_works: '',
                    warranty: '',
                    price: '',
                    operational_expenses: '',
                    category_id: '',
                    subcategory_id: '',
                    equipment_type_id: '',
                });
            }
        } catch (error) {
            console.error('Ошибка при добавлении оборудования:', error);
            toast({
                title: 'Ошибка при добавлении оборудования.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    return (
        <Box as="form" onSubmit={handleSubmit}>
            <FormControl id="number" mb="4" isRequired>
                <FormLabel>Номер</FormLabel>
                <Input type="text" name="number" value={formData.number} onChange={handleChange} />
            </FormControl>

            {/* Повторите аналогично для остальных полей */}

            <FormControl id="brand" mb="4" isRequired>
                <FormLabel>Бренд</FormLabel>
                <Input type="text" name="brand" value={formData.brand} onChange={handleChange} />
            </FormControl>

            <FormControl id="model" mb="4" isRequired>
                <FormLabel>Модель</FormLabel>
                <Input type="text" name="model" value={formData.model} onChange={handleChange} />
            </FormControl>

            <FormControl id="serial_number" mb="4" isRequired>
                <FormLabel>Серийный Номер</FormLabel>
                <Input type="text" name="serial_number" value={formData.serial_number} onChange={handleChange} />
            </FormControl>

            {/* Добавьте остальные поля по аналогии */}

            <Button type="submit" colorScheme="teal">Добавить Оборудование</Button>
        </Box>
    );
};

export default AddEquipmentForm;
