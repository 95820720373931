import React, { useEffect, useState, useContext } from 'react';
import { Box, Flex, Text, Spinner, Avatar, Badge, Button, Tooltip } from '@chakra-ui/react';
import api from '../utils/api';
import NavbarHeader from '../components/Main/NavbarHeader';
import Footer from '../components/Footer';
import UserModal from '../components/Users/UserModal';
import { AccountContext } from '../contexts/AccountContext';
import './styles/UsersPage.css';

function UsersPage() {
    const { user, loadingUser } = useContext(AccountContext);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);

    const fetchUsers = async () => {
        try {
            setLoading(true);
            const response = await api.get('/api/admin/users');
            if (response.data.success) {
                setUsers(response.data.users);
            } else {
                setError(response.data.message || 'Не удалось загрузить список пользователей.');
            }
        } catch (err) {
            console.error('Ошибка при запросе к API:', err);
            setError('Произошла ошибка при загрузке пользователей.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!loadingUser && user?.role === 'admin') {
            fetchUsers();
            // Обновляем список каждые 30 секунд
            const interval = setInterval(fetchUsers, 30000);
            return () => clearInterval(interval);
        }
    }, [user, loadingUser]);

    const formatLastActive = (lastActive) => {
        if (!lastActive) return 'Нет данных';
        const date = new Date(lastActive);
        const now = new Date();
        const diff = now - date;

        if (diff < 60000) return 'Только что';
        if (diff < 3600000) return `${Math.floor(diff / 60000)} мин. назад`;
        if (diff < 86400000) return `${Math.floor(diff / 3600000)} ч. назад`;
        return date.toLocaleString();
    };

    return (
        <div>
            <NavbarHeader/>
        <div className="users-container">

            <div className="users-content">
                <h1 className="users-title">Пользователи</h1>
                {loading && <Spinner/>}
                {error && <div className="error-message">{error}</div>}
                {!loading && !error && (
                    <div className="users-list">
                        {users.map((user) => (
                            <div key={user.id} className="user-card">
                                <div className="user-info">
                                    <img
                                        src={`https://ui-avatars.com/api/?name=${user.first_name}+${user.last_name}`}
                                        alt={`${user.first_name} ${user.last_name}`}
                                        className="user-avatar"
                                    />
                                    <div className="user-details">
                                        <span className="user-name">{user.username}</span>
                                        <div className="user-activity"
                                             title={new Date(user.lastActive).toLocaleString()}>
                                            Активность: {formatLastActive(user.lastActive)}
                                        </div>
                                    </div>
                                </div>
                                <div className="user-actions">
                                    <span className={`status-badge ${user.isOnline ? 'online' : 'offline'}`}>
                                        {user.isOnline ? "Online" : "Offline"}
                                    </span>
                                    <button
                                        className="details-button"
                                        onClick={() => setSelectedUserId(user.id)}
                                    >
                                        Подробнее
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <Footer/>
            {selectedUserId && (
                <UserModal
                    userId={selectedUserId}
                    onClose={() => setSelectedUserId(null)}
                />
            )}
        </div>
        </div>
    );
}

export default UsersPage;
